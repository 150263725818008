import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Col } from "../../style/GlobalStyled";
import { createPortal } from "react-dom";

const CustomModal = (
  {
    name,
    children,
    onBackdropPress,
  }: {
    name: string;
    children: any;
    onBackdropPress?: any;
  },
  ref
) => {
  const [isVisible, setIsVisible] = useState(false);
  const [propsData, setPropsData] = useState(null);
  useImperativeHandle(
    (el) => (ref.current[name] = el),
    () => ({
      show: (key: string) => {
        setIsVisible(true);
      },
      hide: (key: string) => {
        setIsVisible(false);
      },
      setPropsData: (data: any) => {
        setPropsData(data);
      },
      getPropsData: () => {
        return propsData;
      },
    })
  );

  if (!isVisible) {
    return <></>;
  }

  return createPortal(
    <Col
      style={{
        backgroundColor: "rgba(0,0,0,0.5)",
        position: "fixed",
        top: 0,
        zIndex: 1000,
      }}
      id={"overlay"}
      onClick={(e: any) => {
        const divTarget = e.target;
        if (divTarget.id === "overlay") {
          onBackdropPress();
        }
      }}
    >
      {React.cloneElement(children, { propsData })}
    </Col>,
    document.body
  );
};

export default forwardRef(CustomModal);
