import React, { useRef, useState } from "react";
import Arrow from "../../images/svg/Arrow";
import SelectTriangle from "../../images/svg/SelectTriangle";
import { Col, Pre4, Row } from "../../style/GlobalStyled";
import theme from "../../style/theme";
import { useResponsive } from "../../utils/responsive";
import { CustomButton } from "../Atoms/CustomButton";
import CustomCheckBoxRow from "../Atoms/CustomCheckBoxRow";
import CustomChip from "../Atoms/CustomChip";
import CustomModal from "../Atoms/CustomModal";
import ListModal from "../Modals/ListModal";

interface listItemType {
  type?: string; // 필터 종류에 따른 타입
  title?: string; // 필터 좌측 제목
  list?: listItemType[]; // 해당 필터의 하위 필터
  text?: string; // 필터링 될 텍스트
  id?: number;
}

interface propsType {
  selectedFilter: SelectedFilterType;
  setSelectedFilter: React.Dispatch<React.SetStateAction<SelectedFilterType>>;
  data: listItemType;
  onClickSubmit?: () => void;
}

const ObjectFilter = (props: propsType) => {
  const { data, selectedFilter, setSelectedFilter, onClickSubmit } = props;
  const { HORIZONTAL_MARGIN, isDeskTop, isMobile } = useResponsive();
  const [isOpen, setIsOpen] = useState(true);

  const section = data.list[selectedFilter[0] as number]?.text;
  const publisher_author =
    typeof selectedFilter[1] === "number"
      ? data.list[selectedFilter[0] as number].list[selectedFilter[1]]?.text
      : "";

  const modalRef = useRef<ModalRefType>({});

  const onCustomModal = (name: string) => {
    document.body.style.overflow = "hidden";
    modalRef.current?.[name]?.show?.();
  };

  const offCustomModal = (name: string) => {
    document.body.style.overflow = "auto";
    modalRef.current?.[name]?.hide?.();
  };

  const RecursiveComponent = (data: listItemType, idx: number) => {
    const type = data?.type || null;
    const title = data?.title || null;
    const list = data?.list || null;
    // const text = data?.text || null;

    const nextData = Boolean(list)
      ? list.filter((v, vIndex) => vIndex === selectedFilter[idx])[0]
      : null;

    return (
      <>
        <Row
          style={{
            height: "auto",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            padding: isDeskTop ? "20px 24px" : "0 16px",
            marginBottom: isDeskTop ? 0 : 16,
            borderTop: isDeskTop
              ? idx === 0
                ? 0
                : `1px solid ${theme.Gray2}`
              : 0,
          }}
        >
          <Pre4
            fontWeight="Medium"
            style={{
              width: 70,
              lineHeight: type === "select" ? "32px" : "20px",
              alignSelf: isMobile && title === "대단원" && "center",
              userSelect: "none",
            }}
          >
            {title}
          </Pre4>
          {type === "select" && (
            <Row
              style={{
                height: "auto",
                flex: 1,
                flexWrap: "wrap",
                justifyContent: "flex-start",
                gap: "12px",
              }}
            >
              {list.map((j, jIndex) => {
                const isSelected = selectedFilter[idx] === jIndex;
                return (
                  <CustomChip
                    type={"Tertiary"}
                    size={isDeskTop ? "M" : "S"}
                    title={j.text}
                    key={`filterItem_select_${jIndex}`}
                    style={{
                      borderRadius: 8,
                    }}
                    isSelected={isSelected}
                    onClick={() => {
                      if (isSelected) {
                      } else {
                        setSelectedFilter((prev) => {
                          const temp = [...prev];
                          temp[idx] = jIndex;
                          for (let i = idx + 1; i <= temp.length - 1; i++) {
                            if (typeof temp[i] === "number") {
                              temp[i] = 0;
                            } else {
                              temp[i] = [];
                            }
                          }
                          return temp;
                        });
                      }
                    }}
                  />
                );
              })}
            </Row>
          )}
          {type === "check" && !isMobile && (
            <Row
              style={{
                height: "auto",
                flex: 1,
                flexWrap: "wrap",
                justifyContent: "flex-start",
                rowGap: 16,
              }}
            >
              {list.map((j, jIndex) => {
                const isSelected =
                  (selectedFilter[idx] as number[])?.includes?.(j.id) || false;
                return (
                  <Row
                    style={{
                      justifyContent: "flex-start",
                      width: "50%",
                    }}
                    key={`filterItem_custom_${jIndex}`}
                  >
                    <CustomCheckBoxRow
                      onClick={() => {
                        if (isSelected) {
                          setSelectedFilter((prev) => {
                            const temp = [...prev];
                            temp[idx] = (temp[idx] as number[]).filter(
                              (f) => f !== j.id
                            );
                            return temp;
                          });
                        } else {
                          setSelectedFilter((prev) => {
                            const temp = [...prev];
                            temp[idx] = [...(temp[idx] as number[]), j.id];
                            return temp;
                          });
                        }
                      }}
                      isChecked={isSelected}
                      title={`${jIndex + 1}. ${j.text}`}
                      fontStyle={{
                        fontSize: isDeskTop ? "16px" : "14px",
                        lineHeight: isDeskTop ? "20.4px" : "18.4px",
                        wordBreak: "keep-all",
                      }}
                    />
                  </Row>
                );
              })}
            </Row>
          )}
          {type === "check" && isMobile && (
            <Row
              style={{
                height: "auto",
                flex: 1,
                padding: 16,
                border: `1px solid ${theme.Gray3}`,
                borderRadius: 8,
                justifyContent: "space-between",
                cursor: "pointer",
              }}
              onClick={() => {
                modalRef.current["list"].setPropsData({
                  list,
                  idx,
                });
                onCustomModal("list");
              }}
            >
              {(selectedFilter[idx] as number[]).length === 0 ? (
                <Pre4 fontWeight="Regular" color={theme.Gray5}>
                  전체
                </Pre4>
              ) : (
                <Pre4 fontWeight="Regular">
                  {`${(selectedFilter[idx] as number[]).length}개 단원`}
                </Pre4>
              )}
              <SelectTriangle />
            </Row>
          )}
        </Row>

        {Boolean(nextData) &&
          nextData.hasOwnProperty("list") &&
          RecursiveComponent(nextData, idx + 1)}
      </>
    );
  };

  return (
    <Col
      style={{ height: "auto", marginBottom: !isDeskTop && !isOpen ? 24 : 0 }}
    >
      <Col
        style={{
          width: isDeskTop ? "100%" : `calc(100% - ${2 * HORIZONTAL_MARGIN}px)`,
          maxWidth: "714px",
          height: "auto",
          border: `1px solid ${theme.Gray2}`,
          borderRadius: 8,
          borderBottomLeftRadius: isDeskTop || !isOpen ? 8 : 0,
          borderBottomRightRadius: isDeskTop || !isOpen ? 8 : 0,
          borderBottomWidth: isDeskTop || !isOpen ? 1 : 0,
        }}
      >
        {!isDeskTop && (
          <Row
            style={{
              padding: "16px 16px",
              cursor: "pointer",
              userSelect: "none",
            }}
            onClick={() => setIsOpen((prev) => !prev)}
          >
            <Row
              style={{ flex: 1, height: "auto", justifyContent: "flex-start" }}
            >
              <Pre4 color={theme.Black} style={{ flexShrink: 0 }}>
                필터
              </Pre4>
              {!isOpen && (
                <>
                  <Pre4 color={theme.Blue10} style={{ marginLeft: 4 }}>
                    ·
                  </Pre4>
                  <Pre4
                    style={{
                      marginLeft: 4,
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      display: "inline-block",
                    }}
                    color={theme.Blue10}
                    fontWeight={"Regular"}
                  >
                    {section +
                      (Boolean(publisher_author)
                        ? ` - ${publisher_author}${
                            (selectedFilter[2] as number[]).length === 0
                              ? `, 대단원 전체`
                              : `, ${
                                  (selectedFilter[2] as number[]).length
                                }개 단원`
                          }`
                        : `${
                            (selectedFilter[1] as number[]).length === 0
                              ? ", 대단원 전체"
                              : `, ${
                                  (selectedFilter[1] as number[]).length
                                }개 단원`
                          }`)}
                  </Pre4>
                </>
              )}
            </Row>
            <Arrow rotate={isOpen ? false : true} />
          </Row>
        )}
        {isDeskTop || isOpen ? RecursiveComponent(data, 0) : <></>}
      </Col>
      {(isDeskTop || isOpen) && (
        <Row
          style={{
            height: "auto",
            width: isDeskTop
              ? "100%"
              : `calc(100% - ${2 * HORIZONTAL_MARGIN}px)`,
            maxWidth: "714px",
            margin: isDeskTop ? "24px 0" : "0 0 24px 0",
            justifyContent: "flex-end",
          }}
        >
          <CustomButton
            title="검색"
            onClick={onClickSubmit}
            btnType={"Primary"}
            size={isDeskTop ? "L" : "Full"}
            style={{
              padding: "18px 80px",
              borderTopLeftRadius: isDeskTop ? 12 : 0,
              borderTopRightRadius: isDeskTop ? 12 : 0,
            }}
          />
        </Row>
      )}
      <CustomModal
        ref={modalRef}
        name={"list"}
        onBackdropPress={() => offCustomModal("list")}
      >
        <ListModal
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
          offModal={() => offCustomModal("list")}
        />
      </CustomModal>
    </Col>
  );
};

export default ObjectFilter;
