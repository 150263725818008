import * as React from 'react';

function FileDownLoad(props) {
    return (
        <svg width={13} height={13} viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M4 5l2.5 2.5m0 0L9 5M6.5 7.5V1M.5 7.5v4a1 1 0 001 1h10a1 1 0 001-1v-4"
                stroke={props.color || '#5C5F66'}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default FileDownLoad;
