const midScienceClassHighData = {
  title: "세부과목",
  type: "select",
  list: [
    {
      text: "중학과학1",
      title: "대단원",
      type: "check",
      list: [
        { text: "지권의 변화", title: "대단원", id: "지권의 변화" },
        { text: "여러 가지 힘", title: "대단원", id: "여러 가지 힘" },
        { text: "생물의 다양성", title: "대단원", id: "생물의 다양성" },
        { text: "기체의 성질", title: "대단원", id: "기체의 성질" },
        { text: "물질의 상태 변화", title: "대단원", id: "물질의 상태 변화" },
        { text: "빛과 파동", title: "대단원", id: "빛과 파동" },
        { text: "과학과 나의 미래", title: "대단원", id: "과학과 나의 미래" },
      ],
    },
    {
      text: "중학과학2",
      title: "대단원",
      type: "check",
      list: [
        { text: "물질의 구성", title: "대단원", id: "물질의 구성" },
        { text: "전기와 자기", title: "대단원", id: "전기와 자기" },
        { text: "태양계", title: "대단원", id: "태양계" },
        { text: "식물과 에너지", title: "대단원", id: "식물과 에너지" },
        { text: "동물과 에너지", title: "대단원", id: "동물과 에너지" },
        { text: "물질의 특성", title: "대단원", id: "물질의 특성" },
        {
          text: "수권과 해수의 순환",
          title: "대단원",
          id: "수권과 해수의 순환",
        },
        { text: "열과 우리 생활", title: "대단원", id: "열과 우리 생활" },
        { text: "재해·재난과 안전", title: "대단원", id: "재해·재난과 안전" },
      ],
    },
    {
      text: "중학과학3",
      title: "대단원",
      type: "check",
      list: [
        {
          text: "화학 반응의 규칙과 에너지 변화",
          title: "대단원",
          id: "화학 반응의 규칙과 에너지 변화",
        },
        { text: "기권과 날씨", title: "대단원", id: "기권과 날씨" },
        { text: "운동과 에너지", title: "대단원", id: "운동과 에너지" },
        { text: "자극과 반응", title: "대단원", id: "자극과 반응" },
        { text: "생식과 유전", title: "대단원", id: "생식과 유전" },
        {
          text: "에너지 전환과 보존",
          title: "대단원",
          id: "에너지 전환과 보존",
        },
        { text: "별과 우주", title: "대단원", id: "별과 우주" },
        {
          text: "과학기술과 인류 문명",
          title: "대단원",
          id: "과학기술과 인류 문명",
        },
      ],
    },
  ],
};
export default midScienceClassHighData;
