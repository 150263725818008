const exceptionalArr = [
  { before: "강남영상미디어고등학교", after: "강남미디어고" },
  { before: "강원대학교사범대학부설고등학교", after: "강대사대부고" },
  { before: "건국대학교사범대학부속고등학교", after: "건대사대부고" },
  { before: "건양대학교병설건양고등학교", after: "건양고" },
  { before: "경기국제통상고등학교", after: "국제통상고" },
  { before: "경기모바일과학고등학교", after: "경기모바일고" },
  { before: "경기자동차과학고등학교", after: "경기자동차고" },
  { before: "경북과학기술고등학교", after: "경북과기고" },
  { before: "경북기계금속고등학교", after: "경북기금고" },
  { before: "경북기계명장고등학교", after: "경북기명고" },
  { before: "경북대학교사범대학부설고등학교", after: "경대사대부고" },
  { before: "경북식품과학마이스터고등학교", after: "경북식품고" },
  { before: "경북휴먼테크고등학교", after: "휴먼테크고" },
  { before: "경상국립대학교사범대학부설고등학교", after: "경상사대부고" },
  { before: "경일관광경영고등학교", after: "경일관광고" },
  {
    before: "경화여자English Business고등학교",
    after: "경화EB고",
  },
  { before: "고려대학교사범대학부속고등학교", after: "고대사대부고" },
  { before: "공주대학교사범대학부설고등학교", after: "공대사대부고" },
  { before: "광운인공지능고등학교", after: "광운인지고" },
  { before: "광주소프트웨어마이스터고등학교", after: "광주소마고" },
  { before: "광주수피아여자고등학교", after: "수피아여고" },
  { before: "광주자동화설비공업고등학교", after: "광주자동차고" },
  { before: "군자디지털과학고등학교", after: "군자디지털고" },
  { before: "김제농생명마이스터고등학교", after: "김제농생고" },
  { before: "남해정보산업고등학교", after: "남해정보고" },
  { before: "단국대학교사범대학부속고등학교", after: "단대사대부고" },
  {
    before: "대구가톨릭대학교사범대학부속무학고등학교",
    after: "대구카대부고",
  },
  { before: "대구공업고등학교 테크노폴리스 캠퍼스", after: "대구공고" },
  { before: "대구과학기술고등학교", after: "대구과기고" },
  { before: "대구소프트웨어마이스터고등학교", after: "대구소마고" },
  { before: "대구제일여자상업고등학교", after: "제일여상고" },
  { before: "대덕소프트웨어마이스터고등학교", after: "대덕소마고" },
  { before: "대전국제통상고등학교", after: "대전국제고" },
  { before: "대진전자통신고등학교", after: "대진전자고" },
  { before: "덕수고등학교(일반고)", after: "덕수고" },
  { before: "동국대학교사범대학부속고등학교", after: "동대사대부고" },
  { before: "동국대학교사범대학부속금산고등학교", after: "금산고" },
  { before: "동국대학교사범대학부속여자고등학교", after: "동대사대여고" },
  { before: "동국대학교사범대학부속영석고등학교", after: "영석고" },
  { before: "동명생활경영고등학교", after: "동명경영고" },
  { before: "목포마리아회고등학교", after: "목포마리아고" },
  { before: "미림여자정보과학고등학교", after: "미림정보고" },
  { before: "발안바이오과학고등학교", after: "발안바이오고" },
  { before: "부산대학교사범대학부설고등학교", after: "부대사대부고" },
  { before: "부산소프트웨어마이스터고등학교", after: "부산소마고" },
  { before: "부산정보관광고등학교", after: "부산정보고" },
  { before: "부산컴퓨터과학고등학교", after: "부산컴퓨터고" },
  { before: "부천정보산업고등학교", after: "부천정보고" },
  { before: "상명대학교사범대학부속여자고등학교", after: "상대사대부고" },
  { before: "서귀포산업과학고등학교", after: "서귀포산업고" },
  { before: "서서울생활과학고등학교", after: "서울생활고" },
  { before: "서울대학교사범대학부설고등학교", after: "서울사대부고" },
  { before: "서울도시과학기술고등학교", after: "서울도시고" },
  { before: "서울의료보건고등학교", after: "서울의료고" },
  { before: "서울인공지능고등학교", after: "서울인지고" },
  { before: "선일빅데이터고등학교", after: "빅데이터고" },
  { before: "선정국제관광고등학교", after: "선정관광고" },
  { before: "성남테크노과학고등학교", after: "성남테크노고" },
  { before: "성동글로벌경영고등학교", after: "성동경영고" },
  { before: "성암국제무역고등학교", after: "성암무역고" },
  { before: "세그루패션디자인고등학교", after: "세그루패디고" },
  { before: "세종과학예술영재학교", after: "세종과고" },
  { before: "수원농생명과학고등학교", after: "수원농생고" },
  { before: "숭의과학기술고등학교", after: "숭의과기고" },
  { before: "신진과학기술고등학교", after: "신진과기고" },
  { before: "아산스마트팩토리마이스터고등학교", after: "아산마고" },
  { before: "안산디자인문화고등학교", after: "안산디고" },
  { before: "여수석유화학고등학교", after: "여수석유고" },
  { before: "여주자영농업고등학교", after: "여주농고" },
  { before: "연무대기계공업고등학교", after: "연무공고" },
  { before: "염광여자메디텍고등학교", after: "염광메디텍고" },
  { before: "영종국제물류고등학교", after: "영종물류고" },
  { before: "영화국제관광고등학교", after: "영화관광고" },
  { before: "용인한국외국어대학교부설고등학교", after: "용인외고" },
  { before: "울산스포츠과학고등학교", after: "울산스포츠고" },
  { before: "원주금융회계고등학교", after: "원주금융고" },
  { before: "이화여자대학교병설미디어고등학교", after: "이대미디어고" },
  { before: "인덕과학기술고등학교", after: "인덕과기고" },
  { before: "인천과학예술영재학교", after: "인천영재고" },
  { before: "인천미래생활고등학교", after: "인천미래고" },
  { before: "인천바이오과학고등학교", after: "인천바이오고" },
  { before: "인천중앙여자상업고등학교", after: "인천여상고" },
  { before: "인하대학교사범대학부속고등학교", after: "인하부속고" },
  { before: "일산국제컨벤션고등학교", after: "일산컨벤션고" },
  { before: "전남대학교사범대학부설고등학교", after: "전남사대부고" },
  { before: "전북대학교사범대학부설고등학교", after: "전북사대부고" },
  { before: "전주대학교사범대학부설고등학교", after: "전주사대부고" },
  { before: "제주대학교사범대학부설고등학교", after: "제주사대부고" },
  { before: "줄포자동차공업고등학교", after: "줄포자동차고" },
  { before: "충남드론항공고등학교", after: "드론항공고" },
  { before: "충남디자인예술고등학교", after: "충남예고" },
  { before: "충북대학교사범대학부설고등학교", after: "충북사대부고" },
  { before: "충북생명산업고등학교", after: "충북생명고" },
  { before: "포항과학기술고등학교", after: "포항과기고" },
  { before: "한국K-POP고등학교", after: "KPOP고" },
  { before: "한국경마축산고등학교", after: "경마축산고" },
  { before: "한국과학기술고등학교", after: "한국과기고" },
  { before: "한국교원대학교부설고등학교", after: "교원대부고" },
  { before: "한국국제조리고등학교", after: "조리고" },
  { before: "한국국제통상마이스터고등학교", after: "국제통상고" },
  { before: "한국글로벌셰프고등학교", after: "글로벌셰프고" },
  { before: "한국문화영상고등학교", after: "문화영상고" },
  { before: "한국미래농업고등학교", after: "미래농업고" },
  { before: "한국바이오마이스터고등학교", after: "한국바이오고" },
  { before: "한국애니메이션고등학교", after: "애니메이션고" },
  { before: "한국원자력마이스터고등학교", after: "한국원자력고" },
  { before: "한국전통문화고등학교", after: "전통문화고" },
  { before: "한국항만물류고등학교", after: "항만물류고" },
  { before: "한국호텔관광고등학교", after: "호텔관광고" },
  { before: "한세사이버보안고등학교", after: "한세사이버고" },
  { before: "한양대학교사범대학부속고등학교", after: "한양사대부고" },
  { before: "해성국제컨벤션고등학교", after: "국제컨벤션고" },
  { before: "홍익대학교사범대학부속고등학교", after: "홍대사대부고" },
  { before: "홍익대학교사범대학부속여자고등학교", after: "홍대사대부여고" },
  { before: "화곡보건경영고등학교", after: "화곡경영고" },
  { before: "화천정보산업고등학교", after: "화천정보고" },
  { before: "황지정보산업고등학교", after: "황지정보고" },
  { before: "가거도초등학교신안흑산중학교가거도분교장", after: "흑산중" },
  { before: "거창중학교고제분교장", after: "거창중" },
  { before: "건국대학교사범대학부속중학교", after: "건대사대부중" },
  { before: "건양대학교병설건양중학교", after: "건양중" },
  { before: "경북대학교사범대학부설중학교", after: "경북사대부중" },
  { before: "경상국립대학교사범대학부설중학교", after: "경상사대부중" },
  { before: "고려대학교사범대학부속중학교", after: "고려사대부중" },
  { before: "고령중학교개진분교장", after: "고령중" },
  { before: "고흥점암중앙중학교", after: "점암중앙중" },
  { before: "공주대학교사범대학부설중학교", after: "공주사대부중" },
  { before: "광주수피아여자중학교", after: "광주수피아중" },
  { before: "구산중학교구남분교장", after: "구산중" },
  { before: "군위중학교우보분교장", after: "군위중" },
  { before: "금일중학교생일분교장", after: "금일중" },
  { before: "기계중학교기북분교장", after: "기계중" },
  { before: "남평중학교다도분교장", after: "남평중" },
  { before: "넙도초등학교노화중학교넙도분교장", after: "노화중" },
  { before: "단국대학교사범대학부속중학교", after: "단대사대주부중" },
  { before: "당진중학교대호지분교장", after: "당진중" },
  { before: "대구가톨릭대학교사범대학부속무학중학교", after: "무학중" },
  { before: "동국대학교사범대학부속금산중학교", after: "금산중" },
  { before: "동국대학교사범대학부속여자중학교", after: "동대사대여중" },
  { before: "동국대학교사범대학부속중학교", after: "동대사대부중" },
  { before: "동국대학교사범대학부속홍제중학교", after: "홍제중" },
  { before: "삼천포중앙여자중학교", after: "삼천포중앙중" },
  { before: "상명대학교사범대학부속여자중학교", after: "상명사대여중" },
  { before: "서울대학교사범대학부설여자중학교", after: "서울사대여중" },
  { before: "서울대학교사범대학부설중학교", after: "서울사대부중" },
  { before: "성주중학교가천분교장", after: "성주중" },
  { before: "울산스포츠과학중학교", after: "울산스포츠고" },
  { before: "은여울고등학교(진천)", after: "은여울고" },
  { before: "은여울중학교(진천)", after: "은여울중" },
  { before: "이화여자대학교사범대학부속이화·금란중학교", after: "금란중" },
  { before: "인하대학교사범대학부속중학교", after: "인하사대부중" },
  { before: "전남대학교사범대학부설중학교", after: "전남사대부중" },
  { before: "제주대학교사범대학부설중학교", after: "제주사대부중" },
  { before: "중앙대학교사범대학부속중학교", after: "중대사대부중" },
  { before: "청송중학교부동분교장", after: "청송중" },
  { before: "춘양중학교서벽분교장", after: "춘양중" },
  { before: "충북대학교사범대학부설중학교", after: "충북사대부중" },
  { before: "한국교원대학교부설미호중학교", after: "미호중" },
  { before: "한양대학교사범대학부속중학교", after: "한양사대부중" },
  { before: "홍익대학교사범대학부속여자중학교", after: "홍대사대여중" },
  { before: "홍익대학교사범대학부속중학교", after: "홍대사대부중" },
  { before: "화양중학교화양남분교장", after: "화양중" },
];

const shortSchoolName = (school) => {
  if (!school) {
    return "";
  }
  let temp = school;

  for (let i = 0; i < exceptionalArr.length; i++) {
    if (temp.includes(exceptionalArr[i].before)) {
      return exceptionalArr[i].after;
    }
  }

  if (temp.includes("이화여자외국어고등학교")) {
    return "이화외고";
  }

  if (temp.includes("이화여자대학교사범대학부속이화금란고등학교")) {
    return "이대부고";
  }
  if (temp.includes("단국대학교부속소프트웨어고등학교")) {
    return "단대부고";
  }
  if (temp.includes("중앙대학교사범대학부속고등학교")) {
    return "중대부고";
  }

  if (temp.includes("여자")) {
    temp = temp.replace("여자", "여");
  }
  if (temp.includes("남자")) {
    temp = temp.replace("남자", "남");
  }
  if (temp.includes("대학교")) {
    temp = temp.replace("대학교", "대");
  }

  if (temp.includes("비즈니스고등학교")) {
    return temp.replace("비즈니스고등학교", "비고");
  }
  if (temp.includes("체육고등학교")) {
    return temp.replace("체육고등학교", "체고");
  }
  if (temp.includes("외국어고등학교")) {
    return temp.replace("외국어고등학교", "외고");
  }
  if (temp.includes("마이스터고등학교")) {
    return temp.replace("마이스터고등학교", "마고");
  }
  if (temp.includes("과학고등학교")) {
    return temp.replace("과학고등학교", "과고");
  }
  if (temp.includes("소프트웨어고등학교")) {
    return temp.replace("소프트웨어고등학교", "소고");
  }
  if (temp.includes("정보고등학교")) {
    return temp.replace("정보고등학교", "정고");
  }
  if (temp.includes("예술고등학교")) {
    return temp.replace("예술고등학교", "예고");
  }
  if (temp.includes("외국어대학교부설고등학교")) {
    return temp.replace("외국어대학교부설고등학교", "외대부고");
  }
  if (temp.includes("대학교부속고등학교")) {
    return temp.replace("대학교부속고등학교", "대부고");
  }
  if (temp.includes("사범대학부설고등학교")) {
    return temp.replace("사범대학부설고등학교", "사대부고");
  }
  if (temp.includes("사범대학부속고등학교")) {
    return temp.replace("사범대학부속고등학교", "사대부고");
  }
  if (temp.includes("부설고등학교")) {
    return temp.replace("부설고등학교", "부고");
  }
  if (temp.includes("부속고등학교")) {
    return temp.replace("부속고등학교", "부고");
  }
  if (temp.includes("디자인고등학교")) {
    return temp.replace("디자인고등학교", "디고");
  }
  if (temp.includes("디지털고등학교")) {
    return temp.replace("디지털고등학교", "디고");
  }
  if (temp.includes("디지털미디어고등학교")) {
    return temp.replace("디지털미디어고등학교", "디고");
  }
  if (temp.includes("디지털전자고등학교")) {
    return temp.replace("디지털전자고등학교", "디고");
  }
  if (temp.includes("상업고등학교")) {
    return temp.replace("상업고등학교", "상고");
  }
  if (temp.includes("공업고등학교")) {
    return temp.replace("공업고등학교", "공고");
  }
  if (temp.includes("중학교")) {
    return temp.replace("중학교", "중");
  }

  return temp.replace("고등학교", "고");
};
export default shortSchoolName;
