import * as React from "react";

function AppleLogo(props) {
  return (
    <svg
      width={15}
      height={18}
      viewBox="0 0 15 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_114_3018)">
        <path
          d="M14.52 6.137c-.106.08-1.993 1.12-1.993 3.43 0 2.67 2.401 3.616 2.473 3.64-.011.057-.381 1.293-1.266 2.554-.788 1.108-1.612 2.216-2.865 2.216-1.253 0-1.575-.712-3.022-.712-1.41 0-1.91.735-3.056.735-1.146 0-1.946-1.026-2.866-2.286C.86 14.234 0 11.935 0 9.754c0-3.5 2.329-5.356 4.621-5.356 1.218 0 2.233.781 2.998.781.728 0 1.863-.828 3.248-.828.525 0 2.412.047 3.654 1.786zm-4.31-3.268c.572-.664.978-1.585.978-2.507 0-.128-.011-.258-.035-.362-.933.034-2.042.607-2.71 1.365-.526.583-1.016 1.504-1.016 2.439 0 .14.024.28.035.326.06.01.155.023.25.023.837 0 1.89-.547 2.497-1.284z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="clip0_114_3018">
          <path fill="#fff" d="M0 0H15V18H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default AppleLogo;
