const koreaData = {
  title: "세부과목",
  type: "select",
  list: [
    {
      text: "국어",
      title: "세부과목",
      type: "select",
      list: [
        {
          text: "미래엔(신유식)",
          title: "대단원",
          type: "check",
          list: [
            {
              id: "문학의 빛깔",
              text: "문학의 빛깔",
              title: "문학의 빛깔",
            },
            {
              id: "책 속에 길이 있다",
              text: "책 속에 길이 있다",
              title: "책 속에 길이 있다",
            },
            {
              id: "생각하고 표현하고",
              text: "생각하고 표현하고",
              title: "생각하고 표현하고",
            },
            {
              id: "소통의 힘",
              text: "소통의 힘",
              title: "소통의 힘",
            },
            {
              id: "한국 문학의 흐름",
              text: "한국 문학의 흐름",
              title: "한국 문학의 흐름",
            },
            {
              id: "해결해 봅시다",
              text: "해결해 봅시다",
              title: "해결해 봅시다",
            },
            {
              id: "문학의 가치",
              text: "문학의 가치",
              title: "문학의 가치",
            },
            {
              id: "우리말 우리글 사랑하기",
              text: "우리말 우리글 사랑하기",
              title: "우리말 우리글 사랑하기",
            },
          ],
        },
        {
          text: "해냄(정민)",
          title: "대단원",
          type: "check",
          list: [
            {
              id: "읽기와 쓰기로 세상을 보다",
              text: "읽기와 쓰기로 세상을 보다",
              title: "읽기와 쓰기로 세상을 보다",
            },
            {
              id: "문학의 여러 빛깔",
              text: "문학의 여러 빛깔",
              title: "문학의 여러 빛깔",
            },
            {
              id: "공감과 이해의 듣기, 말하기",
              text: "공감과 이해의 듣기, 말하기",
              title: "공감과 이해의 듣기, 말하기",
            },
            {
              id: "올바르게 읽고 쓰기",
              text: "올바르게 읽고 쓰기",
              title: "올바르게 읽고 쓰기",
            },
            {
              id: "우리 문학의 숨과 결",
              text: "우리 문학의 숨과 결",
              title: "우리 문학의 숨과 결",
            },
            {
              id: "문제 해결의 지름길, 소통",
              text: "문제 해결의 지름길, 소통",
              title: "문제 해결의 지름길, 소통",
            },
          ],
        },
        {
          text: "동아(고형진)",
          title: "대단원",
          type: "check",
          list: [
            {
              id: "문학의 갈래와 특성",
              text: "문학의 갈래와 특성",
              title: "문학의 갈래와 특성",
            },
            {
              id: "진로 탐색의 길",
              text: "진로 탐색의 길",
              title: "진로 탐색의 길",
            },
            {
              id: "효과적인 의사소통",
              text: "효과적인 의사소통",
              title: "효과적인 의사소통",
            },
            {
              id: "읽기와 쓰기의 방법과 태도",
              text: "읽기와 쓰기의 방법과 태도",
              title: "읽기와 쓰기의 방법과 태도",
            },
            {
              id: "감상과 표현",
              text: "감상과 표현",
              title: "감상과 표현",
            },
            {
              id: "문제 해결과 설득",
              text: "문제 해결과 설득",
              title: "문제 해결과 설득",
            },
            {
              id: "국어의 변천과 발전",
              text: "국어의 변천과 발전",
              title: "국어의 변천과 발전",
            },
            {
              id: "한국 문학의 이해",
              text: "한국 문학의 이해",
              title: "한국 문학의 이해",
            },
            {
              id: "생각을 나누는 말과 글",
              text: "생각을 나누는 말과 글",
              title: "생각을 나누는 말과 글",
            },
          ],
        },
        {
          text: "신사고(민현식)",
          title: "대단원",
          type: "check",
          list: [
            {
              id: "나의 문학, 나의 꿈",
              text: "나의 문학, 나의 꿈",
              title: "나의 문학, 나의 꿈",
            },
            {
              id: "우리 문학의 전통과 가치",
              text: "우리 문학의 전통과 가치",
              title: "우리 문학의 전통과 가치",
            },
            {
              id: "국어의 어제와 오늘",
              text: "국어의 어제와 오늘",
              title: "국어의 어제와 오늘",
            },
            {
              id: "통하는 국어 생활",
              text: "통하는 국어 생활",
              title: "통하는 국어 생활",
            },
            {
              id: "잘 읽고 잘 쓰는 법",
              text: "잘 읽고 잘 쓰는 법",
              title: "잘 읽고 잘 쓰는 법",
            },
            {
              id: "문학, 쓰기, 읽기와의 첫 만남",
              text: "문학, 쓰기, 읽기와의 첫 만남",
              title: "문학, 쓰기, 읽기와의 첫 만남",
            },
            {
              id: "삶을 담은 문학, 삶을 담아 쓰기",
              text: "삶을 담은 문학, 삶을 담아 쓰기",
              title: "삶을 담은 문학, 삶을 담아 쓰기",
            },
            {
              id: "생각을 나누는 시간",
              text: "생각을 나누는 시간",
              title: "생각을 나누는 시간",
            },
          ],
        },
        {
          text: "비상(박안수)",
          title: "대단원",
          type: "check",
          list: [
            {
              id: "함께 만드는 세상",
              text: "함께 만드는 세상",
              title: "함께 만드는 세상",
            },
            {
              id: "우리의 말과 글을 따라서",
              text: "우리의 말과 글을 따라서",
              title: "우리의 말과 글을 따라서",
            },
            {
              id: "한국 문학의 빛깔",
              text: "한국 문학의 빛깔",
              title: "한국 문학의 빛깔",
            },
            {
              id: "문제를 해결하는 힘",
              text: "문제를 해결하는 힘",
              title: "문제를 해결하는 힘",
            },
            {
              id: "읽기의 가치와 즐거움",
              text: "읽기의 가치와 즐거움",
              title: "읽기의 가치와 즐거움",
            },
            {
              id: "마음을 잇는 소통의 창",
              text: "마음을 잇는 소통의 창",
              title: "마음을 잇는 소통의 창",
            },
            {
              id: "문학으로 그리는 삶",
              text: "문학으로 그리는 삶",
              title: "문학으로 그리는 삶",
            },
            {
              id: "책 속의 지혜, 말 속의 길",
              text: "책 속의 지혜, 말 속의 길",
              title: "책 속의 지혜, 말 속의 길",
            },
          ],
        },
        {
          text: "지학사(이삼형)",
          title: "대단원",
          type: "check",
          list: [
            {
              id: "문학 속으로",
              text: "문학 속으로",
              title: "문학 속으로",
            },
            {
              id: "말과 글의 힘",
              text: "말과 글의 힘",
              title: "말과 글의 힘",
            },
            {
              id: "삶에서 책을 찾다",
              text: "삶에서 책을 찾다",
              title: "삶에서 책을 찾다",
            },
            {
              id: "나, 너, 우리의 말과 글",
              text: "나, 너, 우리의 말과 글",
              title: "나, 너, 우리의 말과 글",
            },
            {
              id: "문학 너머로",
              text: "문학 너머로",
              title: "문학 너머로",
            },
            {
              id: "매듭을 푸는 말과 글",
              text: "매듭을 푸는 말과 글",
              title: "매듭을 푸는 말과 글",
            },
            {
              id: "책에서 삶을 찾다",
              text: "책에서 삶을 찾다",
              title: "책에서 삶을 찾다",
            },
            {
              id: "우리 문학의 길을 따라",
              text: "우리 문학의 길을 따라",
              title: "우리 문학의 길을 따라",
            },
          ],
        },
        {
          text: "천재(박영목)",
          title: "대단원",
          type: "check",
          list: [
            {
              id: "한국 문학의 이해",
              text: "한국 문학의 이해",
              title: "한국 문학의 이해",
            },
            {
              id: "생각을 키우는 읽기와 쓰기",
              text: "생각을 키우는 읽기와 쓰기",
              title: "생각을 키우는 읽기와 쓰기",
            },
            {
              id: "문제 해결을 위한 의사소통",
              text: "문제 해결을 위한 의사소통",
              title: "문제 해결을 위한 의사소통",
            },
            {
              id: "문학과 삶",
              text: "문학과 삶",
              title: "문학과 삶",
            },
            {
              id: "마음을 담은 언어",
              text: "마음을 담은 언어",
              title: "마음을 담은 언어",
            },
            {
              id: "능동적 읽기와 주체적 해석",
              text: "능동적 읽기와 주체적 해석",
              title: "능동적 읽기와 주체적 해석",
            },
            {
              id: "문학의 갈래와 구조",
              text: "문학의 갈래와 구조",
              title: "문학의 갈래와 구조",
            },
            {
              id: "매체와 설득",
              text: "매체와 설득",
              title: "매체와 설득",
            },
          ],
        },
        {
          text: "비상(박영민)",
          title: "대단원",
          type: "check",
          list: [
            {
              id: "세상을 바라보는 예리한 눈",
              text: "세상을 바라보는 예리한 눈",
              title: "세상을 바라보는 예리한 눈",
            },
            {
              id: "설득이라는 이름의 창과 방패",
              text: "설득이라는 이름의 창과 방패",
              title: "설득이라는 이름의 창과 방패",
            },
            {
              id: "삶 속에 흐르는 한국 문학의 강",
              text: "삶 속에 흐르는 한국 문학의 강",
              title: "삶 속에 흐르는 한국 문학의 강",
            },
            {
              id: "함께 나누고 싶은 이야기",
              text: "함께 나누고 싶은 이야기",
              title: "함께 나누고 싶은 이야기",
            },
            {
              id: "문학의 네 가지 빛깔",
              text: "문학의 네 가지 빛깔",
              title: "문학의 네 가지 빛깔",
            },
            {
              id: "엉킨 삶의 실타래를 푸는 지혜",
              text: "엉킨 삶의 실타래를 푸는 지혜",
              title: "엉킨 삶의 실타래를 푸는 지혜",
            },
          ],
        },
        {
          text: "금성(류수열)",
          title: "대단원",
          type: "check",
          list: [
            {
              id: "문학이라는 이름의 나무",
              text: "문학이라는 이름의 나무",
              title: "문학이라는 이름의 나무",
            },
            {
              id: "생각 다듬기, 표현 가다듬기",
              text: "생각 다듬기, 표현 가다듬기",
              title: "생각 다듬기, 표현 가다듬기",
            },
            {
              id: "성찰하는 눈",
              text: "성찰하는 눈",
              title: "성찰하는 눈",
            },
            {
              id: "글, 사회적 상호 작용의 통로",
              text: "글, 사회적 상호 작용의 통로",
              title: "글, 사회적 상호 작용의 통로",
            },
            {
              id: "나답게 읽기",
              text: "나답게 읽기",
              title: "나답게 읽기",
            },
            {
              id: "문제 해결을 위한 언어생활",
              text: "문제 해결을 위한 언어생활",
              title: "문제 해결을 위한 언어생활",
            },
            {
              id: "문학사의 지평에서",
              text: "문학사의 지평에서",
              title: "문학사의 지평에서",
            },
          ],
        },
        {
          text: "창비(최원식)",
          title: "대단원",
          type: "check",
          list: [
            {
              id: "독서는 나의 힘",
              text: "독서는 나의 힘",
              title: "독서는 나의 힘",
            },
            {
              id: "문학의 갈래",
              text: "문학의 갈래",
              title: "문학의 갈래",
            },
            {
              id: "소통하는 말과 글",
              text: "소통하는 말과 글",
              title: "소통하는 말과 글",
            },
            {
              id: "문학의 수용과 생산",
              text: "문학의 수용과 생산",
              title: "문학의 수용과 생산",
            },
            {
              id: "문제를 해결하는 말과 글",
              text: "문제를 해결하는 말과 글",
              title: "문제를 해결하는 말과 글",
            },
          ],
        },
        {
          text: "천재(이성영)",
          title: "대단원",
          type: "check",
          list: [
            {
              id: "올바른 말과 글",
              text: "올바른 말과 글",
              title: "올바른 말과 글",
            },
            {
              id: "문학의 특성",
              text: "문학의 특성",
              title: "문학의 특성",
            },
            {
              id: "미래를 여는 한 권의 책",
              text: "미래를 여는 한 권의 책",
              title: "미래를 여는 한 권의 책",
            },
            {
              id: "마음을 움직이는 말과 글",
              text: "마음을 움직이는 말과 글",
              title: "마음을 움직이는 말과 글",
            },
            {
              id: "상황에 맞는 표현",
              text: "상황에 맞는 표현",
              title: "상황에 맞는 표현",
            },
            {
              id: "서로에게 한 걸음씩",
              text: "서로에게 한 걸음씩",
              title: "서로에게 한 걸음씩",
            },
            {
              id: "문학과 삶",
              text: "문학과 삶",
              title: "문학과 삶",
            },
            {
              id: "소통과 참여",
              text: "소통과 참여",
              title: "소통과 참여",
            },
            {
              id: "국어의 변화와 발전",
              text: "국어의 변화와 발전",
              title: "국어의 변화와 발전",
            },
            {
              id: "비평과 생산",
              text: "비평과 생산",
              title: "비평과 생산",
            },
          ],
        },
      ],
    },
    {
      text: "문학",
      title: "세부과목",
      type: "select",
      list: [
        {
          text: "지학사(정재찬)",
          title: "대단원",
          type: "check",
          list: [
            {
              id: "문학의 본질과 가치",
              text: "문학의 본질과 가치",
              title: "문학의 본질과 가치",
            },
            {
              id: "문학의 소통",
              text: "문학의 소통",
              title: "문학의 소통",
            },
            {
              id: "한국 문학의 성격",
              text: "한국 문학의 성격",
              title: "한국 문학의 성격",
            },
            {
              id: "한국 문학의 흐름",
              text: "한국 문학의 흐름",
              title: "한국 문학의 흐름",
            },
          ],
        },
        {
          text: "신사고(이숭원)",
          title: "대단원",
          type: "check",
          list: [
            {
              id: "문학의 본질과 구조",
              text: "문학의 본질과 구조",
              title: "문학의 본질과 구조",
            },
            {
              id: "문학의 수용과 생산",
              text: "문학의 수용과 생산",
              title: "문학의 수용과 생산",
            },
            {
              id: "한국 문학의 성격과 위상",
              text: "한국 문학의 성격과 위상",
              title: "한국 문학의 성격과 위상",
            },
            {
              id: "한국 문학의 갈래와 흐름",
              text: "한국 문학의 갈래와 흐름",
              title: "한국 문학의 갈래와 흐름",
            },
            {
              id: "문학에 관한 태도",
              text: "문학에 관한 태도",
              title: "문학에 관한 태도",
            },
          ],
        },
        {
          text: "비상(한철우)",
          title: "대단원",
          type: "check",
          list: [
            {
              id: "문학의 본질",
              text: "문학의 본질",
              title: "문학의 본질",
            },
            {
              id: "문학의 수용과 생산",
              text: "문학의 수용과 생산",
              title: "문학의 수용과 생산",
            },
            {
              id: "한국 문학의 성격",
              text: "한국 문학의 성격",
              title: "한국 문학의 성격",
            },
            {
              id: "한국 문학의 역사",
              text: "한국 문학의 역사",
              title: "한국 문학의 역사",
            },
            {
              id: "문학에 관한 태도",
              text: "문학에 관한 태도",
              title: "문학에 관한 태도",
            },
          ],
        },
        {
          text: "미래엔(방민호)",
          title: "대단원",
          type: "check",
          list: [
            {
              id: "문학의 기능과 원리",
              text: "문학의 기능과 원리",
              title: "문학의 기능과 원리",
            },
            {
              id: "문학의 수용과 생산",
              text: "문학의 수용과 생산",
              title: "문학의 수용과 생산",
            },
            {
              id: "한국 문학의 개념과 성격",
              text: "한국 문학의 개념과 성격",
              title: "한국 문학의 개념과 성격",
            },
            {
              id: "한국 문학의 갈래와 흐름",
              text: "한국 문학의 갈래와 흐름",
              title: "한국 문학의 갈래와 흐름",
            },
            {
              id: "문학의 가치",
              text: "문학의 가치",
              title: "문학의 가치",
            },
          ],
        },
        {
          text: "천재(정호웅)",
          title: "대단원",
          type: "check",
          list: [
            {
              id: "문학과 삶",
              text: "문학과 삶",
              title: "문학과 삶",
            },
            {
              id: "문학의 수용과 생산",
              text: "문학의 수용과 생산",
              title: "문학의 수용과 생산",
            },
            {
              id: "한국 문학의 갈래와 흐름",
              text: "한국 문학의 갈래와 흐름",
              title: "한국 문학의 갈래와 흐름",
            },
            {
              id: "한국 문학의 과거와 현재 그리고 미래",
              text: "한국 문학의 과거와 현재 그리고 미래",
              title: "한국 문학의 과거와 현재 그리고 미래",
            },
          ],
        },
        {
          text: "해냄(조정래)",
          title: "대단원",
          type: "check",
          list: [
            {
              id: "문학이란 무엇인가",
              text: "문학이란 무엇인가",
              title: "문학이란 무엇인가",
            },
            {
              id: "문학 활동의 즐거움 속으로",
              text: "문학 활동의 즐거움 속으로",
              title: "문학 활동의 즐거움 속으로",
            },
            {
              id: "한국 문학, 어떻게 이해할까",
              text: "한국 문학, 어떻게 이해할까",
              title: "한국 문학, 어떻게 이해할까",
            },
            {
              id: "한국 문학이 걸어온 길을 따라서",
              text: "한국 문학이 걸어온 길을 따라서",
              title: "한국 문학이 걸어온 길을 따라서",
            },
            {
              id: "삶과 함께하는 문학",
              text: "삶과 함께하는 문학",
              title: "삶과 함께하는 문학",
            },
          ],
        },
        {
          text: "금성(류수열)",
          title: "대단원",
          type: "check",
          list: [
            {
              id: "문학으로 우리는 무엇을 하는가",
              text: "문학으로 우리는 무엇을 하는가",
              title: "문학으로 우리는 무엇을 하는가",
            },
            {
              id: "문학 활동, 어떻게 하는가",
              text: "문학 활동, 어떻게 하는가",
              title: "문학 활동, 어떻게 하는가",
            },
            {
              id: "문학의 동반자들",
              text: "문학의 동반자들",
              title: "문학의 동반자들",
            },
            {
              id: "한국 문학의 내포와 외연",
              text: "한국 문학의 내포와 외연",
              title: "한국 문학의 내포와 외연",
            },
            {
              id: "한국 문학이 걸어온 길",
              text: "한국 문학이 걸어온 길",
              title: "한국 문학이 걸어온 길",
            },
            {
              id: "문학에 임하는 우리의 태도",
              text: "문학에 임하는 우리의 태도",
              title: "문학에 임하는 우리의 태도",
            },
          ],
        },
        {
          text: "창비(최원식)",
          title: "대단원",
          type: "check",
          list: [
            {
              id: "문학의 본질",
              text: "문학의 본질",
              title: "문학의 본질",
            },
            {
              id: "문학의 수용과 생산",
              text: "문학의 수용과 생산",
              title: "문학의 수용과 생산",
            },
            {
              id: "한국 문학의 성격과 역사",
              text: "한국 문학의 성격과 역사",
              title: "한국 문학의 성격과 역사",
            },
            {
              id: "문학과 삶",
              text: "문학과 삶",
              title: "문학과 삶",
            },
          ],
        },
        {
          text: "천재(김동환)",
          title: "대단원",
          type: "check",
          list: [
            {
              id: "문학과 삶",
              text: "문학과 삶",
              title: "문학과 삶",
            },
            {
              id: "문학의 수용과 생산",
              text: "문학의 수용과 생산",
              title: "문학의 수용과 생산",
            },
            {
              id: "한국 문학의 흐름",
              text: "한국 문학의 흐름",
              title: "한국 문학의 흐름",
            },
            {
              id: "한국 문학의 위상",
              text: "한국 문학의 위상",
              title: "한국 문학의 위상",
            },
          ],
        },
        {
          text: "동아(김창원)",
          title: "대단원",
          type: "check",
          list: [
            {
              id: "문학의 본질",
              text: "문학의 본질",
              title: "문학의 본질",
            },
            {
              id: "문학의 수용과 생산",
              text: "문학의 수용과 생산",
              title: "문학의 수용과 생산",
            },
            {
              id: "한국 문학의 이해",
              text: "한국 문학의 이해",
              title: "한국 문학의 이해",
            },
            {
              id: "문학의 생활화",
              text: "문학의 생활화",
              title: "문학의 생활화",
            },
          ],
        },
      ],
    },
    {
      text: "독서",
      title: "세부과목",
      type: "select",
      list: [
        {
          text: "비상(한철우)",
          title: "대단원",
          type: "check",
          list: [
            {
              id: "나를 기르는 독서",
              text: "나를 기르는 독서",
              title: "나를 기르는 독서",
            },
            {
              id: "소통하는 독서",
              text: "소통하는 독서",
              title: "소통하는 독서",
            },
            {
              id: "새롭게 바라보는 독서",
              text: "새롭게 바라보는 독서",
              title: "새롭게 바라보는 독서",
            },
            {
              id: "가치를 내면화하는 독서",
              text: "가치를 내면화하는 독서",
              title: "가치를 내면화하는 독서",
            },
            {
              id: "세상과 만나는 독서",
              text: "세상과 만나는 독서",
              title: "세상과 만나는 독서",
            },
            {
              id: "정보를 활용하는 독서",
              text: "정보를 활용하는 독서",
              title: "정보를 활용하는 독서",
            },
          ],
        },
        {
          text: "미래엔(방민호)",
          title: "대단원",
          type: "check",
          list: [
            {
              id: "독서의 본질",
              text: "독서의 본질",
              title: "독서의 본질",
            },
            {
              id: "독서의 계획과 태도",
              text: "독서의 계획과 태도",
              title: "독서의 계획과 태도",
            },
            {
              id: "독서의 방법",
              text: "독서의 방법",
              title: "독서의 방법",
            },
            {
              id: "다양한 분야의 글 읽기",
              text: "다양한 분야의 글 읽기",
              title: "다양한 분야의 글 읽기",
            },
            {
              id: "다양한 배경의 글 읽기",
              text: "다양한 배경의 글 읽기",
              title: "다양한 배경의 글 읽기",
            },
          ],
        },
        {
          text: "신사고(서혁)",
          title: "대단원",
          type: "check",
          list: [
            {
              id: "독서의 본질",
              text: "독서의 본질",
              title: "독서의 본질",
            },
            {
              id: "독서의 방법",
              text: "독서의 방법",
              title: "독서의 방법",
            },
            {
              id: "독서의 분야",
              text: "독서의 분야",
              title: "독서의 분야",
            },
            {
              id: "독서의 태도",
              text: "독서의 태도",
              title: "독서의 태도",
            },
          ],
        },
        {
          text: "천재(박영목)",
          title: "대단원",
          type: "check",
          list: [
            {
              id: "독서의 본질",
              text: "독서의 본질",
              title: "독서의 본질",
            },
            {
              id: "독서의 방법",
              text: "독서의 방법",
              title: "독서의 방법",
            },
            {
              id: "독서의 분야 1",
              text: "독서의 분야 1",
              title: "독서의 분야 1",
            },
            {
              id: "독서의 분야 2",
              text: "독서의 분야 2",
              title: "독서의 분야 2",
            },
            {
              id: "독서의 태도",
              text: "독서의 태도",
              title: "독서의 태도",
            },
          ],
        },
        {
          text: "지학사(이삼형)",
          title: "대단원",
          type: "check",
          list: [
            {
              id: "독서의 본질과 태도",
              text: "독서의 본질과 태도",
              title: "독서의 본질과 태도",
            },
            {
              id: "독서의 방법",
              text: "독서의 방법",
              title: "독서의 방법",
            },
            {
              id: "다양한 분야의 글 읽기",
              text: "다양한 분야의 글 읽기",
              title: "다양한 분야의 글 읽기",
            },
            {
              id: "다양한 특성의 글 읽기",
              text: "다양한 특성의 글 읽기",
              title: "다양한 특성의 글 읽기",
            },
          ],
        },
        {
          text: "동아(고형진)",
          title: "대단원",
          type: "check",
          list: [
            {
              id: "독서의 본질",
              text: "독서의 본질",
              title: "독서의 본질",
            },
            {
              id: "독서의 방법",
              text: "독서의 방법",
              title: "독서의 방법",
            },
            {
              id: "독서의 분야 1",
              text: "독서의 분야 1",
              title: "독서의 분야 1",
            },
            {
              id: "독서의 분야 2",
              text: "독서의 분야 2",
              title: "독서의 분야 2",
            },
            {
              id: "독서의 태도",
              text: "독서의 태도",
              title: "독서의 태도",
            },
          ],
        },
      ],
    },
    {
      text: "언어와 매체",
      title: "세부과목",
      type: "select",
      list: [
        {
          text: "지학사(이삼형)",
          title: "대단원",
          type: "check",
          list: [
            {
              id: "언어와 매체의 본질",
              text: "언어와 매체의 본질",
              title: "언어와 매체의 본질",
            },
            {
              id: "담화와 문장의 탐구 및 활용",
              text: "담화와 문장의 탐구 및 활용",
              title: "담화와 문장의 탐구 및 활용",
            },
            {
              id: "단어와 음운의 탐구 및 활용",
              text: "단어와 음운의 탐구 및 활용",
              title: "단어와 음운의 탐구 및 활용",
            },
          ],
        },
        {
          text: "비상(이관규)",
          title: "대단원",
          type: "check",
          list: [
            {
              id: "언어와 매체의 본질",
              text: "언어와 매체의 본질",
              title: "언어와 매체의 본질",
            },
            {
              id: "담화와 문장의 탐구 및 활용",
              text: "담화와 문장의 탐구 및 활용",
              title: "담화와 문장의 탐구 및 활용",
            },
            {
              id: "단어와 음운의 탐구 및 활용",
              text: "단어와 음운의 탐구 및 활용",
              title: "단어와 음운의 탐구 및 활용",
            },
            {
              id: "매체 언어의 탐구 및 활용",
              text: "매체 언어의 탐구 및 활용",
              title: "매체 언어의 탐구 및 활용",
            },
            {
              id: "국어 자료와 매체 언어생활",
              text: "국어 자료와 매체 언어생활",
              title: "국어 자료와 매체 언어생활",
            },
          ],
        },
        {
          text: "천재(민현식)",
          title: "대단원",
          type: "check",
          list: [
            {
              id: "언어와 매체 언어",
              text: "언어와 매체 언어",
              title: "언어와 매체 언어",
            },
            {
              id: "단어의 특성과 매체 언어의 표현",
              text: "단어의 특성과 매체 언어의 표현",
              title: "단어의 특성과 매체 언어의 표현",
            },
            {
              id: "국어의 규범과 매체 언어의 성찰",
              text: "국어의 규범과 매체 언어의 성찰",
              title: "국어의 규범과 매체 언어의 성찰",
            },
            {
              id: "문장과 담화, 매체 문화의 향유",
              text: "문장과 담화, 매체 문화의 향유",
              title: "문장과 담화, 매체 문화의 향유",
            },
            {
              id: "국어의 변화와 매체 문화의 발전",
              text: "국어의 변화와 매체 문화의 발전",
              title: "국어의 변화와 매체 문화의 발전",
            },
          ],
        },
        {
          text: "미래엔(방민호)",
          title: "대단원",
          type: "check",
          list: [
            {
              id: "언어와 매체의 본질",
              text: "언어와 매체의 본질",
              title: "언어와 매체의 본질",
            },
            {
              id: "매체의 탐구와 활용",
              text: "매체의 탐구와 활용",
              title: "매체의 탐구와 활용",
            },
            {
              id: "국어 자료의 탐구",
              text: "국어 자료의 탐구",
              title: "국어 자료의 탐구",
            },
            {
              id: "언어와 매체에 대한 태도",
              text: "언어와 매체에 대한 태도",
              title: "언어와 매체에 대한 태도",
            },
          ],
        },
        {
          text: "창비(최형용)",
          title: "대단원",
          type: "check",
          list: [
            {
              id: "언어와 매체의 본질",
              text: "언어와 매체의 본질",
              title: "언어와 매체의 본질",
            },
            {
              id: "국어의 탐구와 활용",
              text: "국어의 탐구와 활용",
              title: "국어의 탐구와 활용",
            },
            {
              id: "매체 언어의 탐구와 활용",
              text: "매체 언어의 탐구와 활용",
              title: "매체 언어의 탐구와 활용",
            },
            {
              id: "생활 속 언어와 매체",
              text: "생활 속 언어와 매체",
              title: "생활 속 언어와 매체",
            },
          ],
        },
      ],
    },
    {
      text: "화법과 작문",
      title: "세부과목",
      type: "select",
      list: [
        {
          text: "천재(박영목)",
          title: "대단원",
          type: "check",
          list: [
            {
              id: "화법과 작문의 본질",
              text: "화법과 작문의 본질",
              title: "화법과 작문의 본질",
            },
            {
              id: "화법의 원리와 실제",
              text: "화법의 원리와 실제",
              title: "화법의 원리와 실제",
            },
            {
              id: "작문의 원리와 실제",
              text: "작문의 원리와 실제",
              title: "작문의 원리와 실제",
            },
            {
              id: "화법과 작문의 태도",
              text: "화법과 작문의 태도",
              title: "화법과 작문의 태도",
            },
          ],
        },
        {
          text: "비상(박영민)",
          title: "대단원",
          type: "check",
          list: [
            {
              id: "화법과 작문의 본질과 태도",
              text: "화법과 작문의 본질과 태도",
              title: "화법과 작문의 본질과 태도",
            },
            {
              id: "화법의 원리와 실제",
              text: "화법의 원리와 실제",
              title: "화법의 원리와 실제",
            },
            {
              id: "작문의 원리와 실제",
              text: "작문의 원리와 실제",
              title: "작문의 원리와 실제",
            },
          ],
        },
        {
          text: "미래엔(민병곤)",
          title: "대단원",
          type: "check",
          list: [
            {
              id: "화법과 작문의 본질과 태도",
              text: "화법과 작문의 본질과 태도",
              title: "화법과 작문의 본질과 태도",
            },
            {
              id: "자아 성찰과 정서 표현",
              text: "자아 성찰과 정서 표현",
              title: "자아 성찰과 정서 표현",
            },
            {
              id: "관계 형성을 위한 의사소통",
              text: "관계 형성을 위한 의사소통",
              title: "관계 형성을 위한 의사소통",
            },
            {
              id: "정보의 효과적 전달",
              text: "정보의 효과적 전달",
              title: "정보의 효과적 전달",
            },
            {
              id: "공감과 설득",
              text: "공감과 설득",
              title: "공감과 설득",
            },
            {
              id: "공동체 문제의 협력적 해결",
              text: "공동체 문제의 협력적 해결",
              title: "공동체 문제의 협력적 해결",
            },
            {
              id: "사회를 향한 말과 글",
              text: "사회를 향한 말과 글",
              title: "사회를 향한 말과 글",
            },
          ],
        },
        {
          text: "지학사(이삼형)",
          title: "대단원",
          type: "check",
          list: [
            {
              id: "화법과 작문의 본질",
              text: "화법과 작문의 본질",
              title: "화법과 작문의 본질",
            },
            {
              id: "생활 속의 화법과 작문",
              text: "생활 속의 화법과 작문",
              title: "생활 속의 화법과 작문",
            },
          ],
        },
        {
          text: "창비(이도영)",
          title: "대단원",
          type: "check",
          list: [
            {
              id: "화법의 원리와 실제",
              text: "화법의 원리와 실제",
              title: "화법의 원리와 실제",
            },
            {
              id: "작문의 원리와 실제",
              text: "작문의 원리와 실제",
              title: "작문의 원리와 실제",
            },
          ],
        },
      ],
    },
  ],
};
export default koreaData;
