import React, { useRef } from "react";
import { Col, Pre3, Pre4, Row } from "../../style/GlobalStyled";
import theme from "../../style/theme";
import { CustomButton } from "./CustomButton";
import CustomLoading from "./CustomLoading";
import ModalHeader from "../Molecules/ModalHeader";
import { bottomUpModalStyle } from "../../style/GlobalStyle";

const CustomAlertModal = (props) => {
  const { propsData, style, mobile, offModal } = props;
  const loadingRef = useRef(null);
  const { type, title, text, buttons, input } = propsData;

  return (
    <Col
      style={
        type === "check" && mobile
          ? { ...bottomUpModalStyle }
          : type === "check" && !mobile
          ? {
              height: type === "check" ? 254 : 168,
              width: type === "check" ? 468 : 295,
              backgroundColor: theme.White,
              borderRadius: 20,
            }
          : {
              height: type === "check" ? 254 : 168,
              width: type === "check" ? 468 : 295,
              backgroundColor: theme.White,
              borderRadius: 20,
            }
      }
    >
      <Col style={{ position: "relative" }}>
        <ModalHeader title={title} offModal={type === "check" && offModal} />
        <Col
          style={{
            height: "auto",
          }}
        >
          {Boolean(text) && (
            <Pre4
              fontWeight="Regular"
              style={{
                lineHeight: type === "check" ? "22.4px" : "40px",
                textAlign: "center",
                whiteSpace: "pre-wrap",
                marginBottom: type === "check" ? 0 : 12,
                height: type === "check" ? "auto" : 40,
              }}
            >
              {text}
            </Pre4>
          )}
          {Boolean(input) && <Col>{input}</Col>}
          <Row
            style={{
              height: "auto",
              padding: type === "check" ? "0px 40px" : "0px 20px",
              marginBottom: type === "check" ? 32 : 20,
            }}
          >
            {buttons.map((v, index) => {
              return (
                <CustomButton
                  size={"Full"}
                  title={v.text}
                  btnType={v?.type || "Primary"}
                  onClick={() => {
                    if (v.text === "다운로드") {
                      loadingRef.current.show();
                    }
                    v.onClick();
                  }}
                  style={{
                    flex: 1,
                    marginLeft: index === 0 ? 0 : 12,
                    ...style,
                  }}
                  key={`alert_buttons_${index}`}
                />
              );
            })}
          </Row>
        </Col>
        <CustomLoading
          ref={loadingRef}
          init={false}
          size={48}
          backgroundColor={theme.White}
          style={{ borderRadius: 20 }}
        />
      </Col>
    </Col>
  );
};

export default CustomAlertModal;
