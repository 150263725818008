import * as React from 'react';

function NaverLogo(props) {
    return (
        <svg width={18} height={19} viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#clip0_117_3417)">
                <path d="M12.205 10.134L5.531.5H0v18h5.795V8.866l6.674 9.634H18V.5h-5.795v9.634z" fill="#fff" />
            </g>
            <defs>
                <clipPath id="clip0_117_3417">
                    <path fill="#fff" transform="translate(0 .5)" d="M0 0H18V18H0z" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default NaverLogo;
