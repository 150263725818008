import React from "react";
import { Col, Pre2, Pre3, Row } from "../../style/GlobalStyled";
import theme from "../../style/theme";
import { useResponsive } from "../../utils/responsive";

const FilterCategory = ({ list, category, setCategory }) => {
  const { isDeskTop, HORIZONTAL_MARGIN, isMobile } = useResponsive();

  if (isDeskTop) {
    return (
      <Col
        style={{
          height: "auto",
          padding: 20,
          border: `1px solid ${theme.Gray2}`,
          borderRadius: 12,
        }}
      >
        {list.map((v, index) => {
          const selected = v === category;
          return (
            <Row
              style={{
                height: "auto",
                justifyContent: "flex-start",
                marginTop: index === 0 ? 0 : 20,
              }}
              key={`category_${index}`}
            >
              <Pre3
                fontWeight="Medium"
                color={selected ? theme.Blue12 : theme.Gray10}
                style={{ cursor: "pointer", userSelect: "none" }}
                onClick={() => {
                  setCategory(v);
                  window.sessionStorage.setItem("@category", v);
                }}
              >
                {v}
              </Pre3>
            </Row>
          );
        })}
      </Col>
    );
  } else {
    return (
      <Row
        style={{
          height: "auto",
          justifyContent: "flex-start",
          width: isDeskTop ? "100%" : `calc(100% - ${2 * HORIZONTAL_MARGIN}px)`,
          maxWidth: 714,
          marginBottom: isMobile ? 16 : 24,
          overflowX: "auto",
        }}
      >
        {list.map((v, index) => {
          const selected = v === category;
          return (
            <Row
              style={{
                width: "auto",
                height: "auto",
                justifyContent: "flex-start",
                padding: isMobile ? "0 10px 12px" : "0 8px 12px",
                flexShrink: 0,
                borderBottom: "2px solid",
                borderBottomColor: selected ? theme.Gray10 : theme.Gray1,
              }}
              key={`category_${index}`}
            >
              <Pre2
                color={selected ? theme.Gray10 : theme.Gray6}
                style={{
                  cursor: "pointer",
                  userSelect: "none",
                  fontSize: isMobile ? 16 : 20,
                }}
                onClick={() => {
                  setCategory(v);
                  window.sessionStorage.setItem("@category", v);
                }}
              >
                {v}
              </Pre2>
            </Row>
          );
        })}
      </Row>
    );
  }
};

export default FilterCategory;
