import React, { useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { categoryState, subjectState } from "../atoms/atoms";
import CustomLoading from "../components/Atoms/CustomLoading";
import DesktopPurchase from "../components/Molecules/DesktopPurchase";
import TabletPurchase from "../components/Molecules/TabletPurchase";
import catchError from "../function/catchError";
import getToken from "../function/getToken";
import useCheryExamAPI from "../hooks/useCheryExamAPI";
import ExamPass360 from "../images/svg/ExamPass360";
import ExamPass60 from "../images/svg/ExamPass60";
import ExamPass90 from "../images/svg/ExamPass90";
import { useResponsive } from "../utils/responsive";

const PurchasePage = () => {
  const [duration, setDuration] = useState(90);
  const [subject] = useRecoilState(subjectState);
  const [category] = useRecoilState(categoryState);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [CHERY_EXAM_API] = useMemo(useCheryExamAPI, []);
  const { isMobile, isDeskTop } = useResponsive();
  const loadingRef = useRef(null);

  const productName = `CheryExam 슈퍼패스 ${"선생님용".slice(
    0,
    -1
  )} ${duration}일`;

  const onPurchase = async () => {
    try {
      loadingRef.current?.show();
      const session = await getToken();
      if (session?.token?.accessToken) {
        const params = {
          body: {
            token: session?.token?.accessToken,
            product: {
              price: purchaseObj["선생님용"][duration].price.value,
              type: "CHERY_EXAM_PASS",
              product_name: productName,
              amount: purchaseObj["선생님용"][duration].amount.value,
              duration,
            },
          },
        };
        const { data } =
          await CHERY_EXAM_API.purchase.makeLinkForDirectPurchaseExam(params);
        window.open(data.link, "_blank");
      }
    } catch (e) {
      console.log(e, "makeLinkForDirectPurchaseExam");
      await catchError({ e, navigate, pathname, subject, category });
    } finally {
      loadingRef.current?.hide();
    }
  };

  const purchaseObj = {
    선생님용: {
      90: {
        amount: { value: 1000 },
        price: { value: 240000 },
        image: (width) => (
          <ExamPass90 width={width} borderRadius={isMobile ? 0 : 12} />
        ),
      },
      180: {
        amount: { value: 3000 },
        price: { value: 440000 },
        image: (width) => (
          <ExamPass60 width={width} borderRadius={isMobile ? 0 : 12} />
        ),
      },
      365: {
        amount: { value: 6000 },
        price: { value: 600000 },
        image: (width) => (
          <ExamPass360 width={width} borderRadius={isMobile ? 0 : 12} />
        ),
      },
    },
  };

  return isDeskTop ? (
    <>
      <DesktopPurchase
        studentDurationList={studentDurationList}
        teacherDurationList={teacherDurationList}
        userType={"선생님용"}
        duration={duration}
        setDuration={setDuration}
        purchaseObj={purchaseObj}
        onPurchase={onPurchase}
      />
      <CustomLoading
        ref={loadingRef}
        size={60}
        init={false}
        backgroundColor={"rgba(255,255,255,0.2)"}
        style={{ position: "fixed", top: 0, left: 0 }}
      />
    </>
  ) : (
    <>
      <TabletPurchase
        studentDurationList={studentDurationList}
        teacherDurationList={teacherDurationList}
        userType={"선생님용"}
        duration={duration}
        setDuration={setDuration}
        purchaseObj={purchaseObj}
        onPurchase={onPurchase}
        mobile={isMobile}
      />
      <CustomLoading
        ref={loadingRef}
        size={60}
        init={false}
        backgroundColor={"rgba(255,255,255,0.2)"}
        style={{ position: "fixed", top: 0, left: 0 }}
      />
    </>
  );
};

export default PurchasePage;

const studentDurationList = [
  { label: "3개월", value: 90 },
  { label: "6개월", value: 180 },
  { label: "1년", value: 365 },
];
const teacherDurationList = [
  { label: "3개월", value: 90 },
  { label: "6개월", value: 180 },
  { label: "1년", value: 365 },
];
