import React, { useEffect, useRef, useState } from "react";
import Arrow from "../../images/svg/Arrow";
import FindIcon from "../../images/svg/FindIcon";
import { Col, Pre3, Pre4, Row } from "../../style/GlobalStyled";
import theme from "../../style/theme";
import { useResponsive } from "../../utils/responsive";
import { CustomButton } from "../Atoms/CustomButton";
import CustomChip from "../Atoms/CustomChip";
import CustomDropdown from "../Atoms/CustomDropdown";
import regionData from "../../utils/regionGuData";
import Check from "../../images/svg/Check";
import SelectTriangle from "../../images/svg/SelectTriangle";
import CustomModal from "../Atoms/CustomModal";
import RegionListModal from "../Modals/RegionListModal";

const ListFilter = ({
  onClickSubmit,
  selectedFilter,
  setSelectedFilter,
  data,
  type,
  category,
}) => {
  const { HORIZONTAL_MARGIN, isDeskTop, isMobile } = useResponsive();
  const [isOpen, setIsOpen] = useState(true);

  const filterSchoolRegion = selectedFilter["학교"].school
    ? selectedFilter["학교"].school
    : type === "SCHOOL"
    ? "학교 전체"
    : selectedFilter["지역"].region === "전체"
    ? `지역 ${selectedFilter["지역"].region}`
    : selectedFilter["지역"].gu === "전체"
    ? `${selectedFilter["지역"].region} 구/군 ${selectedFilter["지역"].gu}`
    : `${selectedFilter["지역"].region} ${selectedFilter["지역"].gu}`;

  const applyFilter = (filterKey, filterString) => {
    const filterValue =
      selectedFilter[filterKey].length === 0
        ? ""
        : data.filter((v) => v.title === filterKey)[0].list.length - 1 ===
          selectedFilter[filterKey].length
        ? `${filterKey} 전체`
        : selectedFilter[filterKey].length === 1
        ? selectedFilter[filterKey] + ""
        : `${selectedFilter[filterKey].length}${filterString}`;

    return filterValue;
  };

  const filterSection = applyFilter("세부과목", "과목");
  const filterYear = applyFilter("연도", "개년");
  const filterSemester = applyFilter("학기", "개 학기");

  const [itemOpen, setItemOpen] = useState(Array(data?.length).fill(false));

  const handleItemClick = (index) => {
    setItemOpen((prev) =>
      prev.map((isOpen, idx) => (idx === index ? !isOpen : false))
    );
  };

  useEffect(() => {
    setItemOpen((prev) => prev.map(() => false));
  }, [type, category, isOpen]);

  const modalRef = useRef<ModalRefType>({});

  const onCustomModal = (name: string) => {
    document.body.style.overflow = "hidden";
    modalRef.current?.[name]?.show?.();
  };

  const offCustomModal = (name: string) => {
    document.body.style.overflow = "auto";
    modalRef.current?.[name]?.hide?.();
  };

  const Item = ({ idx, item }) => {
    const { type, list, title, unit, data, onClick, string } = item;
    const regionList = ["전체", ...regionData.map((v) => v.text)];
    const guList =
      selectedFilter?.["지역"]?.region === "전체"
        ? ["전체"]
        : [
            "전체",
            ...regionData.filter(
              (v) => v.text === selectedFilter?.["지역"]?.region
            )[0].list,
          ];
    const placeHolder = selectedFilter[title].length === 0;
    return (
      <Row
        style={{
          height: "auto",
          justifyContent: "flex-start",
          alignItems:
            type === "check" || type === "select"
              ? "flex-start"
              : type === "multiSelect" && isMobile
              ? "center"
              : type === "multiSelect" && !isMobile
              ? "flex-start"
              : "center",
          padding: isDeskTop ? "20px 24px" : "0 16px",
          marginBottom: isDeskTop ? 0 : 16,
          borderTop: isDeskTop
            ? idx === 0
              ? 0
              : `1px solid ${theme.Gray2}`
            : 0,
          zIndex: -idx,
        }}
      >
        <Row
          style={{
            width: 70,
            height: "auto",
            justifyContent: "flex-start",
          }}
        >
          <Pre4
            fontWeight="Medium"
            style={{
              lineHeight: type === "select" ? "32px" : "20px",
              marginBottom: isMobile && title === "지역" ? 58 : 0,
              userSelect: "none",
            }}
          >
            {title}
          </Pre4>
        </Row>
        {type === "multiSelect" && !isMobile && (
          <Row
            style={{
              height: "auto",
              flex: 1,
              flexWrap: "wrap",
              justifyContent: "flex-start",
              gap: "12px",
            }}
          >
            {list.map((j, jIndex) => {
              const isAll = list.length - 1 === selectedFilter[title].length;
              const isSelected = selectedFilter[title].includes(j);

              return (
                <CustomChip
                  type={"Tertiary"}
                  size={isDeskTop ? "M" : "S"}
                  title={j === "전체" ? j : j + unit}
                  key={`filterItem_select_${jIndex}`}
                  style={{
                    borderRadius: 8,
                  }}
                  isSelected={isAll || isSelected}
                  onClick={() => {
                    if (j === "전체") {
                      if (isAll) {
                        setSelectedFilter((prev) => {
                          const temp = { ...prev };
                          temp[title] = [];
                          return temp;
                        });
                      } else {
                        setSelectedFilter((prev) => {
                          const temp = { ...prev };
                          temp[title] = list.filter((v) => v !== "전체");
                          return temp;
                        });
                      }
                    } else if (isSelected) {
                      setSelectedFilter((prev) => {
                        const temp = { ...prev };
                        temp[title] = temp[title].filter((v) => v !== j);

                        return temp;
                      });
                    } else {
                      setSelectedFilter((prev) => {
                        const temp = { ...prev };
                        temp[title].push(j);

                        return temp;
                      });
                    }
                  }}
                  leftComponent={
                    j !== "전체" && (
                      <Row
                        style={{
                          width: "auto",
                          height: "auto",
                          marginRight: 8,
                        }}
                      >
                        <Check
                          size={10}
                          fill={isSelected ? theme.Blue11 : theme.Gray6}
                        />
                      </Row>
                    )
                  }
                />
              );
            })}
          </Row>
        )}
        {type === "multiSelect" && isMobile && (
          <Col
            style={{
              height: "auto",
              flex: 1,
              position: "relative",
              zIndex: 1,
            }}
          >
            <Row
              style={{
                border: `1px solid ${theme.Gray3}`,
                borderBottom: itemOpen[idx] ? 0 : `1px solid ${theme.Gray3}`,
                borderRadius: itemOpen[idx] ? "8px 8px 0 0" : 8,
                padding: "16px",
                height: 46,
                justifyContent: "space-between",
                gap: 8,
                cursor: "pointer",
              }}
              onClick={() => handleItemClick(idx)}
            >
              <Row
                style={{
                  justifyContent: "flex-start",
                  height: "auto",
                  flex: 1,
                  width: 0,
                }}
              >
                <Pre3
                  fontWeight="Regular"
                  style={{
                    fontSize: isDeskTop ? "16px" : "14px",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    display: "inline-block",
                  }}
                  color={placeHolder ? theme.Gray5 : theme.Gray10}
                >
                  {placeHolder
                    ? `${title}${
                        title === "세부과목" ? "을" : "를"
                      } 선택해주세요.`
                    : applyFilter(title, string)}
                </Pre3>
              </Row>

              <SelectTriangle dir={itemOpen[idx] ? "top" : "bottom"} />
            </Row>
            {itemOpen[idx] && (
              <Row
                style={{
                  height: "auto",
                  maxHeight: 234,
                  overflowY: "auto",
                  flex: 1,
                  flexWrap: "wrap",
                  justifyContent: "flex-start",
                  gap: 8,
                  border: `1px solid ${theme.Gray3}`,
                  borderTop: 0,
                  padding: "0 16px 16px 16px",
                  borderRadius: "0 0 8px 8px",
                  position: "absolute",
                  backgroundColor: theme.White,
                  top: 46,
                }}
              >
                {list.map((j, jIndex) => {
                  const isAll =
                    list.length - 1 === selectedFilter[title].length;
                  const isSelected = selectedFilter[title].includes(j);

                  return (
                    <CustomChip
                      type={"Tertiary"}
                      size={"S"}
                      title={j === "전체" ? j : j + unit}
                      key={`filterItem_select_${jIndex}`}
                      style={{
                        borderRadius: 8,
                      }}
                      isSelected={isAll || isSelected}
                      onClick={() => {
                        if (j === "전체") {
                          if (isAll) {
                            setSelectedFilter((prev) => {
                              const temp = { ...prev };
                              temp[title] = [];
                              return temp;
                            });
                          } else {
                            setSelectedFilter((prev) => {
                              const temp = { ...prev };
                              temp[title] = list.filter((v) => v !== "전체");
                              return temp;
                            });
                          }
                        } else if (isSelected) {
                          setSelectedFilter((prev) => {
                            const temp = { ...prev };
                            temp[title] = temp[title].filter((v) => v !== j);

                            return temp;
                          });
                        } else {
                          setSelectedFilter((prev) => {
                            const temp = { ...prev };
                            temp[title].push(j);

                            return temp;
                          });
                        }
                      }}
                      leftComponent={
                        j !== "전체" && (
                          <Row
                            style={{
                              width: "auto",
                              height: "auto",
                              marginRight: 8,
                            }}
                          >
                            <Check
                              size={10}
                              fill={isSelected ? theme.Blue11 : theme.Gray6}
                            />
                          </Row>
                        )
                      }
                    />
                  );
                })}
              </Row>
            )}
          </Col>
        )}
        {type === "SCHOOL" && (
          <Row
            style={{
              height: "auto",
              flex: 1,
              flexWrap: "wrap",
              justifyContent: "flex-start",
              gap: "12px",
              cursor: "pointer",
              userSelect: "none",
            }}
          >
            <Row
              style={{
                border: `1px solid ${theme.Gray3}`,
                borderRadius: 8,
                padding: "12px 16px",
                height: isMobile ? 46 : 48,
                justifyContent: "flex-start",
                gap: 8,
              }}
              onClick={onClick}
            >
              <FindIcon />
              <Pre3
                fontWeight="Regular"
                color={data.school === "" ? theme.Gray5 : theme.Gray10}
                style={{ fontSize: isDeskTop ? "16px" : "14px" }}
              >
                {data.school === "" ? "학교 이름을 검색하세요." : data.school}
              </Pre3>
            </Row>
          </Row>
        )}
        {type === "REGION" && !isMobile && (
          <Row
            style={{
              height: "auto",
              flex: 1,
              justifyContent: "flex-start",
              gap: "12px",
              cursor: "pointer",
              userSelect: "none",
            }}
          >
            <CustomDropdown
              list={regionList}
              state={selectedFilter["지역"].region}
              setState={(region) => {
                setSelectedFilter((prev) => {
                  return { ...prev, 지역: { region, gu: "전체" } };
                });
              }}
              containerStyle={{ maxHeight: isDeskTop ? "270px" : "180px" }}
              textStyle={{ fontSize: isDeskTop ? "16px" : "14px" }}
            />
            <CustomDropdown
              list={guList}
              state={selectedFilter["지역"].gu}
              setState={(region) => {
                setSelectedFilter((prev) => {
                  return {
                    ...prev,
                    지역: { region: prev["지역"].region, gu: region },
                  };
                });
              }}
              containerStyle={{ maxHeight: isDeskTop ? "270px" : "180px" }}
              textStyle={{ fontSize: isDeskTop ? "16px" : "14px" }}
            />
          </Row>
        )}
        {type === "REGION" && isMobile && (
          <Col
            style={{
              height: "auto",
              flex: 1,
              justifyContent: "flex-start",
              gap: "12px",
              userSelect: "none",
            }}
          >
            <Row
              style={{
                border: `1px solid ${theme.Gray3}`,
                borderRadius: 8,
                padding: "16px",
                height: 46,
                justifyContent: "space-between",
                gap: 8,
                cursor: "pointer",
              }}
              onClick={() => {
                modalRef.current["region"].setPropsData({
                  list: regionList,
                });
                onCustomModal("region");
              }}
            >
              <Pre3
                fontWeight="Regular"
                style={{ fontSize: isDeskTop ? "16px" : "14px" }}
              >
                {selectedFilter["지역"].region}
              </Pre3>
              <SelectTriangle />
            </Row>
            <Row
              style={{
                border: `1px solid ${theme.Gray3}`,
                borderRadius: 8,
                padding: "16px",
                height: 46,
                justifyContent: "space-between",
                gap: 8,
                cursor: "pointer",
              }}
              onClick={() => {
                modalRef.current["gu"].setPropsData({
                  list: guList,
                });
                onCustomModal("gu");
              }}
            >
              <Pre3
                fontWeight="Regular"
                style={{ fontSize: isDeskTop ? "16px" : "14px" }}
              >
                {selectedFilter["지역"].gu}
              </Pre3>
              <SelectTriangle />
            </Row>
          </Col>
        )}
      </Row>
    );
  };

  return (
    <Col
      style={{ height: "auto", marginBottom: !isDeskTop && !isOpen ? 24 : 0 }}
    >
      <Col
        style={{
          width: isDeskTop ? "100%" : `calc(100% - ${2 * HORIZONTAL_MARGIN}px)`,
          maxWidth: "714px",
          height: "auto",
          border: `1px solid ${theme.Gray2}`,
          borderRadius: 8,
          borderBottomLeftRadius: isDeskTop || !isOpen ? 8 : 0,
          borderBottomRightRadius: isDeskTop || !isOpen ? 8 : 0,
          borderBottomWidth: isDeskTop || !isOpen ? 1 : 0,
        }}
      >
        {!isDeskTop && (
          <Row
            style={{
              justifyContent: "space-between",
              padding: "16px 16px",
              cursor: "pointer",
              userSelect: "none",
            }}
            onClick={() => setIsOpen((prev) => !prev)}
          >
            <Row
              style={{ flex: 1, height: "auto", justifyContent: "flex-start" }}
            >
              <Pre4 color={theme.Black} style={{ flexShrink: 0 }}>
                필터
              </Pre4>
              {!isOpen && (
                <>
                  <Pre4 color={theme.Blue10} style={{ marginLeft: 4 }}>
                    ·
                  </Pre4>
                  <Pre4
                    style={{
                      marginLeft: 4,
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      display: "inline-block",
                    }}
                    color={theme.Blue10}
                    fontWeight={"Regular"}
                  >
                    {category === "강남3구기출"
                      ? `${filterSection ? `${filterSection}` : ""}${
                          filterYear
                            ? `${filterSection ? ", " : ""}${filterYear}`
                            : ""
                        }${
                          filterSemester
                            ? `${
                                filterYear || filterSection ? ", " : ""
                              }${filterSemester}`
                            : ""
                        }`
                      : `${filterSchoolRegion}${
                          filterSection ? `, ${filterSection}` : ""
                        }${
                          filterYear
                            ? `${
                                filterSchoolRegion || filterSection ? ", " : ""
                              }${filterYear}`
                            : ""
                        }${
                          filterSemester
                            ? `${
                                filterSchoolRegion ||
                                filterYear ||
                                filterSection
                                  ? ", "
                                  : ""
                              }${filterSemester}`
                            : ""
                        }`}
                  </Pre4>
                </>
              )}
            </Row>
            <Arrow rotate={isOpen ? false : true} />
          </Row>
        )}
        {isDeskTop || isOpen ? (
          data.map((v, index) => {
            return <Item item={v} idx={index} key={`listFilter_${index}`} />;
          })
        ) : (
          <></>
        )}
      </Col>
      {(isDeskTop || isOpen) && (
        <Row
          style={{
            height: "auto",
            width: isDeskTop
              ? "100%"
              : `calc(100% - ${2 * HORIZONTAL_MARGIN}px)`,
            maxWidth: "714px",
            margin: isDeskTop ? "24px 0" : "0 0 24px 0",
            justifyContent: "flex-end",
            zIndex: !isDeskTop && -1,
          }}
        >
          <CustomButton
            title="검색"
            onClick={onClickSubmit}
            btnType={"Primary"}
            size={isDeskTop ? "L" : "Full"}
            style={{
              padding: "18px 80px",
              borderTopLeftRadius: isDeskTop ? 12 : 0,
              borderTopRightRadius: isDeskTop ? 12 : 0,
            }}
          />
        </Row>
      )}
      {isMobile && (
        <CustomModal
          ref={modalRef}
          name={"region"}
          onBackdropPress={() => offCustomModal("region")}
        >
          <RegionListModal
            name={"region"}
            state={selectedFilter["지역"].region}
            setState={(region) => {
              setSelectedFilter((prev) => {
                return { ...prev, 지역: { region, gu: "전체" } };
              });
            }}
            offCustomModal={() => offCustomModal("region")}
          />
        </CustomModal>
      )}
      {isMobile && (
        <CustomModal
          ref={modalRef}
          name={"gu"}
          onBackdropPress={() => offCustomModal("gu")}
        >
          <RegionListModal
            name={"gu"}
            state={selectedFilter["지역"].gu}
            setState={(region) => {
              setSelectedFilter((prev) => {
                return {
                  ...prev,
                  지역: { region: prev["지역"].region, gu: region },
                };
              });
            }}
            offCustomModal={() => offCustomModal("gu")}
          />
        </CustomModal>
      )}
    </Col>
  );
};

export default ListFilter;
