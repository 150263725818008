import React, { ChangeEvent, useState } from "react";
import { WhiteSpace, Pre5 } from "../../style/GlobalStyled";
import theme from "../../style/theme";
import styled from "styled-components";

interface PropsType {
  valid: boolean;
  validMessage: string;
  value: string;
  placeHolder: string;
  name: string;
  disabled?: boolean;
  rightComponent?: React.ReactNode;
  pending: boolean;
  maxLength?: number;
  loading: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  style?: React.CSSProperties;
  mobile?: boolean;
}

const CustomInput = ({
  valid,
  validMessage,
  value,
  placeHolder,
  name,
  disabled,
  rightComponent,
  pending,
  maxLength,
  loading,
  onChange,
  style,
  mobile,
}: PropsType) => {
  const [isFocus, setIsFocus] = useState(false);

  return (
    <>
      <div
        style={{
          position: "relative",
          flexDirection: "row",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Input
          $isFocus={isFocus}
          $valid={valid}
          disabled={disabled || false}
          name={name}
          placeholder={placeHolder}
          onChange={onChange}
          value={value}
          onFocus={() => {
            setIsFocus(true);
          }}
          maxLength={maxLength}
          style={style}
          $mobile={mobile}
        />

        {!pending && rightComponent && (valid || loading) && (
          <div
            style={{
              position: "absolute",
              right: loading ? 30 : 15,
              top: loading ? 25 : 12,
            }}
          >
            {rightComponent}
          </div>
        )}
      </div>
      {!validMessage || pending ? (
        <></>
      ) : (
        <>
          <WhiteSpace height={8} />
          <Pre5
            fontWeight="Regular"
            color={valid ? theme.Blue10 : theme.BoldRed}
            style={{ marginLeft: 16 }}
          >
            {validMessage}
          </Pre5>
        </>
      )}
    </>
  );
};

export default CustomInput;

const Input = styled.input<{
  $isFocus: boolean;
  $valid: boolean;
  disabled: boolean;
  $mobile: boolean;
}>`
  width: 100%;
  border-radius: 8px;
  font-size: 16px;
  border: 1px solid
    ${(props) =>
      !props.$isFocus
        ? theme.Gray3
        : props.$valid
        ? theme.Gray3
        : theme.BoldRed};
  background-color: ${(props) => (props.disabled ? theme.Gray1 : theme.White)};
  padding: 16px;
  &::placeholder {
    font-size: ${(props) => (props.$mobile ? "14px" : "16px")};
    color: ${(props) => (props.disabled ? "transparent" : theme.Gray5)};
  }
`;
