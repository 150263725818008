import React, { Dispatch, SetStateAction, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
  categoryState,
  headerOpenState,
  subjectState,
} from "../../atoms/atoms";
import CheryLogo from "../../images/icon/CheryLogo";
import Hamburger from "../../images/icon/Hamburger";
import { Col, Pre2, Pre3, Pre4, Row } from "../../style/GlobalStyled";
import theme from "../../style/theme";
import { useResponsive } from "../../utils/responsive";
import CustomChip from "../Atoms/CustomChip";

type PropsType = {
  school: string;
  topMenu: { title: string; path: string; onClick: () => void }[];
  categoryList: {
    title: string;
    path: string;
    list: string[];
  }[];
  subjectList: { title: string; value: string }[];
  subject: string;
  setSubject: Dispatch<SetStateAction<string>>;
  mobile: boolean;
};

const TabletHeader = ({
  school,
  topMenu,
  categoryList,
  subjectList,
  subject,
  setSubject,
}: PropsType) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const pageName = pathname.split("/")[2];
  const [recoilHeaderOpen, setRecoilHeaderOpen] =
    useRecoilState(headerOpenState);
  const [category, setCategory] = useRecoilState(categoryState);
  const [recoilSubject, setRecoilSubject] = useRecoilState(subjectState);
  const { isMobile, HORIZONTAL_MARGIN } = useResponsive();

  const headerRef = useRef(null);
  useEffect(() => {
    const outSideClick = (e) => {
      if (
        recoilHeaderOpen &&
        headerRef.current &&
        !headerRef.current.contains(e.target)
      ) {
        setRecoilHeaderOpen(false);
      }
    };
    document.addEventListener("mousedown", outSideClick);
    document.body.style.overflow = recoilHeaderOpen ? "hidden" : "auto";
  }, [recoilHeaderOpen]);

  useEffect(() => {
    if (categoryList.filter((v) => v.path === pageName).length > 0) {
      setCategory(categoryList.filter((v) => v.path === pageName)[0].list[0]);
    }
  }, []);

  const isLogin =
    pathname === "/login" || pathname === "/login/created_account";

  if (isLogin) {
    return (
      <header
        style={{
          width: "100%",
          backgroundColor: theme.White,
          zIndex: 11,
          position: "sticky",
          top: 0,
        }}
      >
        <Row style={{ justifyContent: "flex-start", padding: "32px 40px 0" }}>
          <Row
            style={{
              width: "auto",
              height: "auto",
              cursor: "pointer",
              userSelect: "none",
              marginRight: 48,
            }}
            onClick={() => {
              navigate("/");
            }}
          >
            <CheryLogo width={101} height={24} />
          </Row>
        </Row>
      </header>
    );
  }

  return (
    <header
      style={{
        width: "100%",
        backgroundColor: theme.White,
        zIndex: 11,
        position: "sticky",
        top: 0,
      }}
      ref={headerRef}
    >
      <Row
        style={{
          height: "auto",
          justifyContent: "flex-start",
          cursor: "pointer",
          padding: `32px ${HORIZONTAL_MARGIN}px 0`,
        }}
      >
        <Row style={{ justifyContent: "flex-start" }}>
          <Row
            style={{
              width: "auto",
              height: "auto",
              cursor: "pointer",
              userSelect: "none",
              marginRight: 48,
            }}
            onClick={() => {
              navigate("/");
            }}
          >
            <CheryLogo width={101} height={24} />
          </Row>
          <Row
            style={{
              width: "auto",
              height: "auto",
            }}
          >
            <Pre2
              fontWeight="Medium"
              color={school === "middle" ? theme.Black : theme.Gray5}
              onClick={() => {
                setSubject("english");
                setCategory("우리학교기출");
                setRecoilSubject("english");
                navigate("/middle/SchoolPage");
              }}
              style={{ cursor: "pointer", fontSize: isMobile ? 16 : 20 }}
            >
              중등
            </Pre2>
            <Col
              style={{
                width: "1px",
                height: "20px",
                backgroundColor: `${theme.Gray5}`,
                margin: isMobile ? "0 10px" : "0 20px",
              }}
            />
            <Pre2
              fontWeight="Medium"
              color={school === "high" ? theme.Black : theme.Gray5}
              onClick={() => {
                setSubject("english");
                setCategory("우리학교기출");
                setRecoilSubject("english");
                navigate("/high/SchoolPage");
              }}
              style={{ cursor: "pointer", fontSize: isMobile ? 16 : 20 }}
            >
              고등
            </Pre2>
          </Row>
        </Row>
        <Col
          style={{
            width: "auto",
            height: "auto",
            cursor: "pointer",
          }}
          onClick={() => {
            setRecoilHeaderOpen((prev) => !prev);
          }}
        >
          <Hamburger color={recoilHeaderOpen ? theme.Blue12 : theme.Gray6} />
        </Col>
      </Row>
      <Row
        style={{
          width: "auto",
          height: "auto",
          gap: "8px",
          justifyContent: "flex-start",
          flexWrap: "wrap",
          margin: "20px 0 16px",
          padding: `0 ${HORIZONTAL_MARGIN}px`,
        }}
      >
        {subjectList.map((v, index) => {
          const selected = subject === v.value;
          return (
            <CustomChip
              key={`subject_${index}`}
              size={isMobile ? "S" : "M"}
              title={
                school === "high" && v.title === "역사" ? "한국사" : v.title
              }
              type="Tertiary"
              isSelected={selected}
              onClick={() => {
                setSubject(v.value);
                setRecoilHeaderOpen(true);
              }}
            />
          );
        })}
      </Row>
      <Col
        style={{
          padding: recoilHeaderOpen ? "16px 40px 20px" : "16px 40px",
          backgroundColor: theme.Blue1,
          borderTop: `1px solid ${theme.Blue3}`,
          borderBottom: `1px solid ${theme.Blue3}`,
        }}
      >
        <Row
          style={{
            height: "auto",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            gap: 32,
            flexWrap: "wrap",
            userSelect: "none",
          }}
        >
          {categoryList.map((v, index) => {
            const { list } = v;
            const bigCategorySelected = pageName === v.path;
            return (
              <Col
                style={{
                  width: "auto",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
                key={`categoryList_${index}`}
              >
                <Pre3
                  key={`desktop_big_${index}`}
                  fontWeight={"Bold"}
                  style={{
                    width: isMobile ? 72 : 90,
                    cursor: "pointer",
                    fontSize: isMobile ? 14 : 16,
                  }}
                  color={bigCategorySelected ? theme.Blue12 : theme.Black}
                  onClick={() => {
                    if (school === "middle") {
                      navigate(`middle/${v.path}`);
                      setCategory(list[0]);
                      setRecoilSubject(subject);
                      setRecoilHeaderOpen(false);
                    } else {
                      navigate(`high/${v.path}`);
                      setCategory(list[0]);
                      setRecoilSubject(subject);
                      setRecoilHeaderOpen(false);
                    }
                  }}
                >
                  {v.title}
                </Pre3>
                {recoilHeaderOpen && (
                  <Col
                    style={{
                      width: "auto",
                      height: "auto",
                      alignItems: "flex-start",
                      marginTop: 16,
                    }}
                  >
                    {list?.map((k, index) => {
                      const selected =
                        category === k && subject === recoilSubject;
                      return (
                        <Pre3
                          key={`desktop_small_${index}`}
                          color={selected ? theme.Blue12 : theme.Black}
                          onClick={() => {
                            if (school === "middle") {
                              navigate(`middle/${v.path}`);
                              setCategory(k);
                              setRecoilSubject(subject);
                              setRecoilHeaderOpen(false);
                            } else {
                              navigate(`high/${v.path}`);
                              setCategory(k);
                              setRecoilSubject(subject);
                              setRecoilHeaderOpen(false);
                            }
                          }}
                          fontWeight="Regular"
                          style={{
                            fontSize: isMobile ? 14 : 16,
                            marginBottom: isMobile ? 14 : 16,
                            cursor: "pointer",
                          }}
                        >
                          {k}
                        </Pre3>
                      );
                    })}
                  </Col>
                )}
              </Col>
            );
          })}
        </Row>
        {recoilHeaderOpen && (
          <Row
            style={{
              justifyContent: "flex-start",
              gap: "20px",
              marginTop: 4,
            }}
          >
            {topMenu.map((v, index) => {
              return (
                <Pre4
                  key={`tablet_top_menu_${index}`}
                  onClick={v.onClick}
                  fontWeight="Regular"
                  color={pathname === v.path ? theme.Blue12 : theme.Gray6}
                  style={{ cursor: "pointer" }}
                >
                  {v.title}
                </Pre4>
              );
            })}
          </Row>
        )}
      </Col>
    </header>
  );
};

export default TabletHeader;
