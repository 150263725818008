import { atom } from "recoil";
const subjectState = atom({
  key: "subjectStore",
  default: "english",
});

const categoryState = atom({
  key: "categoryStore",
  default: "우리학교기출",
});

const headerOpenState = atom({
  key: "headerOpenStore",
  default: false,
});

export { subjectState, categoryState, headerOpenState };
