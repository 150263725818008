const midEnglishData = {
  title: "세부과목",
  type: "select",
  list: [
    {
      text: "중학영어1",
      title: "세부과목",
      type: "select",
      list: [
        {
          text: "NE능률(김성곤)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 561,
            },
            {
              text: "2단원 본문",
              id: 562,
            },
            {
              text: "3단원 본문",
              id: 563,
            },
            {
              text: "4단원 본문",
              id: 564,
            },
            {
              text: "5단원 본문",
              id: 565,
            },
            {
              text: "6단원 본문",
              id: 566,
            },
            {
              text: "7단원 본문",
              id: 567,
            },
            {
              text: "8단원 본문",
              id: 568,
            },
          ],
        },
        {
          text: "NE능률(양현권)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 569,
            },
            {
              text: "2단원 본문",
              id: 570,
            },
            {
              text: "3단원 본문",
              id: 571,
            },
            {
              text: "4단원 본문",
              id: 572,
            },
            {
              text: "5단원 본문",
              id: 573,
            },
            {
              text: "6단원 본문",
              id: 574,
            },
            {
              text: "7단원 본문",
              id: 575,
            },
            {
              text: "8단원 본문",
              id: 576,
            },
          ],
        },
        {
          text: "YBM(박준언)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 577,
            },
            {
              text: "2단원 본문",
              id: 578,
            },
            {
              text: "3단원 본문",
              id: 579,
            },
            {
              text: "4단원 본문",
              id: 580,
            },
            {
              text: "5단원 본문",
              id: 581,
            },
            {
              text: "6단원 본문",
              id: 582,
            },
            {
              text: "7단원 본문",
              id: 583,
            },
            {
              text: "8단원 본문",
              id: 584,
            },
            {
              text: "S.L.1 본문",
              id: 585,
            },
            {
              text: "S.L.2 본문",
              id: 586,
            },
          ],
        },
        {
          text: "YBM(송미정)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 587,
            },
            {
              text: "2단원 본문",
              id: 588,
            },
            {
              text: "3단원 본문",
              id: 589,
            },
            {
              text: "4단원 본문",
              id: 590,
            },
            {
              text: "5단원 본문",
              id: 591,
            },
            {
              text: "6단원 본문",
              id: 592,
            },
            {
              text: "7단원 본문",
              id: 593,
            },
            {
              text: "8단원 본문",
              id: 594,
            },
            {
              text: "9단원 본문",
              id: 595,
            },
            {
              text: "S.L. 본문",
              id: 596,
            },
          ],
        },
        {
          text: "미래엔(최연희)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 597,
            },
            {
              text: "2단원 본문",
              id: 598,
            },
            {
              text: "3단원 본문",
              id: 599,
            },
            {
              text: "4단원 본문",
              id: 600,
            },
            {
              text: "5단원 본문",
              id: 601,
            },
            {
              text: "6단원 본문",
              id: 602,
            },
            {
              text: "7단원 본문",
              id: 603,
            },
            {
              text: "8단원 본문",
              id: 604,
            },
            {
              text: "S.L. 본문",
              id: 605,
            },
          ],
        },
        {
          text: "금성(최인철)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 606,
            },
            {
              text: "2단원 본문",
              id: 607,
            },
            {
              text: "3단원 본문",
              id: 608,
            },
            {
              text: "4단원 본문",
              id: 609,
            },
            {
              text: "5단원 본문",
              id: 610,
            },
            {
              text: "6단원 본문",
              id: 611,
            },
            {
              text: "7단원 본문",
              id: 612,
            },
            {
              text: "8단원 본문",
              id: 613,
            },
          ],
        },
        {
          text: "동아(윤정미)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 614,
            },
            {
              text: "2단원 본문",
              id: 615,
            },
            {
              text: "3단원 본문",
              id: 616,
            },
            {
              text: "4단원 본문",
              id: 617,
            },
            {
              text: "5단원 본문",
              id: 618,
            },
            {
              text: "6단원 본문",
              id: 619,
            },
            {
              text: "7단원 본문",
              id: 620,
            },
            {
              text: "8단원 본문",
              id: 621,
            },
            {
              text: "S.L. 본문",
              id: 622,
            },
          ],
        },
        {
          text: "동아(이병민)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 623,
            },
            {
              text: "2단원 본문",
              id: 624,
            },
            {
              text: "3단원 본문",
              id: 625,
            },
            {
              text: "4단원 본문",
              id: 626,
            },
            {
              text: "5단원 본문",
              id: 627,
            },
            {
              text: "6단원 본문",
              id: 628,
            },
            {
              text: "7단원 본문",
              id: 629,
            },
            {
              text: "8단원 본문",
              id: 630,
            },
            {
              text: "S.L.1 본문",
              id: 631,
            },
            {
              text: "S.L.2 본문",
              id: 632,
            },
          ],
        },
        {
          text: "비상(김진완)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 633,
            },
            {
              text: "2단원 본문",
              id: 634,
            },
            {
              text: "3단원 본문",
              id: 635,
            },
            {
              text: "4단원 본문",
              id: 636,
            },
            {
              text: "5단원 본문",
              id: 637,
            },
            {
              text: "6단원 본문",
              id: 638,
            },
            {
              text: "7단원 본문",
              id: 639,
            },
            {
              text: "8단원 본문",
              id: 640,
            },
            {
              text: "S.L.1 본문",
              id: 641,
            },
            {
              text: "S.L.2 본문",
              id: 642,
            },
          ],
        },
        {
          text: "지학사(민찬규)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 643,
            },
            {
              text: "2단원 본문",
              id: 644,
            },
            {
              text: "3단원 본문",
              id: 645,
            },
            {
              text: "4단원 본문",
              id: 646,
            },
            {
              text: "5단원 본문",
              id: 647,
            },
            {
              text: "6단원 본문",
              id: 648,
            },
            {
              text: "7단원 본문",
              id: 649,
            },
            {
              text: "S.L. 본문",
              id: 650,
            },
          ],
        },
        {
          text: "천재(이재영)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 651,
            },
            {
              text: "2단원 본문",
              id: 652,
            },
            {
              text: "2단원 R.F.",
              id: 653,
            },
            {
              text: "3단원 본문",
              id: 654,
            },
            {
              text: "4단원 본문",
              id: 655,
            },
            {
              text: "4단원 R.F.",
              id: 656,
            },
            {
              text: "5단원 본문",
              id: 657,
            },
            {
              text: "6단원 본문",
              id: 658,
            },
            {
              text: "6단원 R.F.",
              id: 659,
            },
            {
              text: "7단원 본문",
              id: 660,
            },
            {
              text: "8단원 본문",
              id: 661,
            },
            {
              text: "8단원 R.F.",
              id: 662,
            },
          ],
        },
        {
          text: "천재(정사열)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 663,
            },
            {
              text: "2단원 본문",
              id: 664,
            },
            {
              text: "3단원 본문",
              id: 665,
            },
            {
              text: "4단원 본문",
              id: 666,
            },
            {
              text: "5단원 본문",
              id: 667,
            },
            {
              text: "6단원 본문",
              id: 668,
            },
            {
              text: "7단원 본문",
              id: 669,
            },
            {
              text: "8단원 본문",
              id: 670,
            },
            {
              text: "S.L.1 본문",
              id: 671,
            },
            {
              text: "S.L.2 본문",
              id: 672,
            },
          ],
        },
      ],
    },
    {
      text: "중학영어2",
      title: "세부과목",
      type: "select",
      list: [
        {
          text: "NE능률(김성곤)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 673,
            },
            {
              text: "2단원 본문",
              id: 674,
            },
            {
              text: "3단원 본문",
              id: 675,
            },
            {
              text: "4단원 본문",
              id: 676,
            },
            {
              text: "5단원 본문",
              id: 677,
            },
            {
              text: "6단원 본문",
              id: 678,
            },
            {
              text: "7단원 본문",
              id: 679,
            },
            {
              text: "8단원 본문",
              id: 680,
            },
          ],
        },
        {
          text: "NE능률(양현권)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 681,
            },
            {
              text: "2단원 본문",
              id: 682,
            },
            {
              text: "3단원 본문",
              id: 683,
            },
            {
              text: "4단원 본문",
              id: 684,
            },
            {
              text: "5단원 본문",
              id: 685,
            },
            {
              text: "6단원 본문",
              id: 686,
            },
            {
              text: "7단원 본문",
              id: 687,
            },
            {
              text: "8단원 본문",
              id: 688,
            },
          ],
        },
        {
          text: "YBM(박준언)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 689,
            },
            {
              text: "2단원 본문",
              id: 690,
            },
            {
              text: "3단원 본문",
              id: 691,
            },
            {
              text: "4단원 본문",
              id: 692,
            },
            {
              text: "5단원 본문",
              id: 693,
            },
            {
              text: "6단원 본문",
              id: 694,
            },
            {
              text: "7단원 본문",
              id: 695,
            },
            {
              text: "8단원 본문",
              id: 696,
            },
            {
              text: "S.L.1 본문",
              id: 697,
            },
            {
              text: "S.L.2 본문",
              id: 698,
            },
          ],
        },
        {
          text: "YBM(송미정)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 699,
            },
            {
              text: "2단원 본문",
              id: 700,
            },
            {
              text: "3단원 본문",
              id: 701,
            },
            {
              text: "4단원 본문",
              id: 702,
            },
            {
              text: "5단원 본문",
              id: 703,
            },
            {
              text: "6단원 본문",
              id: 704,
            },
            {
              text: "7단원 본문",
              id: 705,
            },
            {
              text: "8단원 본문",
              id: 706,
            },
            {
              text: "9단원 본문",
              id: 707,
            },
            {
              text: "S.L. 본문",
              id: 708,
            },
          ],
        },
        {
          text: "미래엔(최연희)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 709,
            },
            {
              text: "2단원 본문",
              id: 710,
            },
            {
              text: "3단원 본문",
              id: 711,
            },
            {
              text: "4단원 본문",
              id: 712,
            },
            {
              text: "5단원 본문",
              id: 713,
            },
            {
              text: "6단원 본문",
              id: 714,
            },
            {
              text: "7단원 본문",
              id: 715,
            },
            {
              text: "8단원 본문",
              id: 716,
            },
          ],
        },
        {
          text: "금성(최인철)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 717,
            },
            {
              text: "2단원 본문",
              id: 718,
            },
            {
              text: "3단원 본문",
              id: 719,
            },
            {
              text: "4단원 본문",
              id: 720,
            },
            {
              text: "5단원 본문",
              id: 721,
            },
            {
              text: "6단원 본문",
              id: 722,
            },
            {
              text: "7단원 본문",
              id: 723,
            },
            {
              text: "8단원 본문",
              id: 724,
            },
          ],
        },
        {
          text: "동아(윤정미)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 725,
            },
            {
              text: "2단원 본문",
              id: 726,
            },
            {
              text: "3단원 본문",
              id: 727,
            },
            {
              text: "4단원 본문",
              id: 728,
            },
            {
              text: "5단원 본문",
              id: 729,
            },
            {
              text: "6단원 본문",
              id: 730,
            },
            {
              text: "7단원 본문",
              id: 731,
            },
            {
              text: "8단원 본문",
              id: 732,
            },
            {
              text: "S.L. 본문",
              id: 733,
            },
          ],
        },
        {
          text: "동아(이병민)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 734,
            },
            {
              text: "2단원 본문",
              id: 735,
            },
            {
              text: "3단원 본문",
              id: 736,
            },
            {
              text: "4단원 본문",
              id: 737,
            },
            {
              text: "5단원 본문",
              id: 738,
            },
            {
              text: "6단원 본문",
              id: 739,
            },
            {
              text: "7단원 본문",
              id: 740,
            },
            {
              text: "8단원 본문",
              id: 741,
            },
            {
              text: "S.L.1 본문",
              id: 742,
            },
            {
              text: "S.L.2 본문",
              id: 743,
            },
          ],
        },
        {
          text: "비상(김진완)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 744,
            },
            {
              text: "2단원 본문",
              id: 745,
            },
            {
              text: "3단원 본문",
              id: 746,
            },
            {
              text: "4단원 본문",
              id: 747,
            },
            {
              text: "5단원 본문",
              id: 748,
            },
            {
              text: "6단원 본문",
              id: 749,
            },
            {
              text: "7단원 본문",
              id: 750,
            },
            {
              text: "8단원 본문",
              id: 751,
            },
            {
              text: "S.L. 본문",
              id: 752,
            },
          ],
        },
        {
          text: "지학사(민찬규)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 753,
            },
            {
              text: "2단원 본문",
              id: 754,
            },
            {
              text: "3단원 본문",
              id: 755,
            },
            {
              text: "4단원 본문",
              id: 756,
            },
            {
              text: "5단원 본문",
              id: 757,
            },
            {
              text: "6단원 본문",
              id: 758,
            },
            {
              text: "7단원 본문",
              id: 759,
            },
            {
              text: "8단원 본문",
              id: 760,
            },
          ],
        },
        {
          text: "천재(이재영)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 761,
            },
            {
              text: "2단원 본문",
              id: 762,
            },
            {
              text: "3단원 본문",
              id: 763,
            },
            {
              text: "4단원 본문",
              id: 764,
            },
            {
              text: "5단원 본문",
              id: 765,
            },
            {
              text: "6단원 본문",
              id: 766,
            },
            {
              text: "7단원 본문",
              id: 767,
            },
            {
              text: "8단원 본문",
              id: 768,
            },
          ],
        },
        {
          text: "천재(정사열)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 769,
            },
            {
              text: "2단원 본문",
              id: 770,
            },
            {
              text: "3단원 본문",
              id: 771,
            },
            {
              text: "4단원 본문",
              id: 772,
            },
            {
              text: "5단원 본문",
              id: 773,
            },
            {
              text: "6단원 본문",
              id: 774,
            },
            {
              text: "7단원 본문",
              id: 775,
            },
            {
              text: "8단원 본문",
              id: 776,
            },
            {
              text: "S.L.1 본문",
              id: 777,
            },
            {
              text: "S.L.2 본문",
              id: 778,
            },
          ],
        },
      ],
    },
    {
      text: "중학영어3",
      title: "세부과목",
      type: "select",
      list: [
        {
          text: "NE능률(김성곤)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 779,
            },
            {
              text: "2단원 본문",
              id: 780,
            },
            {
              text: "3단원 본문",
              id: 781,
            },
            {
              text: "4단원 본문",
              id: 782,
            },
            {
              text: "5단원 본문",
              id: 783,
            },
            {
              text: "6단원 본문",
              id: 784,
            },
            {
              text: "7단원 본문",
              id: 785,
            },
            {
              text: "8단원 본문",
              id: 786,
            },
          ],
        },
        {
          text: "NE능률(양현권)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 787,
            },
            {
              text: "2단원 본문",
              id: 788,
            },
            {
              text: "3단원 본문",
              id: 789,
            },
            {
              text: "4단원 본문",
              id: 790,
            },
            {
              text: "5단원 본문",
              id: 791,
            },
            {
              text: "6단원 본문",
              id: 792,
            },
            {
              text: "7단원 본문",
              id: 793,
            },
            {
              text: "S.L. 본문",
              id: 794,
            },
          ],
        },
        {
          text: "YBM(박준언)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 795,
            },
            {
              text: "2단원 본문",
              id: 796,
            },
            {
              text: "3단원 본문",
              id: 797,
            },
            {
              text: "4단원 본문",
              id: 798,
            },
            {
              text: "5단원 본문",
              id: 799,
            },
            {
              text: "6단원 본문",
              id: 800,
            },
            {
              text: "7단원 본문",
              id: 801,
            },
            {
              text: "8단원 본문",
              id: 802,
            },
            {
              text: "9단원 본문",
              id: 803,
            },
            {
              text: "S.L. 본문",
              id: 804,
            },
          ],
        },
        {
          text: "YBM(송미정)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 805,
            },
            {
              text: "2단원 본문",
              id: 806,
            },
            {
              text: "3단원 본문",
              id: 807,
            },
            {
              text: "4단원 본문",
              id: 808,
            },
            {
              text: "5단원 본문",
              id: 809,
            },
            {
              text: "6단원 본문",
              id: 810,
            },
            {
              text: "7단원 본문",
              id: 811,
            },
            {
              text: "8단원 본문",
              id: 812,
            },
            {
              text: "S.L.1 본문",
              id: 813,
            },
            {
              text: "S.L.2 본문",
              id: 814,
            },
          ],
        },
        {
          text: "미래엔(최연희)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 815,
            },
            {
              text: "2단원 본문",
              id: 816,
            },
            {
              text: "3단원 본문",
              id: 817,
            },
            {
              text: "4단원 본문",
              id: 818,
            },
            {
              text: "5단원 본문",
              id: 819,
            },
            {
              text: "6단원 본문",
              id: 820,
            },
            {
              text: "7단원 본문",
              id: 821,
            },
          ],
        },
        {
          text: "금성(최인철)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 822,
            },
            {
              text: "2단원 본문",
              id: 823,
            },
            {
              text: "3단원 본문",
              id: 824,
            },
            {
              text: "4단원 본문",
              id: 825,
            },
            {
              text: "5단원 본문",
              id: 826,
            },
            {
              text: "6단원 본문",
              id: 827,
            },
            {
              text: "7단원 본문",
              id: 828,
            },
            {
              text: "8단원 본문",
              id: 829,
            },
          ],
        },
        {
          text: "동아(윤정미)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 830,
            },
            {
              text: "2단원 본문",
              id: 831,
            },
            {
              text: "3단원 본문",
              id: 832,
            },
            {
              text: "4단원 본문",
              id: 833,
            },
            {
              text: "5단원 본문",
              id: 834,
            },
            {
              text: "6단원 본문",
              id: 835,
            },
            {
              text: "7단원 본문",
              id: 836,
            },
            {
              text: "8단원 본문",
              id: 837,
            },
            {
              text: "S.L.1 본문",
              id: 838,
            },
          ],
        },
        {
          text: "동아(이병민)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 839,
            },
            {
              text: "2단원 본문",
              id: 840,
            },
            {
              text: "3단원 본문",
              id: 841,
            },
            {
              text: "4단원 본문",
              id: 842,
            },
            {
              text: "5단원 본문",
              id: 843,
            },
            {
              text: "6단원 본문",
              id: 844,
            },
            {
              text: "7단원 본문",
              id: 845,
            },
            {
              text: "8단원 본문",
              id: 846,
            },
            {
              text: "S.L. 본문",
              id: 847,
            },
          ],
        },
        {
          text: "비상(김진완)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 848,
            },
            {
              text: "2단원 본문",
              id: 849,
            },
            {
              text: "3단원 본문",
              id: 850,
            },
            {
              text: "4단원 본문",
              id: 851,
            },
            {
              text: "5단원 본문",
              id: 852,
            },
            {
              text: "6단원 본문",
              id: 853,
            },
            {
              text: "7단원 본문",
              id: 854,
            },
            {
              text: "8단원 본문",
              id: 855,
            },
          ],
        },
        {
          text: "지학사(민찬규)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 856,
            },
            {
              text: "2단원 본문",
              id: 857,
            },
            {
              text: "3단원 본문",
              id: 858,
            },
            {
              text: "4단원 본문",
              id: 859,
            },
            {
              text: "5단원 본문",
              id: 860,
            },
            {
              text: "6단원 본문",
              id: 861,
            },
            {
              text: "7단원 본문",
              id: 862,
            },
            {
              text: "8단원 본문",
              id: 863,
            },
          ],
        },
        {
          text: "천재(이재영)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 864,
            },
            {
              text: "2단원 본문",
              id: 865,
            },
            {
              text: "3단원 본문",
              id: 866,
            },
            {
              text: "4단원 본문",
              id: 867,
            },
            {
              text: "5단원 본문",
              id: 868,
            },
            {
              text: "6단원 본문",
              id: 869,
            },
            {
              text: "7단원 본문",
              id: 870,
            },
            {
              text: "8단원 본문",
              id: 871,
            },
          ],
        },
        {
          text: "천재(정사열)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 872,
            },
            {
              text: "2단원 본문",
              id: 873,
            },
            {
              text: "3단원 본문",
              id: 874,
            },
            {
              text: "4단원 본문",
              id: 875,
            },
            {
              text: "5단원 본문",
              id: 876,
            },
            {
              text: "6단원 본문",
              id: 877,
            },
            {
              text: "7단원 본문",
              id: 878,
            },
            {
              text: "8단원 본문",
              id: 879,
            },
            {
              text: "S.L.1 본문",
              id: 880,
            },
            {
              text: "S.L.2 본문",
              id: 881,
            },
          ],
        },
      ],
    },
  ],
};

export default midEnglishData;
