import styled from "styled-components";
import { Col, Pre1, Pre2, Pre3, Row } from "./style/GlobalStyled";
import { useResponsive } from "./utils/responsive";
import React from "react";
import theme from "./style/theme";

function Footer() {
  const { isMobile, isTablet } = useResponsive();
  const links = [
    { href: "/team", text: "회사소개" },
    {
      href: "https://gravel-marquess-6cf.notion.site/55dddaba70ca4c82aae58484806ddd91",
      text: "자주 묻는 질문",
    },
    {
      href: "https://gravel-marquess-6cf.notion.site/MISE-50c8652cdb12403784a22940c5290e95",
      text: "채용공고",
    },
    {
      href: "https://gravel-marquess-6cf.notion.site/2d14f08b197a477c8f8c8f3c16f1700b",
      text: "이용약관",
    },
    {
      href: "https://gravel-marquess-6cf.notion.site/4807311493734afb9c65217c6232c4cf",
      text: "개인정보처리방침",
    },
  ];
  return (
    <Col
      style={{
        backgroundColor: theme.Gray1,
        padding: isTablet
          ? "32px 40px"
          : isMobile
          ? "32px 20px"
          : "60px 0 100px",
        WebkitUserSelect: "none",
        MozUserSelect: "none",
        userSelect: "none",
      }}
    >
      <Col style={{ width: isMobile || isTablet ? "100%" : 960 }}>
        {isTablet || isMobile ? (
          <Col
            style={{
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            <Pre2>주식회사 마이스</Pre2>
            <Col
              style={{
                gap: 16,
                width: "auto",
                alignItems: "flex-start",
                marginTop: 16,
              }}
            >
              {links.map((link) => (
                <div key={link.href}>
                  {link.href.startsWith("/") ? (
                    <a
                      href={link.href}
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(link.href);
                      }}
                    >
                      <Row style={{ width: "auto" }}>
                        <Pre3 fontWeight="Medium" color="#5C5F66">
                          {link.text}
                        </Pre3>
                        {/* <Arrow20 stroke={theme.Gray3} width={20} height={20} /> */}
                      </Row>
                    </a>
                  ) : (
                    <a
                      href={link.href}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Row style={{ width: "auto" }}>
                        <Pre3 fontWeight="Medium" color="#5C5F66">
                          {link.text}
                        </Pre3>
                        {/* <Arrow20 stroke={theme.Gray3} width={20} height={20} /> */}
                      </Row>
                    </a>
                  )}
                </div>
              ))}
            </Col>
          </Col>
        ) : (
          <Row
            style={{
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            <Pre1>주식회사 마이스</Pre1>
            <Row style={{ gap: 60, width: "auto", marginLeft: 106 }}>
              {links.map((link) => (
                <div key={link.href}>
                  {link.href.startsWith("/") ? (
                    <a
                      href={link.href}
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(link.href);
                      }}
                    >
                      <Pre2 fontWeight="Medium" color="#5C5F66">
                        {link.text}
                      </Pre2>
                    </a>
                  ) : (
                    <a
                      href={link.href}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Pre2 fontWeight="Medium" color="#5C5F66">
                        {link.text}
                      </Pre2>
                    </a>
                  )}
                </div>
              ))}
            </Row>
          </Row>
        )}
        <Col
          style={{
            alignItems: "flex-start",
            marginTop: isMobile || isTablet ? 16 : 24,
          }}
        >
          <Pre3 fontWeight="Regular" style={{ lineHeight: "28px" }}>
            대표자 : 유동훈
            <br />
            유선번호 : 010-2959-4010
            <br />
            상호명: 주식회사 마이스
            <br />
            사업자 등록번호 : 262-88-01755
            <br />
            일반문의: contact@mise.team | 채용문의: career@mise.team
            <br />
            주소: 경기도 성남시 분당구 성남대로 343번길 10-6 3175호
            <br />
            통신판매업 신고번호: 제 2023-성남분당A-0106호
            <br />
            <br />
            ©MISE Co. Ltd. All Rights Reserved.
          </Pre3>
        </Col>
      </Col>
    </Col>
    // <FeatureLayout>
    //   <FooterBackWrapper isMobile={isMobile} isTablet={isTablet}>
    //     <FooterContentWrapper>
    //       <FooterTop isMobile={isMobile} isTablet={isTablet} />
    //     </FooterContentWrapper>
    //   </FooterBackWrapper>
    // </FeatureLayout>
  );
}

export default Footer;

const FooterBackWrapper = styled.footer<{
  isMobile: boolean;
  isTablet: boolean;
}>`
  width: 100vw;
  padding: ${({ isMobile, isTablet }) =>
    isMobile || isTablet ? "32px 0" : "60px 0px 100px"};

  background-color: #f7f7f7;

  & p {
    line-height: 2em;
  }
`;

const FooterContentWrapper = styled.div`
  width: 1080px;
  margin: 0 auto;
`;

const FooterBorder = styled.div`
  width: 100%;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
  margin: 15px 0;
`;
