// import { Platform } from "react-native";
import { createGlobalStyle } from "styled-components";
import styled from "styled-components";
import theme from "./theme";
import "../Fonts/Font.css";

export const GlobalStyles = createGlobalStyle`
    a {
        text-decoration: none;
        color: inherit;
    }
    * {
        box-sizing: border-box;
    }
    html, body, div, span, h1, h2, h3, h4, h5, h6, p, 
    a, dl, dt, dd, ol, ul, li, form, label, table{
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 10px;
        vertical-align: baseline;
    }
    body {
        line-height: 1;
        font-family: 'Noto Sans KR', sans-serif;
    }
    body::-webkit-scrollbar {
      display: none;
    }
    ol, ul {
        list-style: none;
    }
    button {
        border: 0;
        background: transparent;
        cursor: pointer;
    }
    header {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    :focus {
    outline: 0;
    }

    input {
    box-shadow: none
    }

    #invisible-scrollbar::-webkit-scrollbar {
      display: none;
    }
`;

const parser = (value: string | number) => {
  if (typeof value === "string") {
    if (value.substring(value.length - 2, value.length) === "px") {
      return value;
    } else if (value.substring(value.length - 1, value.length) === "%") {
      return value;
    } else {
      return value + "px";
    }
  } else {
    return value + "px";
  }
};

const fontWeight = {
  Light: "100",
  Regular: "400",
  Medium: "500",
  Bold: "700",
};

const fontFamily = {
  Light: "NotoSansKR-Thin",
  Regular: "NotoSansKR-Regular",
  Medium: "NotoSansKR-Medium",
  Bold: "NotoSansKR-Bold",
};

const pretendardFont = {
  Light: "Pretendard-Thin",
  Regular: "Pretendard-Regular",
  Medium: "Pretendard-Medium",
  Bold: "Pretendard-Bold",
};

export const Col = styled.div<{
  width?: number;
  height?: number;
  justifyContent?: string;
  alignItems?: string;
}>`
  display: flex;
  z-index: 0;
  flex-direction: column;
  width: ${(props) => (props.width ? parser(props.width) : "100%")};
  height: ${(props) => (props.height ? parser(props.height) : "100%")};
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "center"};
  align-items: ${(props) => (props.alignItems ? props.alignItems : "center")};
  display: inline-flex;
`;

export const Row = styled.div<{
  width?: number;
  height?: number;
  justifyContent?: string;
  alignItems?: string;
}>`
  display: flex;
  z-index: 0;
  flex-direction: row;
  width: ${(props) => (props.width ? parser(props.width) : "100%")};
  height: ${(props) => (props.height ? parser(props.height) : "100%")};
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "center"};
  align-items: ${(props) => (props.alignItems ? props.alignItems : "center")};
  display: inline-flex;
`;

type TFont = "Light" | "Regular" | "Medium" | "Bold";
/** 24px */
export const Pre1 = styled.p<{
  fontWeight?: TFont;
  color?: string;
  fontSize?: string;
}>`
  font-size: ${(props) => (props.fontSize ? props.fontSize : "24px")};

  font-weight: ${(props) =>
    props.fontWeight ? fontWeight[props.fontWeight] : "700"};
  font-family: ${(props) =>
    props.fontWeight ? pretendardFont[props.fontWeight] : "Pretendard-Bold"};
  include-font-padding: false;
  color: ${(props) => (props.color ? props.color : theme.Gray10)};
  display: inline-flex;
`;
/** 19px */
export const Pre2 = styled.p<{ fontWeight?: TFont; color?: string }>`
  font-size: 20px;
  font-weight: ${(props) =>
    props.fontWeight ? fontWeight[props.fontWeight] : "700"};
  font-family: ${(props) =>
    props.fontWeight ? pretendardFont[props.fontWeight] : "Pretendard-Bold"};
  include-font-padding: false;
  color: ${(props) => (props.color ? props.color : theme.Gray10)};
  display: inline-flex;
`;
/** 16px */
export const Pre3 = styled.p<{ fontWeight?: TFont; color?: string }>`
  font-size: 16px;
  font-weight: ${(props) =>
    props.fontWeight ? fontWeight[props.fontWeight] : "700"};
  font-family: ${(props) =>
    props.fontWeight ? pretendardFont[props.fontWeight] : "Pretendard-Bold"};
  include-font-padding: false;
  color: ${(props) => (props.color ? props.color : theme.Gray10)};
  display: inline-flex;
`;
/** 14px */
export const Pre4 = styled.p<{ fontWeight?: TFont; color?: string }>`
  font-size: 14px;
  font-weight: ${(props) =>
    props.fontWeight ? fontWeight[props.fontWeight] : "700"};
  font-family: ${(props) =>
    props.fontWeight ? pretendardFont[props.fontWeight] : "Pretendard-Bold"};
  color: ${(props) => (props.color ? props.color : theme.Gray10)};
  display: inline-flex;

  include-font-padding: false;
`;
/** 12px */
export const Pre5 = styled.p<{ fontWeight?: TFont; color?: string }>`
  font-size: 12px;
  font-weight: ${(props) =>
    props.fontWeight ? fontWeight[props.fontWeight] : "700"};
  font-family: ${(props) =>
    props.fontWeight ? pretendardFont[props.fontWeight] : "Pretendard-Bold"};
  color: ${(props) => (props.color ? props.color : theme.Gray10)};
  display: inline-flex;

  include-font-padding: false;
`;
/** 10px */
export const Pre6 = styled.p<{ fontWeight?: TFont; color?: string }>`
  font-size: 10px;
  font-weight: ${(props) =>
    props.fontWeight ? fontWeight[props.fontWeight] : "700"};
  font-family: ${(props) =>
    props.fontWeight ? pretendardFont[props.fontWeight] : "Pretendard-Bold"};
  color: ${(props) => (props.color ? props.color : theme.Gray10)};
  display: inline-flex;

  include-font-padding: false;
`;

export const Yet1 = styled.p<{ fontWeight?: TFont; color?: string }>`
  font-size: 24px;
  font-weight: ${(props) => props.fontWeight && fontWeight[props.fontWeight]};
  font-family: "NanumBarunGothic-YetHangul";
  color: ${(props) => (props.color ? props.color : theme.Gray10)};
  include-font-padding: false;
`;

/** 19px */
export const Yet2 = styled.p<{ fontWeight?: TFont; color?: string }>`
  font-size: 19px;
  font-weight: ${(props) => props.fontWeight && fontWeight[props.fontWeight]};
  font-family: "NanumBarunGothic-YetHangul";

  color: ${(props) => (props.color ? props.color : theme.Gray10)};
  include-font-padding: false;
`;

/** 16px */
export const Yet3 = styled.p<{ fontWeight?: TFont; color?: string }>`
  font-size: 16px;
  font-weight: ${(props) => props.fontWeight && fontWeight[props.fontWeight]};
  font-family: "NanumBarunGothic-YetHangul";

  color: ${(props) => (props.color ? props.color : theme.Gray10)};
  include-font-padding: false;
`;

/** 14px */
export const Yet4 = styled.p<{ fontWeight?: TFont; color?: string }>`
  font-size: 14px;
  font-weight: ${(props) => props.fontWeight && fontWeight[props.fontWeight]};
  font-family: "NanumBarunGothic-YetHangul";
  color: ${(props) => (props.color ? props.color : theme.Gray10)};
  include-font-padding: false;
`;

/** 12px */
export const Yet5 = styled.p<{ fontWeight?: TFont; color?: string }>`
  font-size: 12px;
  font-weight: ${(props) => props.fontWeight && fontWeight[props.fontWeight]};
  font-family: "NanumBarunGothic-YetHangul";

  color: ${(props) => (props.color ? props.color : theme.Gray10)};
  include-font-padding: false;
`;

/** 10px */
export const Yet6 = styled.p<{ fontWeight?: TFont; color?: string }>`
  font-size: 10px;
  font-weight: ${(props) => props.fontWeight && fontWeight[props.fontWeight]};
  font-family: "NanumBarunGothic-YetHangul";

  color: ${(props) => (props.color ? props.color : theme.Gray10)};
  include-font-padding: false;
`;

export const WhiteSpace = styled.div<{
  height?: string | number;
  width?: string | number;
}>`
  height: ${(props) => (props.height ? parser(props.height) : "10px")};
  width: ${(props) => (props.width ? parser(props.width) : "100%")};
`;
// };

export const a = "12";
