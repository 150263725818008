import axios from "axios";
import {
  ICheryExamAPI,
  IConsulting,
  IList,
  ILogin,
  IPurchase,
} from "./apiTypes";

type TUseAPI = () => ICheryExamAPI[];

const useCheryExamAPI: TUseAPI = () => {
  // const [cookies, setCookies] = useCookies();

  const WEB_API = axios.create({
    baseURL: "https://ewm4y7sw8c.execute-api.ap-northeast-2.amazonaws.com/api",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  });

  const login: ILogin = {
    getWebAuthToken: (params) => WEB_API.post(`/login/auth`, params.body),
    getNaverToken: (params) => WEB_API.get(`/login/naver?code=${params.code}`),
    getNaverUserInfo: (params) =>
      WEB_API.get(`/login/naver/user?access_token=${params.access_token}`),
    setWebInfos: (params) => WEB_API.put(`/login/auth`, params.body),
    clearCheryExamWebToken: (params) =>
      WEB_API.delete(`/login/auth`, { data: params.body }),
  };

  const list: IList = {
    getCheryExamList: (params) => WEB_API.post(`/list`, params.body),
    getZocboList_v2: (params) => WEB_API.post(`/list/zocbo/v2`, params.body),
  };

  const purchase: IPurchase = {
    getPdfLink: (params) =>
      WEB_API.get(
        `/purchase/pdf?token=${params.token}&length=${params.length}`
      ),
    makeLinkForDirectPurchaseExam: (params) =>
      WEB_API.post("/purchase/link", params.body),
    getMyPageInfo: (params) =>
      WEB_API.get(`/purchase/info?token=${params.token}`),
    insertUnlockContent: (params) => WEB_API.post(`/purchase/pdf`, params.body),
  };

  const consulting: IConsulting = {
    makeCheryExamConsultingRequest: (params) =>
      WEB_API.post("/consulting", params.body),
  };

  const returnValue = [
    {
      WEB_API,
      login,
      list,
      purchase,
      consulting,
    },
  ];

  return returnValue;
};

export default useCheryExamAPI;
