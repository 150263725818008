import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import { categoryState, subjectState } from "../atoms/atoms";
import { CustomButton } from "../components/Atoms/CustomButton";
import CustomModal from "../components/Atoms/CustomModal";
import FindSchoolModal from "../components/Modals/FindSchoolModal";
import AcademyForm from "../components/Molecules/AcademyForm";
import FilterCategory from "../components/Molecules/FIlterCategory";
import ListFilter from "../components/Molecules/ListFilter";
import Overlay from "../components/Molecules/Overlay";
import FilterResultList from "../components/Organisms/FilterResultList";
import Pagenation from "../components/Organisms/Pagenation";
import { useZocboList } from "../query/useCheryExamQuery";
import { Col, Pre1, Row } from "../style/GlobalStyled";
import theme from "../style/theme";
import { useResponsive } from "../utils/responsive";
import { subjectData } from "../utils/subjectData";

const YEAR = [2023, 2022, 2021, 2020];
const SEMESTER = [
  "1학기 중간고사",
  "1학기 기말고사",
  "2학기 중간고사",
  "2학기 기말고사",
];

const CATEGORY = {
  english: ["우리학교기출", "강남3구기출"],
  korean: ["우리학교기출", "강남3구기출"],
  math: ["우리학교기출", "강남3구기출"],
  society: ["우리학교기출", "강남3구기출"],
  science: ["우리학교기출", "강남3구기출"],
  history: ["우리학교기출", "강남3구기출"],
};

const SchoolPage = () => {
  const { HORIZONTAL_MARGIN, isDeskTop, isMobile, isTablet } = useResponsive();
  const [subject] = useRecoilState(subjectState);
  const location = useLocation();

  const school = location.pathname.split("/")[1];
  const modalRef = useRef<ModalRefType>({});

  const [category, setCategory] = useRecoilState(categoryState);
  const [type, setType] = useState("SCHOOL");
  const [isOpen, setIsOpen] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const currentData = subjectData[subject][school];

  const [selectedFilter, setSelectedFilter] = useState({
    연도: [],
    학기: [],
    세부과목: [],
    지역: { region: "전체", gu: "전체" },
    학교: { school: "", code: "" },
  });
  const [queryFilter, setQueryFilter] = useState({
    subject,
    type,
    school_code: selectedFilter["학교"].code,
    region: selectedFilter["지역"].region,
    gu: selectedFilter["지역"].gu,
    category,
    sectionList: [...currentData.list.map((v) => v.text)],
    year: [...YEAR],
    semester: [...SEMESTER],
  });
  const { data } = useZocboList({ ...queryFilter, pageIndex });

  const getSubjectConceptCompletionList = async (initSelectedFilter?: any) => {
    try {
      const paramsSelectedFilter = Boolean(initSelectedFilter)
        ? initSelectedFilter
        : selectedFilter;

      setPageIndex(0);
      setQueryFilter({
        subject,
        type,
        school_code: paramsSelectedFilter["학교"].code,
        region: paramsSelectedFilter["지역"].region,
        gu: paramsSelectedFilter["지역"].gu,
        category,
        sectionList:
          paramsSelectedFilter["세부과목"].length > 0
            ? paramsSelectedFilter["세부과목"]
            : [...currentData.list.map((v) => v.text)],
        year:
          paramsSelectedFilter["연도"].length > 0
            ? paramsSelectedFilter["연도"]
            : [...YEAR],
        semester:
          paramsSelectedFilter["학기"].length > 0
            ? paramsSelectedFilter["학기"]
            : [...SEMESTER],
      });
    } catch (err) {
      console.log(err, "getZocboList");
    }
  };

  useEffect(() => {
    const init = {
      연도: [],
      학기: [],
      세부과목: [],
      지역: { region: "전체", gu: "전체" },
      학교: { school: "", code: "" },
    };
    setSelectedFilter(init);
    getSubjectConceptCompletionList(init);
  }, [subject, category, type]);

  const onModal = (name: string) => {
    document.body.style.overflow = "hidden";
    modalRef.current?.[name]?.show?.();
  };

  const offModal = (name: string) => {
    document.body.style.overflow = "auto";
    modalRef.current?.[name]?.hide?.();
  };

  return (
    <Row
      style={{
        height: "auto",
        width: isDeskTop ? 960 : "100%",
        alignItems: "flex-start",
        flexWrap: "wrap",
      }}
    >
      {isDeskTop ? (
        <Col
          style={{
            height: "auto",
            width: 222,
            marginRight: 24,
          }}
        >
          <FilterCategory
            list={CATEGORY[subject]}
            category={category}
            setCategory={setCategory}
          />
          <AcademyForm />
        </Col>
      ) : (
        <FilterCategory
          list={CATEGORY[subject]}
          category={category}
          setCategory={setCategory}
        />
      )}
      <Col
        style={{
          height: "auto",
          justifyContent: "flex-start",
          width: "100%",
          maxWidth: isDeskTop ? 714 : undefined,
        }}
      >
        {isDeskTop && (
          <Row
            style={{
              height: "auto",
              justifyContent: "flex-start",
              marginBottom: 24,
            }}
          >
            <Pre1 color={theme.Black}>{category}</Pre1>
          </Row>
        )}
        {category === "우리학교기출" && (
          <Row
            style={{
              height: "auto",
              marginBottom: 16,
              gap: 12,
              width: isDeskTop
                ? "100%"
                : `calc(100% - ${2 * HORIZONTAL_MARGIN}px)`,
              maxWidth: "714px",
            }}
          >
            <CustomButton
              title={"학교로 찾기"}
              size={"Full"}
              btnType={type === "SCHOOL" ? "Black" : "Gray"}
              onClick={() => {
                setType("SCHOOL");
              }}
            />
            <CustomButton
              title={"지역으로 찾기"}
              size={"Full"}
              btnType={type === "REGION" ? "Black" : "Gray"}
              onClick={() => {
                setType("REGION");
              }}
            />
          </Row>
        )}
        <ListFilter
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
          data={
            category === "강남3구기출"
              ? [
                  {
                    title: "세부과목",
                    list: ["전체", ...currentData.list.map((v) => v.text)],
                    unit: "",
                    type: "multiSelect",
                    string: "과목",
                  },
                  {
                    title: "연도",
                    list: ["전체", ...YEAR],
                    unit: "년",
                    type: "multiSelect",
                    string: "개년",
                  },
                  {
                    title: "학기",
                    list: ["전체", ...SEMESTER],
                    unit: "",
                    type: "multiSelect",
                    string: "개 학기",
                  },
                ]
              : [
                  {
                    title: type === "SCHOOL" ? "학교" : "지역",
                    data: selectedFilter[type === "SCHOOL" ? "학교" : "지역"],
                    unit: "",
                    type: type,
                    onClick: () => {
                      if (type === "SCHOOL") {
                        onModal("school");
                      }
                    },
                  },
                  {
                    title: "세부과목",
                    list: ["전체", ...currentData.list.map((v) => v.text)],
                    unit: "",
                    type: "multiSelect",
                    string: "과목",
                  },
                  {
                    title: "연도",
                    list: ["전체", ...YEAR],
                    unit: "년",
                    type: "multiSelect",
                    string: "개년",
                  },
                  {
                    title: "학기",
                    list: ["전체", ...SEMESTER],
                    unit: "",
                    type: "multiSelect",
                    string: "개 학기",
                  },
                ]
          }
          onClickSubmit={getSubjectConceptCompletionList}
          type={type}
          category={category}
        />

        <ResultComponent
          info={data}
          mobile={isMobile}
          setPageIndex={setPageIndex}
        />
      </Col>
      <CustomModal
        ref={modalRef}
        name={"school"}
        onBackdropPress={() => offModal("school")}
      >
        <FindSchoolModal
          setSchool={({ school, code }) => {
            setSelectedFilter((prev) => {
              return { ...prev, 학교: { school, code } };
            });
            offModal("school");
          }}
          offModal={() => offModal("school")}
        />
      </CustomModal>
      {(isTablet || isMobile) && (
        <Col
          style={{
            position: "fixed",
            zIndex: 1,
            height: "auto",
            bottom: isMobile ? (isOpen ? 0 : 36) : 40,
            padding: isMobile ? (isOpen ? 0 : "0px 20px") : "0px 40px",
            alignItems: "flex-end",
          }}
        >
          <AcademyForm setIsOpen={setIsOpen} isOpen={isOpen} />
        </Col>
      )}
      <Overlay />
    </Row>
  );
};

export default SchoolPage;

const ResultComponent = ({ info, mobile, setPageIndex }) => {
  const [totalCount, setTotalCount] = useState(0);
  useEffect(() => {
    if (
      totalCount !== info.idList.length &&
      !info?.isPlaceholder &&
      info.idList.length !== 0
    ) {
      setTotalCount(info.idList.length);
    } else if (!info?.isPlaceholder && info.idList.length === 0) {
      setTotalCount(0);
    }
  }, [info.idList.length, info.isPlaceholder]);
  return (
    <Pagenation
      list={info.list}
      containerStyle={{ marginBottom: 80, zIndex: mobile && -1 }}
      totalCount={totalCount}
      setPageIndex={setPageIndex}
    >
      <FilterResultList
        section={info.title}
        headerTitleList={["정보", "다운로드"]}
        headerFlexList={[0.8, 0.2]}
        idList={info.idList}
      />
    </Pagenation>
  );
};
