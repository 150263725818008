const midSocietyClassHighData = {
  title: "세부과목",
  type: "select",
  list: [
    {
      text: "중학사회1",
      title: "대단원",
      type: "check",
      list: [
        { text: "내가 사는 세계", title: "대단원", id: "내가 사는 세계" },
        {
          text: "우리와 다른 기후, 다른 생활",
          title: "대단원",
          id: "우리와 다른 기후, 다른 생활",
        },
        {
          text: "자연으로 떠나는 여행",
          title: "대단원",
          id: "자연으로 떠나는 여행",
        },
        {
          text: "다양한 세계, 다양한 문화",
          title: "대단원",
          id: "다양한 세계, 다양한 문화",
        },
        {
          text: "지구 곳곳에서 일어나는 자연재해",
          title: "대단원",
          id: "지구 곳곳에서 일어나는 자연재해",
        },
        {
          text: "자원을 둘러싼 결쟁과 갈등",
          title: "대단원",
          id: "자원을 둘러싼 결쟁과 갈등",
        },
        { text: "개인과 사회생활", title: "대단원", id: "개인과 사회생활" },
        { text: "문화의 이해", title: "대단원", id: "문화의 이해" },
        {
          text: "정치 생활과 민주주의",
          title: "대단원",
          id: "정치 생활과 민주주의",
        },
        {
          text: "정치 과정과 시민참여",
          title: "대단원",
          id: "정치 과정과 시민참여",
        },
        { text: "일상생활과 법", title: "대단원", id: "일상생활과 법" },
        {
          text: "사회 변동과 사회 문제",
          title: "대단원",
          id: "사회 변동과 사회 문제",
        },
      ],
    },
    {
      text: "중학사회2",
      title: "대단원",
      type: "check",
      list: [
        { text: "인권과 헌법", title: "대단원", id: "인권과 헌법" },
        { text: "헌법과 국가 기관", title: "대단원", id: "헌법과 국가 기관" },
        { text: "경제생활과 선택", title: "대단원", id: "경제생활과 선택" },
        { text: "시장 경제와 가격", title: "대단원", id: "시장 경제와 가격" },
        {
          text: "국민 경제와 국제 거래",
          title: "대단원",
          id: "국민 경제와 국제 거래",
        },
        {
          text: "국제 사회와 국제 정치",
          title: "대단원",
          id: "국제 사회와 국제 정치",
        },
        {
          text: "인구 변화와 인구 문제",
          title: "대단원",
          id: "인구 변화와 인구 문제",
        },
        {
          text: "사람이 만든 삶터, 도시",
          title: "대단원",
          id: "사람이 만든 삶터, 도시",
        },
        {
          text: "글로벌 경제 활동과 지역 변화",
          title: "대단원",
          id: "글로벌 경제 활동과 지역 변화",
        },
        {
          text: "환경 문제와 지속 가능한 환경",
          title: "대단원",
          id: "환경 문제와 지속 가능한 환경",
        },
        {
          text: "세계 속의 우리나라",
          title: "대단원",
          id: "세계 속의 우리나라",
        },
        { text: "더불어 사는 세계", title: "대단원", id: "더불어 사는 세계" },
      ],
    },
  ],
};
export default midSocietyClassHighData;
