import React from "react";
import { styled } from "styled-components";
import BasicCheckbox from "../../images/svg/BasicCheckbox.svg";
import CheckedCheckbox from "../../images/svg/CheckedCheckbox.svg";

interface PropsType {
  width: string | number;
  height: string | number;
  id: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
}

const CheckboxInput = styled.input`
  appearance: none;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  margin: 0;
  background: url(${BasicCheckbox}) no-repeat center / 100%;
  cursor: pointer;
  &:checked {
    background-image: url(${CheckedCheckbox});
  }
`;

export const Checkbox = ({
  width,
  height,
  id,
  onChange,
  checked,
}: PropsType) => {
  return (
    <CheckboxInput
      type="checkbox"
      width={width}
      height={width}
      id={id}
      onChange={onChange}
      checked={checked}
    />
  );
};
