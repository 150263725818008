type RGB = `rgb(${number}, ${number}, ${number})`;
type RGBA = `rgba(${number}, ${number}, ${number}, ${number})`;
type HEX = `#${string}`;

export type Color = RGB | RGBA | HEX;

interface themeType {
  Blue1: Color;
  Blue2: Color;
  Blue3: Color;
  Blue4: Color;
  Blue5: Color;
  Blue6: Color;
  Blue7: Color;
  Blue8: Color;
  Blue9: Color;
  Blue10: Color;
  Blue11: Color;
  Blue12: Color;

  Gray1: Color;
  Gray2: Color;
  Gray3: Color;
  Gray4: Color;
  Gray5: Color;
  Gray6: Color;
  Gray7: Color;
  Gray8: Color;
  Gray9: Color;
  Gray10: Color;

  Pink1: Color;
  Pink2: Color;
  Pink3: Color;
  Pink4: Color;
  Pink5: Color;
  Pink6: Color;
  Pink7: Color;
  Pink8: Color;
  Pink9: Color;

  DarkEng: Color;
  BoldEng: Color;
  MediumEng: Color;
  LightEng: Color;
  BackgroundEng: Color;

  DarkKor: Color;
  BoldKor: Color;
  MediumKor: Color;
  LightKor: Color;
  BackgroundKor: Color;

  DarkMath: Color;
  BoldMath: Color;
  MediumMath: Color;
  LightMath: Color;
  BackgroundMath: Color;

  BoldSociety: Color;
  DarkSociety: Color;
  MediumSociety: Color;
  LightSociety: Color;
  BackgroundSociety: Color;

  DarkScience: Color;
  BoldScience: Color;
  MediumScience: Color;
  LightScience: Color;
  BackgroundScience: Color;

  DarkHistory: Color;
  BoldHistory: Color;
  MediumHistory: Color;
  LightHistory: Color;
  BackgroundHistory: Color;

  DarkRed: Color;
  BoldRed: Color;
  MediumRed: Color;
  LightRed: Color;
  BackgroundRed: Color;

  DarkNavy: Color;
  BoldNavy: Color;
  MediumNavy: Color;
  LightNavy: Color;
  BackgroundNavy: Color;

  White: Color;
  Black: Color;

  MagentaLogo: Color;
  LightLogo: Color;
  Yellow: Color;
}

const theme: themeType = {
  Yellow: "#FFD833",
  MagentaLogo: "#FF77A8",
  LightLogo: "#FFF196",

  // After Renewal

  White: "#FFFFFF",
  Black: "#000",

  // English
  DarkEng: "#D65106",
  BoldEng: "#FF823D",
  MediumEng: "#FCA574",
  LightEng: "#FAD2BC",
  BackgroundEng: "#FCF0E9",

  // Korean
  DarkKor: "#AE7F04",
  BoldKor: "#F9D30B",
  MediumKor: "#FCE154",
  LightKor: "#FCED9D",
  BackgroundKor: "#FAF6DF",

  // Math
  DarkMath: "#079674",
  BoldMath: "#3CD092",
  MediumMath: "#7AE5B8",
  LightMath: "#BBF0DA",
  BackgroundMath: "#E6FAF1",

  // Society
  DarkSociety: "#8530F1",
  BoldSociety: "#B178F9",
  MediumSociety: "#C69EF8",
  LightSociety: "#DAC3F7",
  BackgroundSociety: "#F3EDFB",

  // Science
  DarkScience: "#0488D1",
  BoldScience: "#49C0F3",
  MediumScience: "#82D8FC",
  LightScience: "#B3E4F9",
  BackgroundScience: "#E8F4F9",

  // history
  DarkHistory: "#93503B",
  BoldHistory: "#BB6F5E",
  MediumHistory: "#D2A095",
  LightHistory: "#E7CDC7",
  BackgroundHistory: "#F7EEEC",

  // Red
  DarkRed: "#D43636",
  BoldRed: "#F25555",
  MediumRed: "#FF8888",
  LightRed: "#FDD9D9",
  BackgroundRed: "#FDF1F1",

  // Navy
  DarkNavy: "#365DEA",
  BoldNavy: "#4F74F4",
  MediumNavy: "#9EB2FA",
  LightNavy: "#D8E0FD",
  BackgroundNavy: "#F5F7FE",

  // Blue
  Blue1: "#F4F8FE",
  Blue2: "#DEEAFD",
  Blue3: "#C7DCFC",
  Blue4: "#A6C7FB",
  Blue5: "#90B8F9",
  Blue6: "#7AAAF8",
  Blue7: "#649CF7",
  Blue8: "#4D8EF6",
  Blue9: "#337DF5",
  Blue10: "#2172F4",
  Blue11: "#0D66F5",
  Blue12: "#0059E8",

  // Gray
  Gray1: "#F5F5F5",
  Gray2: "#EAEAEA",
  Gray3: "#DBDCDC",
  Gray4: "#C9CACC",
  Gray5: "#B5B7BA",
  Gray6: "#909398",
  Gray7: "#787A80",
  Gray8: "#5C5F66",
  Gray9: "#43464E",
  Gray10: "#292C34",

  //Pink
  Pink1: "#FFF9FF",
  Pink2: "#FDE9FF",
  Pink3: "#F9CBF4",
  Pink4: "#FAB6F3",
  Pink5: "#F69EEE",
  Pink6: "#EF84EA",
  Pink7: "#E974E4",
  Pink8: "#DD5AD8",
  Pink9: "#BC33A6",
};

export default theme;
