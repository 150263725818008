import React from "react";
import { useRecoilState } from "recoil";
import { headerOpenState } from "../../atoms/atoms";

const Overlay = () => {
  const [recoilHeaderOpen] = useRecoilState(headerOpenState);
  return (
    <div
      style={{
        position: "absolute",
        height: "100%",
        width: "100%",
        backgroundColor: "rgba(255,255,255,0.5)",
        zIndex: 0,
        display: recoilHeaderOpen ? "block" : "none",
      }}
    />
  );
};

export default Overlay;
