const midHistoryClassHighData = {
  title: "세부과목",
  type: "select",
  list: [
    {
      text: "중학역사 1",
      title: "대단원",
      type: "check",
      list: [
        {
          text: "문명의 발생과 고대 세계의 형성",
          title: "대단원",
          id: "문명의 발생과 고대 세계의 형성",
        },
        {
          text: "세계 종교의 확산과 지역문화의 형성",
          title: "대단원",
          id: "세계 종교의 확산과 지역문화의 형성",
        },
        {
          text: "지역 세계의 교류와 변화",
          title: "대단원",
          id: "지역 세계의 교류와 변화",
        },
        {
          text: "제국주의 침략과 국민 국가 건설 운동",
          title: "대단원",
          id: "제국주의 침략과 국민 국가 건설 운동",
        },
        {
          text: "세계 대전과 사회 변동",
          title: "대단원",
          id: "세계 대전과 사회 변동",
        },
        {
          text: "현대 세계의 전개와 과제",
          title: "대단원",
          id: "현대 세계의 전개와 과제",
        },
      ],
    },
    {
      text: "중학역사 2",
      title: "대단원",
      type: "check",
      list: [
        {
          text: "선사 문화와 고대 국가의 형성",
          title: "대단원",
          id: "선사 문화와 고대 국가의 형성",
        },
        {
          text: "남북국 시대의 전개",
          title: "대단원",
          id: "남북국 시대의 전개",
        },
        {
          text: "고려의 성립과 변천",
          title: "대단원",
          id: "고려의 성립과 변천",
        },
        {
          text: "조선의 성립과 발전",
          title: "대단원",
          id: "조선의 성립과 발전",
        },
        { text: "조선 사회의 변동", title: "대단원", id: "조선 사회의 변동" },
        {
          text: "근현대 사회의 전개",
          title: "대단원",
          id: "근현대 사회의 전개",
        },
      ],
    },
  ],
};
export default midHistoryClassHighData;
