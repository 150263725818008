import * as React from "react"

function SvgComponent({ rotate = false, ...props }) {
  return (
    <svg
      width={10}
      height={6}
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: rotate ? "rotate(180deg)" : "" }}
      {...props}
    >
      <path
        d="M9 5L5 1 1 5"
        stroke="#B5B7BA"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgComponent
