import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.75 13v-2.778C5.75 6.786 8.548 4 12 4s6.25 2.786 6.25 6.222V13"
        stroke="#7AAAF8"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 20H6.636C4.628 20 3 18.433 3 16.5S4.628 13 6.636 13H8v7zM16 13h1.364C19.372 13 21 14.567 21 16.5S19.372 20 17.364 20H16v-7z"
        fill="#7AAAF8"
        stroke="#7AAAF8"
        strokeWidth={2}
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgComponent;
