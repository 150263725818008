const scienceClassHighData = {
  title: "세부과목",
  type: "select",
  list: [
    {
      text: "통합과학",
      title: "대단원",
      type: "check",
      list: [
        { text: "물질과 규칙성", title: "대단원", id: "물질과 규칙성" },
        {
          text: "시스템과 상호 작용",
          title: "대단원",
          id: "시스템과 상호 작용",
        },
        { text: "변화와 다양성", title: "대단원", id: "변화와 다양성" },
        { text: "환경과 에너지", title: "대단원", id: "환경과 에너지" },
      ],
    },
    {
      text: "물리학1",
      title: "대단원",
      type: "check",
      list: [
        { text: "역학과 에너지", title: "대단원", id: "역학과 에너지" },
        { text: "물질과 전자기장", title: "대단원", id: "물질과 전자기장" },
        { text: "파동과 정보 통신", title: "대단원", id: "파동과 정보 통신" },
      ],
    },
    {
      text: "화학1",
      title: "대단원",
      type: "check",
      list: [
        { text: "화학의 첫걸음", title: "대단원", id: "화학의 첫걸음" },
        { text: "원자의 세계", title: "대단원", id: "원자의 세계" },
        {
          text: "화학 결합과 분자의 세계",
          title: "대단원",
          id: "화학 결합과 분자의 세계",
        },
        {
          text: "역동적인 화학 반응",
          title: "대단원",
          id: "역동적인 화학 반응",
        },
      ],
    },
    {
      text: "생명과학1",
      title: "대단원",
      type: "check",
      list: [
        { text: "생명 과학의 이해", title: "대단원", id: "생명 과학의 이해" },
        { text: "사람의 물질대사", title: "대단원", id: "사람의 물질대사" },
        {
          text: "항상성과 몸의 조절",
          title: "대단원",
          id: "항상성과 몸의 조절",
        },
        { text: "유전", title: "대단원", id: "유전" },
        {
          text: "생태계와 상호 작용",
          title: "대단원",
          id: "생태계와 상호 작용",
        },
      ],
    },
    {
      text: "지구과학1",
      title: "대단원",
      type: "check",
      list: [
        { text: "고체 지구", title: "대단원", id: "고체 지구" },
        { text: "대기와 해양", title: "대단원", id: "대기와 해양" },
        { text: "우주", title: "대단원", id: "우주" },
      ],
    },
  ],
};

export default scienceClassHighData;
