import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

function useToken() {
  const [token, setToken] = useState(null);
  const [status, setStatus] = useState("loading");
  const location = useLocation();
  useEffect(() => {
    const fetchToken = async () => {
      let token = "";
      if (typeof window !== "undefined") {
        token = localStorage.getItem("token");
      }
      if (token) {
        setToken(token);
        setStatus("authenticated");
      } else {
        setToken(token);
        setStatus("unAuthenticated");
      }
    };

    fetchToken();
  }, [location.pathname]);

  return { data: { token: { accessToken: token } }, status };
}

export default useToken;
