import React from "react";
import theme, { Color } from "../../style/theme";
import { Pre2, Row } from "../../style/GlobalStyled";

type PropsType = {
  isSelected?: boolean;
  type: "Primary" | "Secondary" | "Tertiary";
  num?: number;
  title: string;
  size: "L" | "M" | "S" | "XS";
  style?: React.CSSProperties;
  onClick?: () => void;
  leftComponent?: React.ReactNode;
};

const CustomChip = ({
  isSelected,
  type,
  num,
  title,
  style,
  onClick,
  size,
  leftComponent,
}: PropsType) => {
  return (
    <Row
      style={{
        backgroundColor: isSelected
          ? CHIP_TYPE[type].selectedBackgroundColor
          : CHIP_TYPE[type].backgroundColor,
        borderRadius: 30,
        borderStyle: "solid",
        borderWidth: CHIP_TYPE[type].borderWidth,
        borderColor: isSelected
          ? CHIP_TYPE[type].selectedBorderColor
          : CHIP_TYPE[type].borderColor,
        padding: SIZE_TYPE[size].padding,
        boxSizing: "border-box",
        display: "inline-flex",
        width: "auto",
        cursor: "pointer",
        ...style,
      }}
      onClick={onClick}
    >
      {Boolean(leftComponent) && leftComponent}
      <Pre2
        fontWeight="Medium"
        style={{
          color: isSelected
            ? CHIP_TYPE[type].selectedTextColor
            : CHIP_TYPE[type].textColor,
          lineHeight: SIZE_TYPE[size].lineHeight,
          fontSize: SIZE_TYPE[size].fontSize,
          whiteSpace: "nowrap",
          userSelect: "none",
        }}
      >
        {`${title}${num !== undefined ? " ∙ " + num : ""}`}
      </Pre2>
    </Row>
  );
};

interface ICHIPTYPE {
  selectedBackgroundColor: Color;
  selectedTextColor: Color;
  pressedBackgroundColor: Color;
  textColor: Color;
  backgroundColor: Color;
  borderWidth: string;
  borderColor: Color;
  selectedBorderColor: Color;
}

const CHIP_TYPE: {
  Primary: ICHIPTYPE;
  Secondary: ICHIPTYPE;
  Tertiary: ICHIPTYPE;
} = {
  Primary: {
    selectedBackgroundColor: theme.Blue9,
    selectedTextColor: theme.White,
    pressedBackgroundColor: theme.Blue11,
    textColor: theme.Gray7,
    backgroundColor: theme.Gray1,
    borderWidth: "0px",
    borderColor: undefined,
    selectedBorderColor: undefined,
  },
  Secondary: {
    selectedBackgroundColor: theme.Blue2,
    selectedTextColor: theme.Blue12,
    pressedBackgroundColor: theme.Blue3,
    textColor: theme.Gray7,
    backgroundColor: theme.Gray1,
    borderWidth: "0px",
    borderColor: undefined,
    selectedBorderColor: undefined,
  },
  Tertiary: {
    selectedBackgroundColor: theme.Blue1,
    selectedTextColor: theme.Blue11,
    pressedBackgroundColor: theme.Blue2,
    textColor: theme.Gray6,
    backgroundColor: theme.White,
    borderWidth: "1px",
    borderColor: theme.Gray2,
    selectedBorderColor: theme.Blue4,
  },
};

interface ISIZETYPE {
  padding: string;
  lineHeight: string;
  fontSize: string;
}

const SIZE_TYPE: { L: ISIZETYPE; M: ISIZETYPE; S: ISIZETYPE; XS: ISIZETYPE } = {
  L: {
    padding: "12px 16px",
    lineHeight: "20px",
    fontSize: "20px",
  },
  M: {
    padding: "8px 12px",
    lineHeight: "16px",
    fontSize: "16px",
  },
  S: {
    padding: "8px 12px",
    lineHeight: "14px",
    fontSize: "14px",
  },
  XS: {
    padding: "4px 8px",
    lineHeight: "12px",
    fontSize: "12px",
  },
};

export default CustomChip;
