import React, { Dispatch, SetStateAction, useState } from "react";
import { Col, Pre3, Row } from "../../style/GlobalStyled";
import theme from "../../style/theme";
import SelectTriangle from "../../images/svg/SelectTriangle";
import { styled } from "styled-components";

type PropsType = {
  list: string[];
  state?: string;
  setState: Dispatch<SetStateAction<string>>;
  textStyle?: {};
  containerStyle?: React.CSSProperties;
};

const HoverPre3 = styled(Pre3)`
  &:hover {
    background-color: ${theme.Blue1};
  }
`;

const CustomDropdown = ({
  list,
  state,
  setState,
  textStyle,
  containerStyle,
}: PropsType) => {
  const [isActive, setIsActive] = useState(false);

  const handleClick = (item: string) => {
    setIsActive(true);
    setState(item);
  };
  return (
    <Col
      style={{
        width: "100%",
        alignItems: "flex-start",
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: isActive ? theme.Blue10 : theme.Gray3,
        borderBottomColor: isActive ? theme.White : theme.Gray3,
        borderRadius: isActive ? "8px 8px 0 0 " : "8px",
        position: "relative",
        zIndex: 1,
        height: 48,
      }}
    >
      <Row
        style={{
          justifyContent: "space-between",
          width: "100%",
          padding: "16px",
          cursor: "pointer",
        }}
        onClick={() => {
          setIsActive((prev) => !prev);
        }}
      >
        <Pre3
          fontWeight="Regular"
          color={theme.Black}
          style={{
            color:
              state === "지역" || state === "구/군" ? theme.Gray5 : theme.Black,
            ...textStyle,
          }}
        >
          {state}
        </Pre3>
        <SelectTriangle />
      </Row>
      {isActive && (
        <Col
          style={{
            alignItems: "flex-start",
            width: "100%",
            height: "auto",
            position: "absolute",
            top: 47,
            left: -1,
            backgroundColor: "white",
            borderRadius: "0px 0px 8px 8px",
            border: `1px solid ${theme.Blue10}`,
            borderTop: "0px",
            boxSizing: "content-box",
            overflowY: "scroll",
            maxHeight: "270px",
            justifyContent: "flex-start",
            ...containerStyle,
          }}
        >
          {list.map((item, index) => {
            return (
              <HoverPre3
                key={`dropdown_${index}`}
                fontWeight="Regular"
                style={{
                  padding: "16px",
                  width: "100%",
                  cursor: "pointer",
                  ...textStyle,
                }}
                onClick={() => {
                  handleClick(item);
                  setIsActive(false);
                }}
              >
                {item}
              </HoverPre3>
            );
          })}
        </Col>
      )}
    </Col>
  );
};

export default CustomDropdown;
