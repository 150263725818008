import React from "react";
import styled from "styled-components";
import Arrow20 from "../../images/svg/Arrow20";
import Check from "../../images/svg/Check";
import { Col, Pre4, Row } from "../../style/GlobalStyled";
import theme from "../../style/theme";
import { bottomUpModalStyle } from "../../style/GlobalStyle";
import ModalHeader from "../Molecules/ModalHeader";

const RegionListModal = ({
  propsData,
  name,
  state,
  setState,
  offCustomModal,
}: {
  propsData?: {
    list: string[];
  };
  name: string;
  state: string;
  setState: (any) => void;
  offCustomModal: () => void;
}) => {
  return (
    <Col
      style={{
        ...bottomUpModalStyle,
      }}
      id="invisible-scrollbar"
    >
      <ModalHeader
        title={name === "region" ? "지역 선택" : "구/군 선택"}
        offModal={offCustomModal}
      />
      <Col
        style={{
          height: "auto",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          overflowY: "scroll",
        }}
        id={"invisible-scrollbar"}
      >
        {propsData.list.map((j, jIndex) => {
          return (
            <HoverRow
              key={`regionListModal_${jIndex}`}
              style={{
                padding: "16px 20px",
                width: "100%",
                borderBottom: `1px solid ${theme.Gray2}`,
                borderTop: jIndex === 0 ? `1px solid ${theme.Gray2}` : 0,
                backgroundColor: state === j ? theme.Blue1 : theme.White,
                cursor: "pointer",
                justifyContent: "space-between",
                height: "46px",
              }}
              onClick={() => {
                setState(j);
                offCustomModal();
              }}
            >
              <Pre4 fontWeight="Regular">{j}</Pre4>
              {name === "region" && (
                <Arrow20
                  size={24}
                  stroke={state === j ? theme.Blue5 : theme.Gray5}
                />
              )}
              {name === "gu" && state === j && (
                <Check size={20} fill={theme.Blue11} />
              )}
            </HoverRow>
          );
        })}
      </Col>
    </Col>
  );
};

export default RegionListModal;

const HoverRow = styled(Row)`
  &:hover {
    background-color: ${theme.Blue1};
  }
`;
