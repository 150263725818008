type Internal = {
  text: string;
  title?: string;
  type?: "select" | "check";
  list?: Internal;
}[];

const subjectDataToList = ({
  title,
  type,
  list,
}: {
  title: string;
  type: "select" | "check";
  list: Internal;
}) => {
  const resultArr = [type === "select" ? 0 : []];
  recursive(list, resultArr);
  return resultArr;
};

const recursive = (list: Internal, resultArr: (number | any[])[]) => {
  const type = list[0].type;
  if (type) {
    resultArr.push(type === "select" ? 0 : []);
    recursive(list[0].list, resultArr);
  }
};

export default subjectDataToList;
