import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4 5h16M4 12h16M4 19h16"
        stroke={props.color ? props.color : "#909398"}
        strokeWidth={2}
        strokeLinecap="round"
      />
    </svg>
  )
}

export default SvgComponent