import * as React from "react";

const dir = { bottom: "0deg", top: "180deg" };

function SvgComponent(props) {
  return (
    <svg
      width={props.size ? props.size : 12}
      height={props.size ? props.size * (2 / 3) : 8}
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ rotate: props.dir ? dir[props.dir] : "0deg" }}
      {...props}
    >
      <path
        d="M6 7L1 1h10L6 7z"
        fill={props.color || "#C9CACC"}
        stroke={props.color || "#C9CACC"}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgComponent;
