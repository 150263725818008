const englishData = {
  title: "세부과목",
  type: "select",
  list: [
    {
      text: "영어",
      title: "교과서",
      type: "select",
      list: [
        {
          text: "NE능률(김성곤)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 1,
            },
            {
              text: "2단원 본문",
              id: 2,
            },
            {
              text: "3단원 본문",
              id: 3,
            },
            {
              text: "4단원 본문",
              id: 4,
            },
            {
              text: "5단원 본문",
              id: 5,
            },
            {
              text: "6단원 본문",
              id: 6,
            },
            {
              text: "7단원 본문",
              id: 7,
            },
            {
              text: "8단원 본문",
              id: 8,
            },
          ],
        },
        {
          text: "NE능률(양현권)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 9,
            },
            {
              text: "2단원 본문",
              id: 10,
            },
            {
              text: "3단원 본문",
              id: 11,
            },
            {
              text: "4단원 본문",
              id: 12,
            },
            {
              text: "5단원 본문",
              id: 13,
            },
            {
              text: "6단원 본문",
              id: 14,
            },
            {
              text: "7단원 본문",
              id: 15,
            },
            {
              text: "8단원 본문",
              id: 16,
            },
          ],
        },
        {
          text: "YBM(박준언)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 17,
            },
            {
              text: "2단원 본문",
              id: 18,
            },
            {
              text: "3단원 본문",
              id: 19,
            },
            {
              text: "4단원 본문",
              id: 20,
            },
            {
              text: "5단원 본문",
              id: 21,
            },
            {
              text: "6단원 본문",
              id: 22,
            },
            {
              text: "7단원 본문",
              id: 23,
            },
            {
              text: "8단원 본문",
              id: 24,
            },
            {
              text: "9단원 본문",
              id: 25,
            },
            {
              text: "10단원 본문",
              id: 26,
            },
            {
              text: "S.L. 본문",
              id: 27,
            },
          ],
        },
        {
          text: "YBM(한상호)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 28,
            },
            {
              text: "2단원 본문",
              id: 29,
            },
            {
              text: "3단원 본문",
              id: 30,
            },
            {
              text: "4단원 본문",
              id: 31,
            },
            {
              text: "5단원 본문",
              id: 32,
            },
            {
              text: "6단원 본문",
              id: 33,
            },
            {
              text: "7단원 본문",
              id: 34,
            },
            {
              text: "8단원 본문",
              id: 35,
            },
            {
              text: "S.L.1 본문",
              id: 36,
            },
            {
              text: "S.L.2 본문",
              id: 37,
            },
            {
              text: "S.L.1 A.R.",
              id: 311,
            },
          ],
        },
        {
          text: "금성(최인철)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 38,
            },
            {
              text: "2단원 본문",
              id: 39,
            },
            {
              text: "3단원 본문",
              id: 40,
            },
            {
              text: "4단원 본문",
              id: 41,
            },
            {
              text: "5단원 본문",
              id: 42,
            },
            {
              text: "6단원 본문",
              id: 43,
            },
            {
              text: "7단원 본문",
              id: 44,
            },
            {
              text: "8단원 본문",
              id: 45,
            },
            {
              text: "9단원 본문",
              id: 46,
            },
            {
              text: "10단원 본문",
              id: 47,
            },
          ],
        },
        {
          text: "동아(이병민)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 48,
            },
            {
              text: "2단원 본문",
              id: 49,
            },
            {
              text: "3단원 본문",
              id: 50,
            },
            {
              text: "4단원 본문",
              id: 51,
            },
            {
              text: "5단원 본문",
              id: 52,
            },
            {
              text: "6단원 본문",
              id: 53,
            },
            {
              text: "7단원 본문",
              id: 54,
            },
            {
              text: "8단원 본문",
              id: 55,
            },
            {
              text: "A.R.1 본문",
              id: 56,
            },
            {
              text: "A.R.2 본문",
              id: 57,
            },
          ],
        },
        {
          text: "비상(홍민표)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 58,
            },
            {
              text: "2단원 본문",
              id: 59,
            },
            {
              text: "3단원 본문",
              id: 60,
            },
            {
              text: "4단원 본문",
              id: 61,
            },
            {
              text: "5단원 본문",
              id: 62,
            },
            {
              text: "6단원 본문",
              id: 63,
            },
            {
              text: "7단원 본문",
              id: 64,
            },
            {
              text: "8단원 본문",
              id: 65,
            },
            {
              text: "1단원 R.M.",
              id: 312,
            },
            {
              text: "2단원 R.C.",
              id: 313,
            },
            {
              text: "3단원 R.M.",
              id: 314,
            },
            {
              text: "4단원 R.C.",
              id: 315,
            },
            {
              text: "5단원 R.C.",
              id: 316,
            },
            {
              text: "6단원 R.C.",
              id: 317,
            },
            {
              text: "7단원 R.C.",
              id: 318,
            },
            {
              text: "8단원 R.C.",
              id: 319,
            },
          ],
        },
        {
          text: "지학사(민찬규)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 66,
            },
            {
              text: "2단원 본문",
              id: 67,
            },
            {
              text: "3단원 본문",
              id: 68,
            },
            {
              text: "4단원 본문",
              id: 69,
            },
            {
              text: "5단원 본문",
              id: 70,
            },
            {
              text: "6단원 본문",
              id: 71,
            },
            {
              text: "7단원 본문",
              id: 72,
            },
            {
              text: "8단원 본문",
              id: 73,
            },
            {
              text: "1단원 S.R.",
              id: 320,
            },
            {
              text: "2단원 S.R.",
              id: 321,
            },
            {
              text: "3단원 S.R.",
              id: 322,
            },
            {
              text: "4단원 S.R.",
              id: 323,
            },
            {
              text: "5단원 S.R.",
              id: 324,
            },
            {
              text: "6단원 S.R.",
              id: 325,
            },
            {
              text: "7단원 S.R.",
              id: 326,
            },
            {
              text: "8단원 S.R.",
              id: 327,
            },
          ],
        },
        {
          text: "천재(김태영)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 74,
            },
            {
              text: "2단원 본문",
              id: 75,
            },
            {
              text: "3단원 본문",
              id: 76,
            },
            {
              text: "4단원 본문",
              id: 77,
            },
            {
              text: "5단원 본문",
              id: 78,
            },
            {
              text: "6단원 본문",
              id: 79,
            },
            {
              text: "7단원 본문",
              id: 80,
            },
            {
              text: "8단원 본문",
              id: 81,
            },
          ],
        },
        {
          text: "천재(이재영)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 82,
            },
            {
              text: "2단원 본문",
              id: 83,
            },
            {
              text: "3단원 본문",
              id: 84,
            },
            {
              text: "4단원 본문",
              id: 85,
            },
            {
              text: "5단원 본문",
              id: 86,
            },
            {
              text: "6단원 본문",
              id: 87,
            },
            {
              text: "7단원 본문",
              id: 88,
            },
            {
              text: "8단원 본문",
              id: 89,
            },
            {
              text: "1단원 F.R.",
              id: 328,
            },
            {
              text: "2단원 F.R.",
              id: 329,
            },
            {
              text: "3단원 F.R.",
              id: 330,
            },
            {
              text: "4단원 F.R.",
              id: 331,
            },
            {
              text: "5단원 F.R.",
              id: 332,
            },
            {
              text: "6단원 F.R.",
              id: 333,
            },
            {
              text: "7단원 F.R.",
              id: 334,
            },
            {
              text: "8단원 F.R.",
              id: 335,
            },
          ],
        },
        {
          text: "다락원(김길중)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 534,
            },
            {
              text: "2단원 본문",
              id: 535,
            },
            {
              text: "3단원 본문",
              id: 536,
            },
            {
              text: "4단원 본문",
              id: 537,
            },
            {
              text: "5단원 본문",
              id: 538,
            },
            {
              text: "6단원 본문",
              id: 539,
            },
            {
              text: "7단원 본문",
              id: 540,
            },
            {
              text: "8단원 본문",
              id: 541,
            },
            {
              text: "S.U. 본문",
              id: 542,
            },
          ],
        },
      ],
    },
    {
      text: "영어1",
      title: "교과서",
      type: "select",
      list: [
        {
          text: "NE능률(김성곤)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 90,
            },
            {
              text: "2단원 본문",
              id: 91,
            },
            {
              text: "3단원 본문",
              id: 92,
            },
            {
              text: "4단원 본문",
              id: 93,
            },
            {
              text: "5단원 본문",
              id: 94,
            },
            {
              text: "S.L. 본문",
              id: 95,
            },
          ],
        },
        {
          text: "YBM(박준언)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 96,
            },
            {
              text: "2단원 본문",
              id: 97,
            },
            {
              text: "3단원 본문",
              id: 98,
            },
            {
              text: "4단원 본문",
              id: 99,
            },
            {
              text: "5단원 본문",
              id: 100,
            },
            {
              text: "6단원 본문",
              id: 101,
            },
            {
              text: "S.L. 본문",
              id: 102,
            },
          ],
        },
        {
          text: "YBM(한상호)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 103,
            },
            {
              text: "2단원 본문",
              id: 104,
            },
            {
              text: "3단원 본문",
              id: 105,
            },
            {
              text: "4단원 본문",
              id: 106,
            },
            {
              text: "5단원 본문",
              id: 107,
            },
            {
              text: "6단원 본문",
              id: 108,
            },
            {
              text: "S.L. 본문",
              id: 109,
            },
          ],
        },
        {
          text: "교학사(강문구)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 110,
            },
            {
              text: "2단원 본문",
              id: 111,
            },
            {
              text: "3단원 본문",
              id: 112,
            },
            {
              text: "4단원 본문",
              id: 113,
            },
            {
              text: "5단원 본문",
              id: 114,
            },
            {
              text: "6단원 본문",
              id: 115,
            },
            {
              text: "7단원 본문",
              id: 116,
            },
          ],
        },
        {
          text: "금성(최인철)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 117,
            },
            {
              text: "2단원 본문",
              id: 118,
            },
            {
              text: "3단원 본문",
              id: 119,
            },
            {
              text: "4단원 본문",
              id: 120,
            },
            {
              text: "5단원 본문",
              id: 121,
            },
            {
              text: "6단원 본문",
              id: 122,
            },
            {
              text: "1단원 S.R.",
              id: 336,
            },
            {
              text: "2단원 S.R.",
              id: 337,
            },
            {
              text: "3단원 S.R.",
              id: 338,
            },
            {
              text: "4단원 S.R.",
              id: 339,
            },
            {
              text: "5단원 S.R.",
              id: 340,
            },
            {
              text: "6단원 S.R.",
              id: 341,
            },
          ],
        },
        {
          text: "동아(권혁승)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 123,
            },
            {
              text: "2단원 본문",
              id: 124,
            },
            {
              text: "3단원 본문",
              id: 125,
            },
            {
              text: "4단원 본문",
              id: 126,
            },
            {
              text: "5단원 본문",
              id: 127,
            },
            {
              text: "6단원 본문",
              id: 128,
            },
          ],
        },
        {
          text: "비상(홍민표)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 129,
            },
            {
              text: "2단원 본문",
              id: 130,
            },
            {
              text: "3단원 본문",
              id: 131,
            },
            {
              text: "4단원 본문",
              id: 132,
            },
            {
              text: "5단원 본문",
              id: 133,
            },
            {
              text: "6단원 본문",
              id: 134,
            },
            {
              text: "1단원 R.M.",
              id: 342,
            },
            {
              text: "2단원 R.M.",
              id: 343,
            },
            {
              text: "3단원 R.M.",
              id: 344,
            },
            {
              text: "4단원 R.M.",
              id: 345,
            },
            {
              text: "5단원 R.M.",
              id: 346,
            },
            {
              text: "6단원 R.M.",
              id: 347,
            },
          ],
        },
        {
          text: "지학사(민찬규)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 135,
            },
            {
              text: "2단원 본문",
              id: 136,
            },
            {
              text: "3단원 본문",
              id: 137,
            },
            {
              text: "4단원 본문",
              id: 138,
            },
            {
              text: "5단원 본문",
              id: 139,
            },
            {
              text: "6단원 본문",
              id: 140,
            },
            {
              text: "1단원 T.O.T.B.",
              id: 348,
            },
            {
              text: "2단원 T.O.T.B.",
              id: 349,
            },
            {
              text: "3단원 T.O.T.B.",
              id: 350,
            },
            {
              text: "4단원 T.O.T.B.",
              id: 351,
            },
            {
              text: "5단원 T.O.T.B.",
              id: 352,
            },
            {
              text: "6단원 T.O.T.B.",
              id: 353,
            },
          ],
        },
        {
          text: "천재(이재영)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 141,
            },
            {
              text: "2단원 본문",
              id: 142,
            },
            {
              text: "3단원 본문",
              id: 143,
            },
            {
              text: "4단원 본문",
              id: 144,
            },
            {
              text: "5단원 본문",
              id: 145,
            },
            {
              text: "6단원 본문",
              id: 146,
            },
          ],
        },
        {
          text: "다락원(김길중)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 543,
            },
            {
              text: "2단원 본문",
              id: 544,
            },
            {
              text: "3단원 본문",
              id: 545,
            },
            {
              text: "4단원 본문",
              id: 546,
            },
            {
              text: "5단원 본문",
              id: 547,
            },
            {
              text: "6단원 본문",
              id: 548,
            },
          ],
        },
      ],
    },
    {
      text: "영어2",
      title: "교과서",
      type: "select",
      list: [
        {
          text: "NE능률(김성곤)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 147,
            },
            {
              text: "2단원 본문",
              id: 148,
            },
            {
              text: "3단원 본문",
              id: 149,
            },
            {
              text: "4단원 본문",
              id: 150,
            },
            {
              text: "5단원 본문",
              id: 151,
            },
            {
              text: "S.L. 본문",
              id: 152,
            },
          ],
        },
        {
          text: "YBM(박준언)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 153,
            },
            {
              text: "2단원 본문",
              id: 154,
            },
            {
              text: "3단원 본문",
              id: 155,
            },
            {
              text: "4단원 본문",
              id: 156,
            },
            {
              text: "5단원 본문",
              id: 157,
            },
            {
              text: "6단원 본문",
              id: 158,
            },
            {
              text: "S.L. 본문",
              id: 159,
            },
            {
              text: "1단원 S.R.",
              id: 354,
            },
            {
              text: "2단원 S.R.",
              id: 355,
            },
            {
              text: "3단원 S.R.",
              id: 356,
            },
            {
              text: "4단원 S.R.",
              id: 357,
            },
            {
              text: "5단원 S.R.",
              id: 358,
            },
            {
              text: "6단원 S.R.",
              id: 359,
            },
          ],
        },
        {
          text: "YBM(한상호)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 160,
            },
            {
              text: "2단원 본문",
              id: 161,
            },
            {
              text: "3단원 본문",
              id: 162,
            },
            {
              text: "4단원 본문",
              id: 163,
            },
            {
              text: "5단원 본문",
              id: 164,
            },
            {
              text: "6단원 본문",
              id: 165,
            },
            {
              text: "S.L. 본문",
              id: 166,
            },
            {
              text: "1단원 D.I.T.T.",
              id: 360,
            },
            {
              text: "2단원 D.I.T.T.",
              id: 361,
            },
            {
              text: "3단원 D.I.T.T.",
              id: 362,
            },
            {
              text: "4단원 D.I.T.T.",
              id: 363,
            },
            {
              text: "5단원 D.I.T.T.",
              id: 364,
            },
            {
              text: "6단원 D.I.T.T.",
              id: 365,
            },
          ],
        },
        {
          text: "금성(최인철)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 167,
            },
            {
              text: "2단원 본문",
              id: 168,
            },
            {
              text: "3단원 본문",
              id: 169,
            },
            {
              text: "4단원 본문",
              id: 170,
            },
            {
              text: "5단원 본문",
              id: 171,
            },
            {
              text: "6단원 본문",
              id: 172,
            },
            {
              text: "S.L.1 본문",
              id: 173,
            },
            {
              text: "S.L.2 본문",
              id: 174,
            },
            {
              text: "1단원 S.R.",
              id: 366,
            },
            {
              text: "2단원 S.R.",
              id: 367,
            },
            {
              text: "3단원 S.R.",
              id: 368,
            },
            {
              text: "4단원 S.R.",
              id: 369,
            },
            {
              text: "5단원 S.R.",
              id: 370,
            },
            {
              text: "6단원 S.R.",
              id: 371,
            },
          ],
        },
        {
          text: "동아(권혁승)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 175,
            },
            {
              text: "2단원 본문",
              id: 176,
            },
            {
              text: "3단원 본문",
              id: 177,
            },
            {
              text: "4단원 본문",
              id: 178,
            },
            {
              text: "5단원 본문",
              id: 179,
            },
            {
              text: "6단원 본문",
              id: 180,
            },
            {
              text: "S.L. 본문",
              id: 181,
            },
          ],
        },
        {
          text: "비상(홍민표)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 182,
            },
            {
              text: "2단원 본문",
              id: 183,
            },
            {
              text: "3단원 본문",
              id: 184,
            },
            {
              text: "4단원 본문",
              id: 185,
            },
            {
              text: "5단원 본문",
              id: 186,
            },
            {
              text: "6단원 본문",
              id: 187,
            },
            {
              text: "1단원 R.M.",
              id: 372,
            },
            {
              text: "2단원 R.M.",
              id: 373,
            },
            {
              text: "3단원 R.M.",
              id: 374,
            },
            {
              text: "4단원 R.M.",
              id: 375,
            },
            {
              text: "5단원 R.M.",
              id: 376,
            },
            {
              text: "6단원 R.M.",
              id: 377,
            },
          ],
        },
        {
          text: "지학사(민찬규)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 188,
            },
            {
              text: "2단원 본문",
              id: 189,
            },
            {
              text: "3단원 본문",
              id: 190,
            },
            {
              text: "4단원 본문",
              id: 191,
            },
            {
              text: "5단원 본문",
              id: 192,
            },
            {
              text: "6단원 본문",
              id: 193,
            },
            {
              text: "1단원 T.O.T.B.",
              id: 378,
            },
            {
              text: "2단원 T.O.T.B.",
              id: 379,
            },
            {
              text: "3단원 T.O.T.B.",
              id: 380,
            },
            {
              text: "4단원 T.O.T.B.",
              id: 381,
            },
            {
              text: "5단원 T.O.T.B.",
              id: 382,
            },
            {
              text: "6단원 T.O.T.B.",
              id: 383,
            },
          ],
        },
        {
          text: "천재(이재영)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 194,
            },
            {
              text: "2단원 본문",
              id: 195,
            },
            {
              text: "3단원 본문",
              id: 196,
            },
            {
              text: "4단원 본문",
              id: 197,
            },
            {
              text: "5단원 본문",
              id: 198,
            },
            {
              text: "6단원 본문",
              id: 199,
            },
          ],
        },
        {
          text: "다락원(김길중)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 549,
            },
            {
              text: "1단원 R.M.",
              id: 550,
            },
            {
              text: "2단원 본문",
              id: 551,
            },
            {
              text: "2단원 R.M.",
              id: 552,
            },
            {
              text: "3단원 본문",
              id: 553,
            },
            {
              text: "3단원 R.M.",
              id: 554,
            },
            {
              text: "4단원 본문",
              id: 555,
            },
            {
              text: "4단원 R.M.",
              id: 556,
            },
            {
              text: "5단원 본문",
              id: 557,
            },
            {
              text: "5단원 R.M.",
              id: 558,
            },
            {
              text: "6단원 본문",
              id: 559,
            },
            {
              text: "6단원 R.M.",
              id: 560,
            },
          ],
        },
      ],
    },
    {
      text: "영어독해와작문",
      title: "교과서",
      type: "select",
      list: [
        {
          text: "NE능률(양현권)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문1",
              id: 200,
            },
            {
              text: "2단원 본문1",
              id: 201,
            },
            {
              text: "3단원 본문1",
              id: 202,
            },
            {
              text: "4단원 본문1",
              id: 203,
            },
            {
              text: "5단원 본문1",
              id: 204,
            },
            {
              text: "6단원 본문1",
              id: 205,
            },
            {
              text: "1단원 본문2",
              id: 384,
            },
            {
              text: "2단원 본문2",
              id: 385,
            },
            {
              text: "3단원 본문2",
              id: 386,
            },
            {
              text: "4단원 본문2",
              id: 387,
            },
            {
              text: "5단원 본문2",
              id: 388,
            },
            {
              text: "6단원 본문2",
              id: 389,
            },
          ],
        },
        {
          text: "YBM(신정현)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 206,
            },
            {
              text: "2단원 본문",
              id: 207,
            },
            {
              text: "3단원 본문",
              id: 208,
            },
            {
              text: "4단원 본문",
              id: 209,
            },
            {
              text: "5단원 본문",
              id: 210,
            },
            {
              text: "6단원 본문",
              id: 211,
            },
            {
              text: "7단원 본문",
              id: 212,
            },
            {
              text: "1단원 F.R.",
              id: 390,
            },
            {
              text: "2단원 F.R.",
              id: 391,
            },
            {
              text: "3단원 F.R.",
              id: 392,
            },
            {
              text: "4단원 F.R.",
              id: 393,
            },
            {
              text: "5단원 F.R.",
              id: 394,
            },
            {
              text: "6단원 F.R.",
              id: 395,
            },
            {
              text: "7단원 F.R.",
              id: 396,
            },
          ],
        },
        {
          text: "동아(권혁승)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 213,
            },
            {
              text: "2단원 본문",
              id: 214,
            },
            {
              text: "3단원 본문",
              id: 215,
            },
            {
              text: "4단원 본문",
              id: 216,
            },
            {
              text: "5단원 본문",
              id: 217,
            },
            {
              text: "6단원 본문",
              id: 218,
            },
            {
              text: "1단원 F.R.",
              id: 397,
            },
            {
              text: "2단원 F.R.",
              id: 398,
            },
            {
              text: "3단원 F.R.",
              id: 399,
            },
            {
              text: "4단원 F.R.",
              id: 400,
            },
            {
              text: "5단원 F.R.",
              id: 401,
            },
            {
              text: "6단원 F.R.",
              id: 402,
            },
          ],
        },
        {
          text: "비상(김진완)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 219,
            },
            {
              text: "2단원 본문",
              id: 220,
            },
            {
              text: "3단원 본문",
              id: 221,
            },
            {
              text: "4단원 본문",
              id: 222,
            },
            {
              text: "5단원 본문",
              id: 223,
            },
            {
              text: "6단원 본문",
              id: 224,
            },
            {
              text: "S.L. 본문",
              id: 225,
            },
          ],
        },
        {
          text: "천재(안병규)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 226,
            },
            {
              text: "2단원 본문",
              id: 227,
            },
            {
              text: "3단원 본문",
              id: 228,
            },
            {
              text: "4단원 본문",
              id: 229,
            },
            {
              text: "5단원 본문",
              id: 230,
            },
            {
              text: "6단원 본문",
              id: 231,
            },
            {
              text: "S.B. 본문1",
              id: 232,
            },
            {
              text: "1단원 E.R.",
              id: 403,
            },
            {
              text: "2단원 E.R.",
              id: 404,
            },
            {
              text: "3단원 E.R.",
              id: 405,
            },
            {
              text: "4단원 E.R.",
              id: 406,
            },
            {
              text: "5단원 E.R.",
              id: 407,
            },
            {
              text: "6단원 E.R.",
              id: 408,
            },
            {
              text: "S.B. 본문2",
              id: 409,
            },
            {
              text: "S.B. 본문3",
              id: 410,
            },
            {
              text: "S.B. 본문4",
              id: 411,
            },
            {
              text: "S.B. 본문5",
              id: 412,
            },
          ],
        },
      ],
    },
    {
      text: "실용영어",
      title: "교과서",
      type: "select",
      list: [
        {
          text: "NE능률(김성곤)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문1",
              id: 233,
            },
            {
              text: "2단원 본문1",
              id: 234,
            },
            {
              text: "3단원 본문1",
              id: 235,
            },
            {
              text: "4단원 본문1",
              id: 236,
            },
            {
              text: "5단원 본문1",
              id: 237,
            },
            {
              text: "6단원 본문1",
              id: 238,
            },
            {
              text: "1단원 본문2",
              id: 413,
            },
            {
              text: "2단원 본문2",
              id: 414,
            },
            {
              text: "3단원 본문2",
              id: 415,
            },
            {
              text: "4단원 본문2",
              id: 416,
            },
            {
              text: "5단원 본문2",
              id: 417,
            },
            {
              text: "6단원 본문2",
              id: 418,
            },
          ],
        },
        {
          text: "YBM(박준언)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 239,
            },
            {
              text: "2단원 본문",
              id: 240,
            },
            {
              text: "3단원 본문",
              id: 241,
            },
            {
              text: "4단원 본문",
              id: 242,
            },
            {
              text: "5단원 본문",
              id: 243,
            },
            {
              text: "6단원 본문",
              id: 244,
            },
            {
              text: "S.L. 본문",
              id: 245,
            },
          ],
        },
        {
          text: "천재(안병규)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문1",
              id: 246,
            },
            {
              text: "2단원 본문1",
              id: 247,
            },
            {
              text: "3단원 본문1",
              id: 248,
            },
            {
              text: "4단원 본문1",
              id: 249,
            },
            {
              text: "5단원 본문1",
              id: 250,
            },
            {
              text: "S.U.1 본문",
              id: 251,
            },
            {
              text: "S.U.2 본문1",
              id: 252,
            },
            {
              text: "1단원 본문2",
              id: 419,
            },
            {
              text: "2단원 본문2",
              id: 420,
            },
            {
              text: "3단원 본문2",
              id: 421,
            },
            {
              text: "4단원 본문2",
              id: 422,
            },
            {
              text: "5단원 본문2",
              id: 423,
            },
            {
              text: "S.U.2 본문2",
              id: 424,
            },
            {
              text: "S.U.2 본문3",
              id: 425,
            },
            {
              text: "S.U.2 본문4",
              id: 426,
            },
            {
              text: "S.U.2 본문5",
              id: 427,
            },
            {
              text: "S.U.2 본문6",
              id: 428,
            },
          ],
        },
      ],
    },
    {
      text: "영어권문화",
      title: "교과서",
      type: "select",
      list: [
        {
          text: "NE능률(김정렬)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 253,
            },
            {
              text: "2단원 본문",
              id: 254,
            },
            {
              text: "3단원 본문",
              id: 255,
            },
            {
              text: "4단원 본문",
              id: 256,
            },
            {
              text: "5단원 본문",
              id: 257,
            },
            {
              text: "6단원 본문",
              id: 258,
            },
            {
              text: "7단원 본문",
              id: 259,
            },
            {
              text: "1단원 R.M.C.",
              id: 429,
            },
            {
              text: "2단원 R.M.C.",
              id: 430,
            },
            {
              text: "3단원 R.M.C.",
              id: 431,
            },
            {
              text: "4단원 R.M.C.",
              id: 432,
            },
            {
              text: "5단원 R.M.C.",
              id: 433,
            },
            {
              text: "6단원 R.M.C.",
              id: 434,
            },
            {
              text: "7단원 R.M.C.",
              id: 435,
            },
          ],
        },
      ],
    },
    {
      text: "심화영어1",
      title: "교과서",
      type: "select",
      list: [
        {
          text: "NE능률(허명혜)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 260,
            },
            {
              text: "2단원 본문",
              id: 261,
            },
            {
              text: "3단원 본문",
              id: 262,
            },
            {
              text: "4단원 본문",
              id: 263,
            },
            {
              text: "5단원 본문",
              id: 264,
            },
            {
              text: "6단원 본문",
              id: 265,
            },
            {
              text: "7단원 본문",
              id: 266,
            },
            {
              text: "1단원 S.A.R.1",
              id: 436,
            },
            {
              text: "1단원 S.A.R.2",
              id: 437,
            },
            {
              text: "1단원 S.A.R.3",
              id: 438,
            },
            {
              text: "2단원 S.A.R.1",
              id: 439,
            },
            {
              text: "2단원 S.A.R.2",
              id: 440,
            },
            {
              text: "2단원 S.A.R.3",
              id: 441,
            },
            {
              text: "3단원 S.A.R.1",
              id: 442,
            },
            {
              text: "3단원 S.A.R.2",
              id: 443,
            },
            {
              text: "3단원 S.A.R.3",
              id: 444,
            },
            {
              text: "4단원 S.A.R.1",
              id: 445,
            },
            {
              text: "4단원 S.A.R.2",
              id: 446,
            },
            {
              text: "4단원 S.A.R.3",
              id: 447,
            },
            {
              text: "5단원 S.A.R.",
              id: 448,
            },
            {
              text: "5단원 S.A.R.",
              id: 449,
            },
            {
              text: "5단원 S.A.R.",
              id: 450,
            },
            {
              text: "6단원 S.A.R.1",
              id: 451,
            },
            {
              text: "6단원 S.A.R.2",
              id: 452,
            },
            {
              text: "6단원 S.A.R.3",
              id: 453,
            },
            {
              text: "7단원 S.A.R.1",
              id: 454,
            },
            {
              text: "7단원 S.A.R.2",
              id: 455,
            },
            {
              text: "7단원 S.A.R.3",
              id: 456,
            },
          ],
        },
        {
          text: "YBM(신정현)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 267,
            },
            {
              text: "2단원 본문",
              id: 268,
            },
            {
              text: "3단원 본문",
              id: 269,
            },
            {
              text: "4단원 본문",
              id: 270,
            },
            {
              text: "5단원 본문",
              id: 271,
            },
            {
              text: "6단원 본문",
              id: 272,
            },
            {
              text: "1단원 R.A.",
              id: 457,
            },
            {
              text: "2단원 R.A.",
              id: 458,
            },
            {
              text: "3단원 R.A.",
              id: 459,
            },
            {
              text: "4단원 R.A.",
              id: 460,
            },
            {
              text: "5단원 R.A.",
              id: 461,
            },
            {
              text: "6단원 R.A.",
              id: 462,
            },
          ],
        },
        {
          text: "천재(이재영)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 273,
            },
            {
              text: "2단원 본문",
              id: 274,
            },
            {
              text: "3단원 본문",
              id: 275,
            },
            {
              text: "4단원 본문",
              id: 276,
            },
            {
              text: "5단원 본문",
              id: 277,
            },
            {
              text: "6단원 본문",
              id: 278,
            },
            {
              text: "1단원 P.R.",
              id: 463,
            },
            {
              text: "2단원 P.R.",
              id: 464,
            },
            {
              text: "3단원 P.R.",
              id: 465,
            },
            {
              text: "4단원 P.R.",
              id: 466,
            },
            {
              text: "5단원 P.R.",
              id: 467,
            },
            {
              text: "6단원 P.R.",
              id: 468,
            },
          ],
        },
      ],
    },
    {
      text: "진로영어",
      title: "교과서",
      type: "select",
      list: [
        {
          text: "NE능률(김정렬)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 279,
            },
            {
              text: "2단원 본문",
              id: 280,
            },
            {
              text: "3단원 본문",
              id: 281,
            },
            {
              text: "4단원 본문",
              id: 282,
            },
            {
              text: "5단원 본문",
              id: 283,
            },
            {
              text: "6단원 본문",
              id: 284,
            },
            {
              text: "7단원 본문",
              id: 285,
            },
          ],
        },
        {
          text: "YBM(박준언)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 286,
            },
            {
              text: "2단원 본문",
              id: 287,
            },
            {
              text: "3단원 본문",
              id: 288,
            },
            {
              text: "4단원 본문1",
              id: 289,
            },
            {
              text: "5단원 본문1",
              id: 290,
            },
            {
              text: "6단원 본문",
              id: 291,
            },
            {
              text: "S.L. 본문",
              id: 292,
            },
            {
              text: "2단원 A.R.1",
              id: 469,
            },
            {
              text: "2단원 A.R.2",
              id: 470,
            },
            {
              text: "4단원 본문2",
              id: 471,
            },
            {
              text: "4단원 A.R.3",
              id: 472,
            },
            {
              text: "4단원 A.R.4",
              id: 473,
            },
            {
              text: "5단원 본문2",
              id: 474,
            },
            {
              text: "6단원 A.R.5",
              id: 475,
            },
            {
              text: "6단원 A.R.6",
              id: 476,
            },
          ],
        },
      ],
    },
    {
      text: "심화영어독해1",
      title: "교과서",
      type: "select",
      list: [
        {
          text: "NE능률(허명혜)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문",
              id: 293,
            },
            {
              text: "2단원 본문",
              id: 294,
            },
            {
              text: "3단원 본문",
              id: 295,
            },
            {
              text: "4단원 본문",
              id: 296,
            },
            {
              text: "5단원 본문",
              id: 297,
            },
            {
              text: "6단원 본문",
              id: 298,
            },
            {
              text: "1단원 S.A.R.1",
              id: 477,
            },
            {
              text: "1단원 S.A.R.2",
              id: 478,
            },
            {
              text: "1단원 S.A.R.3",
              id: 479,
            },
            {
              text: "1단원 E.R.1",
              id: 480,
            },
            {
              text: "1단원 E.R.2",
              id: 481,
            },
            {
              text: "2단원 S.A.R.1",
              id: 482,
            },
            {
              text: "2단원 S.A.R.2",
              id: 483,
            },
            {
              text: "2단원 S.A.R.3",
              id: 484,
            },
            {
              text: "2단원 E.R.1",
              id: 485,
            },
            {
              text: "2단원 E.R.2",
              id: 486,
            },
            {
              text: "3단원 S.A.R.1",
              id: 487,
            },
            {
              text: "3단원 S.A.R.2",
              id: 488,
            },
            {
              text: "3단원 S.A.R.3",
              id: 489,
            },
            {
              text: "3단원 E.R.1",
              id: 490,
            },
            {
              text: "3단원 E.R.2",
              id: 491,
            },
            {
              text: "3단원 R.C.D.T.",
              id: 492,
            },
            {
              text: "3단원 R.C.D.T.",
              id: 493,
            },
            {
              text: "3단원 R.C.D.T.",
              id: 494,
            },
            {
              text: "4단원 S.A.R.1",
              id: 495,
            },
            {
              text: "4단원 S.A.R.2",
              id: 496,
            },
            {
              text: "4단원 S.A.R.3",
              id: 497,
            },
            {
              text: "4단원 E.R.1",
              id: 498,
            },
            {
              text: "4단원 E.R.2",
              id: 499,
            },
            {
              text: "5단원 S.A.R.1",
              id: 500,
            },
            {
              text: "5단원 S.A.R.2",
              id: 501,
            },
            {
              text: "5단원 S.A.R.3",
              id: 502,
            },
            {
              text: "5단원 E.R.1",
              id: 503,
            },
            {
              text: "5단원 E.R.2",
              id: 504,
            },
            {
              text: "6단원 S.A.R.1",
              id: 505,
            },
            {
              text: "6단원 S.A.R.2",
              id: 506,
            },
            {
              text: "6단원 S.A.R.3",
              id: 507,
            },
            {
              text: "6단원 E.R.1",
              id: 508,
            },
            {
              text: "6단원 E.R.2",
              id: 509,
            },
            {
              text: "6단원 R.C.D.T.",
              id: 510,
            },
            {
              text: "6단원 R.C.D.T.",
              id: 511,
            },
            {
              text: "6단원 R.C.D.T.",
              id: 512,
            },
          ],
        },
        {
          text: "YBM(신정현)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문1",
              id: 299,
            },
            {
              text: "2단원 본문1",
              id: 300,
            },
            {
              text: "3단원 본문1",
              id: 301,
            },
            {
              text: "4단원 본문1",
              id: 302,
            },
            {
              text: "5단원 본문1",
              id: 303,
            },
            {
              text: "6단원 본문1",
              id: 304,
            },
            {
              text: "1단원 본문2",
              id: 513,
            },
            {
              text: "1단원 R.M.",
              id: 514,
            },
            {
              text: "2단원 본문2",
              id: 515,
            },
            {
              text: "2단원 R.M.",
              id: 516,
            },
            {
              text: "3단원 본문2",
              id: 517,
            },
            {
              text: "3단원 R.M.",
              id: 518,
            },
            {
              text: "4단원 본문2",
              id: 519,
            },
            {
              text: "4단원 R.M.",
              id: 520,
            },
            {
              text: "5단원 본문2",
              id: 521,
            },
            {
              text: "5단원 R.M.",
              id: 522,
            },
            {
              text: "6단원 본문2",
              id: 523,
            },
            {
              text: "6단원 R.M.",
              id: 524,
            },
          ],
        },
        {
          text: "천재(안병규)",
          title: "대단원",
          type: "check",
          list: [
            {
              text: "1단원 본문1",
              id: 305,
            },
            {
              text: "2단원 본문1",
              id: 306,
            },
            {
              text: "3단원 본문1",
              id: 307,
            },
            {
              text: "4단원 본문1",
              id: 308,
            },
            {
              text: "5단원 본문1",
              id: 309,
            },
            {
              text: "6단원 본문1",
              id: 310,
            },
            {
              text: "1단원 본문2",
              id: 525,
            },
            {
              text: "2단원 본문2",
              id: 526,
            },
            {
              text: "2단원 S.R.",
              id: 527,
            },
            {
              text: "3단원 본문2",
              id: 528,
            },
            {
              text: "4단원 본문2",
              id: 529,
            },
            {
              text: "4단원 S.D.",
              id: 530,
            },
            {
              text: "5단원 본문2",
              id: 531,
            },
            {
              text: "6단원 본문2",
              id: 532,
            },
            {
              text: "6단원 S.J.",
              id: 533,
            },
          ],
        },
      ],
    },
  ],
};

export default englishData;
