import React, { useEffect, useState } from "react";
import {
  Col,
  Pre1,
  Pre2,
  Pre4,
  Row,
  WhiteSpace,
} from "../../style/GlobalStyled";
import theme from "../../style/theme";
import CustomChip from "../Atoms/CustomChip";
import { CustomButton } from "../Atoms/CustomButton";
import numberWithCommas from "../../function/numberWithCommas";

const TabletPurchase = ({
  studentDurationList,
  teacherDurationList,
  userType,
  duration,
  setDuration,
  purchaseObj,
  onPurchase,
  mobile,
}) => {
  const [size, setSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setSize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <Col style={{ paddingBottom: 40, padding: mobile ? 0 : "0px 40px 24px" }}>
      {purchaseObj[userType][duration].image(mobile ? size : 520)}

      <WhiteSpace height={24} />
      <Col
        style={{
          alignItems: "flex-start",
          padding: mobile ? "0 20px" : "20px",
          border: mobile ? 0 : `1px solid ${theme.Gray3}`,
          borderRadius: 12,
          marginBottom: 32,
        }}
      >
        <Pre1>CheryExam 슈퍼패스</Pre1>
        <Col
          style={{
            backgroundColor: theme.Gray1,
            padding: "16px",
            borderRadius: 12,
            alignItems: "flex-start",
            margin: "20px 0 0",
          }}
        >
          <Row style={{ width: "auto" }}>
            <Pre4
              fontWeight="Regular"
              color={theme.Gray6}
              style={{
                flex: 1,
                minWidth: 82,
                marginRight: 12,
              }}
            >
              기간
            </Pre4>
            <Row
              style={{
                width: "auto",
                flexWrap: "wrap",
                justifyContent: "flex-start",
                gap: 8,
              }}
            >
              {(userType === "학생용"
                ? studentDurationList
                : teacherDurationList
              ).map((v, i) => {
                return (
                  <CustomChip
                    key={`date_${i}`}
                    type="Tertiary"
                    title={v.label}
                    size="S"
                    style={{
                      // flex: 1,
                      borderRadius: 8,
                    }}
                    onClick={() => {
                      setDuration(v.value);
                    }}
                    isSelected={v.value === duration}
                  />
                );
              })}
            </Row>
          </Row>
          <Row style={{ width: "auto", margin: "16px 0" }}>
            <Pre4
              fontWeight="Regular"
              color={theme.Gray6}
              style={{ flex: 1, minWidth: 82, marginRight: 12 }}
            >
              다운로드 건수
            </Pre4>
            <Pre4 fontWeight="Regular">
              {purchaseObj[userType][duration].amount.value + "건"}
            </Pre4>
          </Row>
          <Row style={{ width: "auto" }}>
            <Pre4
              fontWeight="Regular"
              color={theme.Gray6}
              style={{ flex: 1, minWidth: 82, marginRight: 12 }}
            >
              가격
            </Pre4>
            <Pre4 fontWeight="Regular">
              {`${numberWithCommas(
                purchaseObj[userType][duration].price.value
              )}원 (1건 당 ${Math.floor(
                purchaseObj[userType][duration].price.value /
                  purchaseObj[userType][duration].amount.value
              )}원) `}
            </Pre4>
          </Row>
        </Col>
        {/* <Row>
          <Pre4
            color={theme.Gray6}
            fontWeight="Regular"
            style={{ width: 82, marginRight: 12 }}
          >
            할인쿠폰
          </Pre4>
          <Row
            style={{
              justifyContent: "space-between",
              width: "100%",
              padding: "16px",
              cursor: "pointer",
              border: `1px solid ${theme.Gray3}`,
              borderRadius: 8,
            }}
          >
            <Pre4 fontWeight="Regular" color={theme.Gray5}>
              등록된 쿠폰이 없습니다.
            </Pre4>
            <SelectTriangle />
          </Row>
        </Row> */}
        <span
          style={{
            height: "1px",
            width: "100%",
            backgroundColor: theme.Gray2,
            margin: "20px 0",
          }}
        />
        <Row style={{ justifyContent: "space-between" }}>
          <Pre4
            color={theme.Gray6}
            fontWeight="Regular"
            style={{ width: 82, marginRight: 12 }}
          >
            최종 결제 금액
          </Pre4>
          <Pre2>
            {purchaseObj[userType][duration].price.value
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            원
          </Pre2>
        </Row>
      </Col>
      <Col
        style={{
          padding: mobile ? "0px 20px" : 0,
          marginBottom: mobile ? 16 : 0,
        }}
      >
        <CustomButton
          title="결제하기"
          btnType="Primary"
          size="Full"
          onClick={onPurchase}
        />
      </Col>
    </Col>
  );
};

export default TabletPurchase;
