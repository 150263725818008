import { useState, useEffect } from "react";

export function useResponsive() {
  const mediaQueryTablet = window.matchMedia(
    "(min-width: 601px) and (max-width: 1079px)"
  );
  const mediaQueryMobile = window.matchMedia("(max-width: 600px)");

  const [isTablet, setIsTablet] = useState(mediaQueryTablet.matches);
  const [isMobile, setIsMobile] = useState(mediaQueryMobile.matches);
  const isDeskTop = !isTablet && !isMobile;
  const HORIZONTAL_MARGIN = isTablet ? 40 : isMobile ? 20 : 0;

  useEffect(() => {
    const handleResizeTablet = (event: MediaQueryListEvent) => {
      setIsTablet(event.matches);
    };
    const handleResizeMobile = (event: MediaQueryListEvent) => {
      setIsMobile(event.matches);
    };

    mediaQueryTablet.addEventListener("change", handleResizeTablet);
    mediaQueryMobile.addEventListener("change", handleResizeMobile);

    return () => {
      mediaQueryTablet.removeEventListener("change", handleResizeTablet);
      mediaQueryMobile.removeEventListener("change", handleResizeMobile);
    };
  }, []);

  return { isTablet, isMobile, HORIZONTAL_MARGIN, isDeskTop };
}
