import { debounce } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import WriteInformationPage from "../components/Atoms/WriteInformationPage";
import useCheryExamAPI from "../hooks/useCheryExamAPI";
import useEnglishAPI from "../hooks/useEnglishAPI";
import { Row } from "../style/GlobalStyled";

export interface IValue {
  nickname: string;
  grade: string;
  accountRoute: string;
  school: string;
  schoolCode: string | number;
  code: string;
}

const CreatedAccountPage = () => {
  const [API] = useMemo(useEnglishAPI, []);
  const [WEB_API] = useMemo(useCheryExamAPI, []);
  const location = useLocation();
  const user_code = location?.state?.code;

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [codeLoading, setCodeLoading] = useState(false);
  const [valid, setValid] = useState(false);
  const [codeValid, setCodeValid] = useState(false);
  const [recommend, setRecommend] = useState<string[]>([]);

  const [value, setValue] = useState<IValue>({
    nickname: "",
    grade: "",
    accountRoute: "",
    school: "",
    schoolCode: "",
    code: "",
  });

  const onChangeInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setValue((prev) => ({ ...prev, [name]: value }));
    if (name === "nickname" && value.length < 3) {
      setValid(false);
    } else if (name === "nickname" && value.length > 3) {
      setLoading(true);
      debounceHandler(name, value);
    }

    if (name === "code" && value.length !== 6) {
      setCodeValid(false);
    } else if (name === "code" && value.length === 6) {
      setCodeLoading(true);
      debounceCodeCheckHandler(name, value);
    }
  };

  const onClickNicknameHandler = (v: string) => {
    setValue((prev) => ({ ...prev, nickname: v }));
    setValid(true);
  };

  const onClickGradeHandler = (v: string) => {
    setValue((prev) => ({ ...prev, grade: v, school: "", schoolCode: "" }));
  };

  const onClickAccountRouteHandler = (v: string) => {
    setValue((prev) => ({ ...prev, accountRoute: v }));
  };

  const onClickComplete = async () => {
    const { nickname, grade, accountRoute, school, schoolCode } = value;
    try {
      const params = {
        body: {
          nickname,
          grade,
          path: accountRoute,
          school,
          schoolCode,
          user_code,
        },
      };

      console.log(params);

      const {
        data: { token },
      } = await WEB_API.login.setWebInfos(params);

      localStorage.setItem("token", token);
      const pathname = sessionStorage.getItem("pathname");
      if (pathname) {
        navigate(pathname);
      }
    } catch (e) {
      console.log("created_account error", e);
    }
  };

  const nicknameCheck = async (nickname: string) => {
    try {
      const params = {
        nickname,
      };
      const { data } = await API.info.checkNickname(params);
      if (data.success) {
        setValid(true);
        setLoading(false);
      } else {
        setValid(false);
        setLoading(false);
      }
    } catch (err) {
      console.log(err, "nicknameCheck");
    }
  };

  const codeCheck = async (code: string) => {
    try {
      const params = {
        body: {
          code,
          user_code,
          is_check: 1,
        },
      };
      const {
        data: { data },
      } = await API.friend.postFriendAdd(params);

      if (data.result === "success") {
        setCodeLoading(false);
        setCodeValid(true);
      } else {
        setCodeLoading(false);
        setCodeValid(false);
      }
    } catch (err) {
      console.log(err, "codeCheck");
    }
  };

  const getRequestNickname = async () => {
    if (recommend.length === 0) {
      try {
        const { data } = await API.auth.requestNickname();
        data.nicknameArr && setRecommend(data.nicknameArr);
      } catch (e) {
        console.log(e, "requestNickname");
      }
    }
  };

  useEffect(() => {
    getRequestNickname();
  }, []);

  useEffect(() => {
    if (!user_code) {
      navigate("/*");
    }
  }, [user_code, navigate]);

  const debounceHandler = useCallback(
    debounce((name, value) => {
      if (name === "nickname" && value.length > 1) {
        nicknameCheck(value);
      }
    }, 700),
    []
  );
  const debounceCodeCheckHandler = useCallback(
    debounce((name, value) => {
      if (name === "code" && value.length === 6) {
        codeCheck(value);
      }
    }, 700),
    []
  );

  return (
    <Row>
      <WriteInformationPage
        value={value}
        setValue={setValue}
        recommend={recommend}
        valid={valid}
        codeValid={codeValid}
        loading={loading}
        codeLoading={codeLoading}
        onChangeInputValue={onChangeInputValue}
        onClickNicknameHandler={onClickNicknameHandler}
        onClickGradeHandler={onClickGradeHandler}
        onClickAccountRouteHandler={onClickAccountRouteHandler}
        onClickComplete={onClickComplete}
      />
    </Row>
  );
};

export default CreatedAccountPage;
