import React from "react";
import axios from "axios";
import LoginButton from "./LoginButton";
import AppleLogo from "../../images/svg/AppleLogo";
import theme from "../../style/theme";

// const AppleLogin = () => {
//   return (
//     <div>
//       <AppleSignin
//         authOptions={{
//           /** Client ID - eg: 'com.example.com' */
//           clientId: "com.mise.cheryexam",
//           /** Requested scopes, seperated by spaces - eg: 'email name' */
//           scope: "email name",
//           /** Apple's redirectURI - must be one of the URIs you added to the serviceID - the undocumented trick in apple docs is that you should call auth from a page that is listed as a redirectURI, localhost fails */
//           redirectURI: "https://cheryexam.com/login/api/oauth/callback/apple",
//           /** State string that is returned with the apple response */
//           /** Nonce */
//           /** Uses popup auth instead of redirection */
//           usePopup: false,
//         }}
//         onSuccess={(response) => console.log(response)} // default = undefined
//         /** Called upon signin error */
//         onError={(error) => console.log(error)} // default = undefined
//         render={({ onClick }) => (
//           <LoginButton
//             onClick={onClick}
//             title={"Apple로 계속하기"}
//             bgColor={"#000"}
//             color={"#FFF"}
//             logo={<AppleLogo />}
//           />
//         )}
//       />
//     </div>
//   );
// };
const AppleLogin = () => {
  const APPLE_CLIENT_ID = process.env.REACT_APP_APPLE_CLIENT_ID;
  const APPLE_REDIRECT_URL = process.env.REACT_APP_APPLE_REDIRECT_URL;
  const STATE = "test";
  const APPLE_AUTH_URL = `https://appleid.apple.com/auth/authorize?client_id=${APPLE_CLIENT_ID}&redirect_uri=${APPLE_REDIRECT_URL}&response_type=code%20id_token&state=${STATE}&response_mode=fragment`;

  const handleNaverLogin = async () => {
    // 클릭시 요청 URL로 이동
    window.location.href = APPLE_AUTH_URL;
    try {
      const data = await axios.get(APPLE_AUTH_URL);
      console.log(data);
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <LoginButton
      logo={<AppleLogo />}
      title={"Apple로 계속하기"}
      bgColor={"#000"}
      color={theme.White}
      onClick={handleNaverLogin}
    />
  );
};

export default AppleLogin;
