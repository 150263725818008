import { useQuery } from "react-query";
import cheryExamAPI from "../hooks/useCheryExamAPI";
import { translateSubject } from "../function/tranlateSubject";

const [API] = cheryExamAPI();

const useCheryExamList = ({
  section,
  subject,
  publisher_author,
  category,
  meta_id_list,
  school_type,
}: {
  section: string;
  subject: string;
  publisher_author: string;
  category: string;
  meta_id_list: number[];
  school_type: string;
}) => {
  return useQuery({
    queryKey: [
      "cheryExamList",
      section,
      subject,
      category,
      publisher_author,
      String(meta_id_list),
      school_type,
    ],
    queryFn: async () => {
      try {
        const params = {
          body: {
            section,
            publisher_author,
            subject: translateSubject(subject),
            category,
            meta_id_list,
            school_type,
          },
        };
        const { data } = await API.list.getCheryExamList(params);
        return {
          ...data,
          title:
            section + (publisher_author === "" ? "" : ` - ${publisher_author}`),
          isPlaceholder: false,
        };
      } catch (e) {
        console.log(e, "getCheryExamList");
        return { list: [], idList: [], title: "", isPlaceholder: true };
      }
    },
    placeholderData: { list: [], idList: [], title: "", isPlaceholder: true },
  });
};

const useZocboList = ({
  subject,
  type,
  school_code,
  region,
  gu,
  category,
  sectionList,
  year,
  semester,
  pageIndex,
}: {
  subject: string;
  type: string;
  school_code: string;
  region: string;
  gu: string;
  category: string;
  sectionList: string[];
  year: number[];
  semester: string[];
  pageIndex: number;
}) => {
  return useQuery({
    queryKey: [
      "zocboList",
      subject,
      type,
      school_code,
      region,
      gu,
      category,
      sectionList,
      year,
      semester,
      pageIndex,
    ],
    queryFn: async () => {
      try {
        const params = {
          body: {
            subject: translateSubject(subject),
            type,
            school_code,
            region,
            gu,
            category,
            sectionList,
            year,
            semester,
            pageIndex,
          },
        };
        const { data } = await API.list.getZocboList_v2(params);
        return { ...data, title: "", isPlaceholder: false };
      } catch (e) {
        console.log(e, "getZocboList");
        return { list: [], idList: [], title: "", isPlaceholder: true };
      }
    },
    placeholderData: { list: [], idList: [], title: "", isPlaceholder: true },
  });
};

export { useCheryExamList, useZocboList };
