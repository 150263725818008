import { useEffect, useMemo, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { categoryState, headerOpenState, subjectState } from "./atoms/atoms";
import {
  DesktopHeader,
  DesktopLogoHeader,
} from "./components/Header/DesktopHeader";
import MobileHeader from "./components/Header/MobileHeader";
import TabletHeader from "./components/Header/TabletHeader";
import useCheryExamAPI from "./hooks/useCheryExamAPI";
import useToken from "./hooks/useToken";
import theme from "./style/theme";
import { useResponsive } from "./utils/responsive";
import Footer from "./Footer";

// ["영어", "국어", "수학", "사회", "과학", "역사"]
const subjectList = [
  { title: "영어", value: "english" },
  { title: "국어", value: "korean" },
  { title: "수학", value: "math" },
  { title: "사회", value: "society" },
  { title: "과학", value: "science" },
  { title: "역사", value: "history" },
];

const Layout = () => {
  const { isTablet, isMobile, isDeskTop } = useResponsive();
  const { data: session } = useToken();
  const [CHERY_EXAM_API] = useMemo(useCheryExamAPI, []);
  const navigate = useNavigate();
  const location = useLocation();
  const school = location.pathname.split("/")[1];
  const [subject, setSubject] = useState("english");
  const setRecoilSubject = useRecoilState(subjectState)[1];

  const [category] = useRecoilState(categoryState);
  const [recoilHeaderOpen, setRecoilHeaderOpen] =
    useRecoilState(headerOpenState);

  const { pathname } = useLocation();

  const topMenu = [
    {
      title: "이용권 구매",
      path: "/purchase",
      onClick: () => {
        navigate("/purchase");
        setRecoilHeaderOpen(false);
      },
    },
    {
      title: "고객센터",
      path: "/customer",
      onClick: () => {
        window.open("http://pf.kakao.com/_LxhGcK/chat", "_blank");
      },
    },
    {
      title: "마이페이지",
      path: "/myPage",
      onClick: () => {
        navigate("/myPage");
        setRecoilHeaderOpen(false);
      },
    },
    session?.token?.accessToken
      ? {
          title: "로그아웃",
          onClick: async () => {
            try {
              // alert(session?.token?.accessToken)
              await CHERY_EXAM_API.login.clearCheryExamWebToken({
                body: { token: session?.token?.accessToken },
              });
              if (typeof window !== "undefined") {
                localStorage.setItem("token", "");
              }
              window.location.reload(); // 새로고침
              // signOut({ callbackUrl: "/studentRecord/list" });
            } catch (e) {
              alert("E");
            } finally {
            }
          },
        }
      : {
          title: "로그인",
          path: "/login",
          onClick: () => {
            if (pathname !== "/login") {
              sessionStorage.setItem("pathname", pathname);
              sessionStorage.setItem("category", category);
              sessionStorage.setItem("subject", subject);
            }
            navigate("/login");
          },
        },
  ];

  const categoryList = [
    {
      title: "학교기출",
      path: `SchoolPage`,
      list: ["우리학교기출", "강남3구기출"],
    },
    {
      title: "개념완성",
      path: `ConceptPage`,
      list: tempObj[subject],
    },
    {
      title: "실전대비",
      path: `PracticalPage`,
      list: ["대표유형", "실력별학습"],
    },
    {
      title: "약점공략",
      path: `WeaknessPage`,
      list: ["서술형대비", "최다빈출모음", "최다오답모음"],
    },
    {
      title: "최종점검",
      path: `FinalPage`,
      list: ["파이널대비"],
    },
  ];
  useEffect(() => {
    if (location.pathname === "/") {
      navigate("/high/SchoolPage");
    }
  }, [navigate, location]);

  const isCategory = !(
    pathname === "/login" || pathname === "/login/created_account"
  );

  return (
    <div
      style={{
        backgroundColor: theme.White,
        position: "relative",
        justifyContent: "flex-start",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {isMobile && (
        <MobileHeader
          school={school}
          topMenu={topMenu}
          categoryList={categoryList}
          subjectList={subjectList}
          subject={subject}
          setSubject={setSubject}
          mobile={isMobile}
        />
      )}
      {isTablet && (
        <TabletHeader
          school={school}
          topMenu={topMenu}
          categoryList={categoryList}
          subjectList={subjectList}
          subject={subject}
          setSubject={setSubject}
          mobile={isMobile}
        />
      )}
      {isDeskTop && (
        <DesktopLogoHeader
          school={school}
          topMenu={topMenu}
          categoryList={categoryList}
          subjectList={subjectList}
          subject={subject}
          setSubject={setSubject}
          setRecoilSubject={setRecoilSubject}
        />
      )}
      {isDeskTop && isCategory && (
        <DesktopHeader
          school={school}
          topMenu={topMenu}
          categoryList={categoryList}
          subjectList={subjectList}
          subject={subject}
          setSubject={setSubject}
        />
      )}
      <main
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          position: "relative",
          paddingTop: isDeskTop ? 40 : isMobile ? 8 : 24,
        }}
      >
        <div
          style={{
            flex: 1,
            overflow: "hidden",
            width: "100%",
            zIndex: 1,
          }}
        >
          <Outlet />
        </div>
        <Footer />
      </main>
    </div>
  );
};

export default Layout;

const tempObj = {
  english: ["단어장", "본문분석", "빈칸채우기", "해석연습", "본문암기"],
  korean: ["작품분석", "빈칸채우기", "OX퀴즈"],
  math: ["개념서", "빈칸채우기", "기본예제"],
  society: ["개념서", "빈칸채우기", "OX퀴즈"],
  science: ["개념서", "빈칸채우기", "OX퀴즈"],
  history: ["개념서", "빈칸채우기", "OX퀴즈"],
};
