import React from "react";
import { Navigate } from "react-router-dom";

export default function ProtectedRoute({
  children,
}: {
  children: React.ReactNode;
}) {
  const user = localStorage.getItem("token");

  if (Boolean(user)) {
    return <Navigate to="/" replace />;
  }

  return children;
}
