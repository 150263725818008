import React, { useMemo, useRef, useState } from "react";
import styled from "styled-components";
import useCheryExamAPI from "../../hooks/useCheryExamAPI";
import Check from "../../images/svg/Check";
import Headset from "../../images/svg/Headset";
import Xicon from "../../images/svg/Xicon";
import { Col, Pre2, Pre3, Pre5, Row } from "../../style/GlobalStyled";
import theme from "../../style/theme";
import { useResponsive } from "../../utils/responsive";
import { Checkbox } from "../Atoms/Checkbox";
import { CustomButton } from "../Atoms/CustomButton";
import CustomLoading from "../Atoms/CustomLoading";

const AcademyForm = ({
  setIsOpen,
  isOpen,
}: {
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  isOpen?: boolean;
}) => {
  const { isDeskTop, HORIZONTAL_MARGIN, isMobile, isTablet } = useResponsive();
  const [CHERY_EXAM_API] = useMemo(useCheryExamAPI, []);
  const loadingRef = useRef(null);
  const [form, setForm] = useState({
    name: "",
    academyName: "",
    phoneNum: "",
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const isFormValid =
    Object.values(form).every((value) => value !== "") && isChecked;

  const updateForm = (key, value) => {
    setForm((prev) => ({ ...prev, [key]: value }));
  };
  const makeCheryExamConsultingRequestHandler = async () => {
    try {
      loadingRef.current?.show();
      const params = {
        body: {
          name: form.name,
          academy_name: form.academyName,
          phone_num: form.phoneNum,
        },
      };
      await CHERY_EXAM_API.consulting.makeCheryExamConsultingRequest(params);
      setIsSubmitted(true);
    } catch (e) {
      console.log(e, "makeCheryExamConsultingRequestHandler");
    } finally {
      loadingRef.current?.hide();
      setForm({
        name: "",
        academyName: "",
        phoneNum: "",
      });
      setIsChecked(false);
    }
  };
  if (isDeskTop) {
    return (
      <Col
        style={{
          height: "auto",
          borderRadius: 12,
          padding: "18px 20px 20px 20px",
          backgroundColor: theme.Blue1,
          marginTop: 20,
          alignItems: "flex-start",
          position: "relative",
        }}
      >
        <Row
          style={{
            height: "auto",
            gap: 8,
            justifyContent: "flex-start",
            marginBottom: 18,
          }}
        >
          <Headset />
          <Pre2>도입문의</Pre2>
        </Row>
        {isSubmitted ? (
          <Col style={{ height: "auto", alignItems: "flex-start" }}>
            <Row
              style={{
                height: "auto",
                gap: 8,
                justifyContent: "flex-start",
                marginBottom: 20,
              }}
            >
              <Pre3 color={theme.Blue10} fontWeight="Regular">
                신청이 완료되었습니다
              </Pre3>
              <Check size={20} fill={theme.Blue10} />
            </Row>
            <CustomButton
              title="돌아가기"
              btnType="Secondary"
              size="S"
              onClick={() => {
                setIsSubmitted(false);
              }}
            />
          </Col>
        ) : (
          <>
            {[
              {
                title: "성함",
                placeholder: "성함을 입력해주세요.",
                key: "name",
              },
              {
                title: "학원명",
                placeholder: "학원명을 입력해주세요.",
                key: "academyName",
              },
              {
                title: "전화번호",
                placeholder: "전화번호를 입력해주세요.",
                key: "phoneNum",
              },
            ].map((v, vIdx) => {
              return (
                <Col
                  style={{
                    height: "auto",
                    alignItems: "flex-start",
                    marginBottom: 20,
                  }}
                  key={`input_${vIdx}`}
                >
                  <Pre5
                    fontWeight="Regular"
                    color={theme.Gray6}
                    style={{ marginBottom: 8 }}
                  >
                    {v.title}
                  </Pre5>
                  <Input
                    placeholder={v.placeholder}
                    value={form[v.key]}
                    onChange={(e) => updateForm(v.key, e.target.value)}
                  />
                </Col>
              );
            })}
            <Row
              style={{
                height: "auto",
                justifyContent: "flex-start",
                gap: 8,
                marginBottom: 20,
              }}
            >
              <Checkbox
                width={20}
                height={20}
                id={"checkbox"}
                onChange={() => setIsChecked((prev) => !prev)}
                checked={isChecked}
              />
              <label htmlFor="checkbox" style={{ cursor: "pointer" }}>
                <Pre5 fontWeight="Regular" color={theme.Gray8}>
                  개인정보 수집 이용 동의
                </Pre5>
              </label>
            </Row>
            <CustomButton
              title="신청하기"
              btnType="Primary"
              size="Full"
              disabled={!isFormValid}
              onClick={makeCheryExamConsultingRequestHandler}
            />
          </>
        )}
        <CustomLoading
          ref={loadingRef}
          size={40}
          init={false}
          backgroundColor={"rgba(255,255,255,0.5)"}
          style={{ position: "absolute", top: 0, left: 0, borderRadius: 12 }}
        />
      </Col>
    );
  } else if (isTablet) {
    return (
      <Col
        style={{
          height: "auto",
          alignItems: "flex-start",
          borderRadius: 12,
          padding: "18px 20px",
          backgroundColor: theme.Blue1,
          boxShadow: "0px 2px 10px 0px rgba(0, 89, 232, 0.25)",
          width: isOpen ? "100%" : "auto",
          cursor: isOpen ? "auto" : "pointer",
        }}
        onClick={isOpen ? undefined : () => setIsOpen((prev) => !prev)}
      >
        <Row
          style={{
            justifyContent: "space-between",
            marginBottom: isOpen ? 16 : 0,
          }}
        >
          <Row style={{ justifyContent: "flex-start", gap: 8 }}>
            <Headset />
            <Pre2>도입문의</Pre2>
          </Row>
          {isOpen && (
            <Col
              style={{ width: "auto", height: "auto", cursor: "pointer" }}
              onClick={() => {
                setIsOpen((prev) => !prev);
                setIsSubmitted(false);
              }}
            >
              <Xicon color={theme.Gray6} />
            </Col>
          )}
        </Row>
        {isOpen && (
          <>
            {isSubmitted ? (
              <Row style={{ height: "auto", justifyContent: "space-between" }}>
                <Row
                  style={{
                    height: "auto",
                    width: "auto",
                    gap: 8,
                  }}
                >
                  <Pre3 color={theme.Blue10} fontWeight="Regular">
                    신청이 완료되었습니다
                  </Pre3>
                  <Check size={20} fill={theme.Blue10} />
                </Row>
                <CustomButton
                  title="돌아가기"
                  btnType="Secondary"
                  size="S"
                  onClick={() => {
                    setIsSubmitted(false);
                  }}
                />
              </Row>
            ) : (
              <>
                <Row style={{ height: "auto", gap: 20 }}>
                  {[
                    {
                      title: "성함",
                      placeholder: "성함을 입력해주세요.",
                      key: "name",
                    },
                    {
                      title: "학원명",
                      placeholder: "학원명을 입력해주세요.",
                      key: "academyName",
                    },
                    {
                      title: "전화번호",
                      placeholder: "전화번호를 입력해주세요.",
                      key: "phoneNum",
                    },
                  ].map((v, vIdx) => {
                    return (
                      <Col
                        style={{
                          height: "auto",
                          alignItems: "flex-start",
                          marginBottom: 20,
                        }}
                        key={`input_${vIdx}`}
                      >
                        <Pre5
                          fontWeight="Regular"
                          color={theme.Gray6}
                          style={{ marginBottom: 8 }}
                        >
                          {v.title}
                        </Pre5>
                        <Input
                          placeholder={v.placeholder}
                          value={form[v.key]}
                          onChange={(e) => updateForm(v.key, e.target.value)}
                        />
                      </Col>
                    );
                  })}
                </Row>
                <Row
                  style={{
                    height: "auto",
                    justifyContent: "space-between",
                  }}
                >
                  <Row
                    style={{
                      height: "auto",
                      width: "auto",
                      justifyContent: "flex-start",
                      gap: 8,
                      cursor: "pointer",
                    }}
                  >
                    <Checkbox
                      width={20}
                      height={20}
                      id={"checkbox"}
                      onChange={() => setIsChecked((prev) => !prev)}
                      checked={isChecked}
                    />
                    <label htmlFor="checkbox" style={{ cursor: "pointer" }}>
                      <Pre5 fontWeight="Regular" color={theme.Gray8}>
                        개인정보 수집 이용 동의
                      </Pre5>
                    </label>
                  </Row>
                  <CustomButton
                    title="신청하기"
                    btnType="Primary"
                    size="M"
                    disabled={!isFormValid}
                    onClick={makeCheryExamConsultingRequestHandler}
                  />
                </Row>
              </>
            )}
          </>
        )}
        <CustomLoading
          ref={loadingRef}
          size={40}
          init={false}
          backgroundColor={"rgba(255,255,255,0.5)"}
          style={{ position: "absolute", top: 0, left: 0, borderRadius: 12 }}
        />
      </Col>
    );
  } else if (isMobile) {
    return (
      <Col
        style={{
          height: "auto",
          alignItems: "flex-start",
          borderTopLeftRadius: isOpen ? 20 : 12,
          borderTopRightRadius: isOpen ? 20 : 12,
          borderBottomLeftRadius: isOpen ? 0 : 12,
          borderBottomRightRadius: isOpen ? 0 : 12,
          padding: "16px 20px",
          backgroundColor: theme.Blue1,
          boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.25)",
          width: isOpen ? "100%" : "auto",
          cursor: isOpen ? "auto" : "pointer",
        }}
        onClick={
          isOpen
            ? undefined
            : () => {
                setIsOpen((prev) => !prev);
              }
        }
      >
        <Row
          style={{
            justifyContent: "space-between",
            marginBottom: isOpen ? 16 : isSubmitted ? 24 : 0,
          }}
        >
          <Row style={{ justifyContent: "flex-start", gap: 8 }}>
            <Headset />
            <Pre3>도입문의</Pre3>
          </Row>
          {isOpen && (
            <Col
              style={{ width: "auto", height: "auto", cursor: "pointer" }}
              onClick={() => {
                setIsOpen((prev) => !prev);
                setIsSubmitted(false);
              }}
            >
              <Xicon color={theme.Gray6} />
            </Col>
          )}
        </Row>
        {isOpen && (
          <>
            {isSubmitted ? (
              <Col style={{ height: "auto", alignItems: "flex-start" }}>
                <Row
                  style={{
                    height: "auto",
                    gap: 8,
                    marginBottom: 24,
                  }}
                >
                  <Pre3 color={theme.Blue10} fontWeight="Regular">
                    신청이 완료되었습니다
                  </Pre3>
                  <Check size={20} fill={theme.Blue10} />
                </Row>
                <CustomButton
                  title="돌아가기"
                  btnType="Secondary"
                  size="Full"
                  onClick={() => {
                    setIsSubmitted(false);
                  }}
                />
              </Col>
            ) : (
              <>
                {[
                  {
                    title: "성함",
                    placeholder: "성함을 입력해주세요.",
                    key: "name",
                  },
                  {
                    title: "학원명",
                    placeholder: "학원명을 입력해주세요.",
                    key: "academyName",
                  },
                  {
                    title: "전화번호",
                    placeholder: "전화번호를 입력해주세요.",
                    key: "phoneNum",
                  },
                ].map((v, vIdx) => {
                  return (
                    <Col
                      style={{
                        height: "auto",
                        alignItems: "flex-start",
                        marginBottom: vIdx === 2 ? 16 : 20,
                      }}
                      key={`input_${vIdx}`}
                    >
                      <Pre5
                        fontWeight="Regular"
                        color={theme.Gray6}
                        style={{ marginBottom: 8 }}
                      >
                        {v.title}
                      </Pre5>
                      <Input
                        placeholder={v.placeholder}
                        value={form[v.key]}
                        onChange={(e) => updateForm(v.key, e.target.value)}
                      />
                    </Col>
                  );
                })}
                <Row
                  style={{
                    height: "auto",
                    justifyContent: "flex-start",
                    gap: 8,
                    marginBottom: 16,
                  }}
                >
                  <Checkbox
                    width={20}
                    height={20}
                    id={"checkbox"}
                    onChange={() => setIsChecked((prev) => !prev)}
                    checked={isChecked}
                  />
                  <label htmlFor="checkbox" style={{ cursor: "pointer" }}>
                    <Pre5 fontWeight="Regular" color={theme.Gray8}>
                      개인정보 수집 이용 동의
                    </Pre5>
                  </label>
                </Row>
                <CustomButton
                  title="신청하기"
                  btnType="Primary"
                  size="Full"
                  disabled={!isFormValid}
                  onClick={makeCheryExamConsultingRequestHandler}
                />
              </>
            )}
          </>
        )}
        <CustomLoading
          ref={loadingRef}
          size={40}
          init={false}
          backgroundColor={"rgba(255,255,255,0.5)"}
          style={{ position: "absolute", top: 0, left: 0, borderRadius: 12 }}
        />
      </Col>
    );
  }
};

export default AcademyForm;

const Input = styled.input`
  width: 100%;
  border-radius: 8px;
  font-size: 14px;
  border: 1px solid ${theme.Gray3};
  padding: 14px;
  &::placeholder {
    color: ${theme.Gray5};
  }
`;
