import React from "react";
import { Col, Pre4, Row } from "../../style/GlobalStyled";
import theme from "../../style/theme";
import Check from "../../images/svg/Check";
import { bottomUpModalStyle } from "../../style/GlobalStyle";
import ModalHeader from "../Molecules/ModalHeader";

const ListModal = ({
  propsData,
  selectedFilter,
  setSelectedFilter,
  offModal,
}: {
  propsData?: {
    list: any[];
    idx: number;
  };
  selectedFilter: SelectedFilterType;
  setSelectedFilter: React.Dispatch<React.SetStateAction<SelectedFilterType>>;
  offModal: () => void;
}) => {
  return (
    <Col
      style={{
        ...bottomUpModalStyle,
      }}
      id="invisible-scrollbar"
    >
      <ModalHeader title={"대단원"} offModal={offModal} />
      <Col
        style={{
          height: "auto",
          overflowY: "scroll",
          justifyContent: "flex-start",
        }}
        id={"invisible-scrollbar"}
      >
        {propsData.list.map((j, jIndex) => {
          const isSelected =
            (selectedFilter[propsData.idx] as number[])?.includes?.(j.id) ||
            false;
          return (
            <Row
              key={`listItem_${jIndex}`}
              style={{
                padding: "16px 20px",
                width: "100%",
                borderBottom: `1px solid ${theme.Gray2}`,
                borderTop: jIndex === 0 ? `1px solid ${theme.Gray2}` : 0,
                cursor: "pointer",
                backgroundColor: isSelected ? theme.Blue1 : theme.White,
                justifyContent: "space-between",
                height: "46px",
              }}
              onClick={() => {
                if (isSelected) {
                  setSelectedFilter((prev) => {
                    const temp = [...prev];
                    temp[propsData.idx] = (
                      temp[propsData.idx] as number[]
                    ).filter((f) => f !== j.id);
                    return temp;
                  });
                } else {
                  setSelectedFilter((prev) => {
                    const temp = [...prev];
                    temp[propsData.idx] = [
                      ...(temp[propsData.idx] as number[]),
                      j.id,
                    ];
                    return temp;
                  });
                }
              }}
            >
              <Pre4 fontWeight="Regular">{`${jIndex + 1}. ${j.text}`}</Pre4>
              {isSelected && <Check size={20} fill={theme.Blue11} />}
            </Row>
          );
        })}
      </Col>
    </Col>
  );
};

export default ListModal;
