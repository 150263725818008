const getToken = () => {
  if (typeof window !== "undefined") {
    const { localStorage } = window;
    const token = localStorage.getItem("token");

    if (token) {
      return { token: { accessToken: token } };
    } else {
      throw new Error("Token not found");
    }
  }
};

export default getToken;
