import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import shortSchoolName from "../../function/shortSchoolName";
import Arrow20 from "../../images/svg/Arrow20";
import {
  Col,
  Pre1,
  Pre2,
  Pre3,
  Pre4,
  Pre5,
  Row,
  WhiteSpace,
} from "../../style/GlobalStyled";
import theme from "../../style/theme";
import CustomAlertModal from "../Atoms/CustomAlertModal";
import CustomModal from "../Atoms/CustomModal";
import Overlay from "./Overlay";

const DesktopMyPage = ({ userInfo }) => {
  const navigate = useNavigate();
  const modalRef = useRef({});

  // const onCustomModal = (name: string) => {
  //   modalRef.current?.[name]?.show?.();
  // };

  const offCustomModal = (name: string) => {
    modalRef.current?.[name]?.hide?.();
  };
  return (
    <Col>
      <Col
        style={{
          width: 960,
          alignItems: "flex-start",
          marginBottom: 40,
        }}
      >
        <Pre1 style={{ fontSize: "32px", marginBottom: 24 }}>마이페이지</Pre1>
        <Row
          style={{
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: 24,
          }}
        >
          <Col
            style={{
              alignItems: "flex-start",
              flex: 1,
            }}
          >
            <Col
              style={{
                border: `1px solid ${theme.Gray3}`,
                borderRadius: 12,
                padding: "20px 0px",
                marginBottom: 24,
              }}
            >
              <div
                style={{
                  borderRadius: "50%",
                  overflow: "hidden",
                  lineHeight: 0,
                  border: `1px solid ${theme.Gray3}`,
                  width: 106,
                  height: 106,
                  marginBottom: 24,
                }}
              >
                <img
                  src={
                    userInfo.imgUrl ||
                    "https://kr.object.ncloudstorage.com/userprofile-image/cheram_default.jpg"
                  }
                  width={104}
                  height={104}
                  alt="유저 프로필"
                />
              </div>
              <Pre2 fontWeight="Medium">{userInfo.nickname}</Pre2>
              <Pre3
                fontWeight="Medium"
                color={theme.Blue12}
                style={{ margin: "8px 0 12px" }}
              >
                {`${userInfo.school ? shortSchoolName(userInfo.school) : ""} ${
                  userInfo.grade
                }`}
              </Pre3>
              <Pre4 fontWeight="Medium" color={theme.Gray6}>
                {`잔여 다운로드: ${userInfo.total_cnt}건`}
              </Pre4>
            </Col>
            <Row
              style={{
                backgroundColor: theme.Blue2,
                padding: "20px",
                borderRadius: 12,
                justifyContent: "space-between",
                cursor: "pointer",
              }}
              onClick={() => {
                navigate("/purchase");
              }}
            >
              <Pre3
                color={theme.Blue12}
                style={{
                  whiteSpace: "pre-line",
                  lineHeight: "25px",
                }}
              >{`이용권\n구매하기`}</Pre3>
              <Arrow20 width={24} height={24} stroke={theme.Blue4} />
            </Row>
          </Col>
          <Col style={{ flex: 3.2, alignItems: "flex-start" }}>
            {userInfo.passList.length > 0 ? (
              <MyPageTable
                title={"이용권"}
                propList={["상품명", "이용기간", "잔여 다운로드"]}
                flexList={[3, 2.5, 1]}
                keyList={["productName", "duration", "cnt"]}
                data={userInfo.passList}
              />
            ) : (
              <>
                <Pre2 style={{ marginBottom: 16 }}>이용권</Pre2>
                <Col
                  style={{
                    padding: "52px 0",
                  }}
                >
                  <Pre3 fontWeight="Medium" color={theme.Gray5}>
                    이용권이 없습니다.
                  </Pre3>
                </Col>
              </>
            )}
            <WhiteSpace height={40} />
          </Col>
        </Row>
      </Col>
      <CustomModal
        ref={modalRef}
        name={"alert"}
        onBackdropPress={() => offCustomModal("alert")}
        // isHeader={true}
        // title={"쿠폰 등록"}
        // contentStyles={{
        //   width: 468,
        //   height: 222,
        // }}
      >
        <CustomAlertModal />
      </CustomModal>
      <Overlay />
    </Col>
  );
};

export default DesktopMyPage;

const MyPageTable = (props) => {
  const { title, propList, flexList, data, keyList } = props;
  return (
    <Col style={{ alignItems: "flex-start" }}>
      <Pre2 style={{ marginBottom: 16 }}>{title}</Pre2>
      <Row
        style={{
          backgroundColor: theme.Gray1,
          padding: "16px 24px",
          borderTop: `1px solid ${theme.Gray3}`,
          borderBottom: `1px solid ${theme.Gray3}`,
        }}
      >
        {propList.map((v, i) => {
          return (
            <Pre4
              key={`MyPageTable_${i}`}
              fontWeight="Regular"
              color={theme.Gray8}
              style={{
                flex: flexList[i],
                textAlign: "center",
                display: "inline-block",
              }}
            >
              {v}
            </Pre4>
          );
        })}
      </Row>
      <Col
        style={{
          backgroundColor: theme.White,
          borderBottom: `1px solid ${theme.Gray2}`,
        }}
      >
        {data.map((k, i) => {
          const timeDiff =
            new Date(`${k.expired_at}`).getTime() - new Date().getTime();
          const MILLISECONDS_IN_A_SECOND = 1000;
          const SECONDS_IN_A_MINUTE = 60;
          const MINUTES_IN_AN_HOUR = 60;
          const HOURS_IN_A_DAY = 24;
          const timeDiffInDays =
            timeDiff /
            (MILLISECONDS_IN_A_SECOND *
              SECONDS_IN_A_MINUTE *
              MINUTES_IN_AN_HOUR *
              HOURS_IN_A_DAY);
          const formattedTime = Math.ceil(timeDiffInDays);
          return (
            <Row style={{ padding: "20px 24px" }} key={`${title}_${i}`}>
              {keyList.map((j, jIdx) => {
                if (j === "productName") {
                  return (
                    <Pre4
                      key={`productName_${jIdx}`}
                      fontWeight="Regular"
                      color={theme.Gray8}
                      style={{
                        flex: flexList[jIdx],
                        display: "inline-block",
                      }}
                    >
                      {k.product_name}
                    </Pre4>
                  );
                } else if (j === "duration") {
                  return (
                    <Row
                      style={{ flex: flexList[jIdx], gap: 8 }}
                      key={`duration_${jIdx}`}
                    >
                      <Pre4
                        fontWeight="Regular"
                        color={theme.Gray8}
                        style={{
                          textAlign: "center",
                          display: "inline-block",
                        }}
                      >
                        {`${k.checked_at} ~ ${k.expired_at}`}
                      </Pre4>
                      <Pre5
                        fontWeight="Medium"
                        color={theme.Blue12}
                        style={{
                          padding: "4px 8px",
                          borderRadius: 20,
                          backgroundColor: theme.Blue2,
                        }}
                      >
                        {`${formattedTime + 1}일 남음`}
                      </Pre5>
                    </Row>
                  );
                } else if (j === "cnt") {
                  return (
                    <Pre4
                      key={`cnt_${jIdx}`}
                      fontWeight="Regular"
                      color={theme.Gray8}
                      style={{
                        flex: flexList[jIdx],
                        textAlign: "center",
                        display: "inline-block",
                      }}
                    >
                      {k.cnt}건
                    </Pre4>
                  );
                }
                return <></>;
              })}
            </Row>
          );
        })}
      </Col>
    </Col>
  );
};
