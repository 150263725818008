import React from "react";
import { useLocation } from "react-router-dom";
import { Col } from "../../style/GlobalStyled";
import { useResponsive } from "../../utils/responsive";
import FindSchoolScroll from "../Molecules/FindSchoolScroll";
import { bottomUpModalStyle } from "../../style/GlobalStyle";
import ModalHeader from "../Molecules/ModalHeader";
import theme from "../../style/theme";

const FindSchoolModal = ({ setSchool, offModal }) => {
  const { HORIZONTAL_MARGIN, isDeskTop, isMobile } = useResponsive();
  const location = useLocation();

  const school = location.pathname.split("/")[1];
  return (
    <Col
      style={
        isMobile
          ? {
              ...bottomUpModalStyle,
              height: 400,
              justifyContent: "flex-start",
            }
          : {
              height: 500,
              justifyContent: "flex-start",
              width: isDeskTop
                ? 720
                : `calc(100% - ${2 * HORIZONTAL_MARGIN}px)`,
              backgroundColor: theme.White,
              borderRadius: 20,
            }
      }
      id="invisible-scrollbar"
    >
      <ModalHeader title={"학교 찾기"} offModal={offModal} />
      <FindSchoolScroll
        onClickItem={({ name, code }) => {
          setSchool({ school: name, code });
        }}
        school={""}
        type={school === "high" ? "HIGH" : "MID"}
        mobile={isMobile}
        style={{
          padding: "0px 20px",
          height: isMobile ? 272 : 372,
          marginBottom: 12,
        }}
      />
    </Col>
  );
};

export default FindSchoolModal;
