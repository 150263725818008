type propsType = {
  e: any;
  navigate: any;
  pathname: string;
  subject?: string;
  category?: string;
};

const catchError = async ({
  e,
  navigate,
  pathname,
  subject,
  category,
}: propsType) => {
  if (
    e?.message === "Token not found" ||
    e?.response?.data?.msg === "INVALID_TOKEN"
  ) {
    localStorage.setItem("token", "");
    sessionStorage.setItem("pathname", pathname);
    if (subject) {
      sessionStorage.setItem("subject", subject);
    }
    if (category) {
      sessionStorage.setItem("category", category);
    }
    await navigate("/login", { replace: true }); // 유효하지 않은 경우 로그인 페이지로 이동
    console.log("join error", e);
  } else if (e?.response?.data?.msg === "IS_ASSIGNED") {
    await navigate("/", { replace: true });
  }
  //   else if (e?.response?.data?.msg === "GO_TO_WRITE") {
  //     await navigate.replace("/writeAcademy");
  //   } else if (e?.response?.data?.msg === "GO_TO_SEARCH") {
  //     await navigate.replace("/searchAcademy");
  //   }
};

export default catchError;
