import * as React from "react";

function Xicon(props) {
  return (
    <svg
      width={props.width || 14}
      height={props.width || 14}
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: props.xicon ? [{ rotate: "45deg" }] : [] }}
      {...props}
    >
      <path
        d="M1.5 1.5l7 7m0-7l-7 7"
        stroke={props.color || "#0D66F5"}
        strokeWidth={1.0}
        strokeLinecap="round"
      />
    </svg>
  );
}

export default Xicon;
