import axios from "axios";

const useIntegratedAPI = () => {
  const INTEGRATED_API = axios.create({
    baseURL: "https://yp92155c00.execute-api.ap-northeast-2.amazonaws.com/v1",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  });

  const school = {
    searchSchool: (params) =>
      INTEGRATED_API.get(
        `/school/search?schoolName=${params.schoolName}&schoolType=${params.schoolType}`
      ),
  };

  const returnValue = [
    {
      INTEGRATED_API,
      school,
    },
  ];
  return returnValue;
};

export default useIntegratedAPI;
