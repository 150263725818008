import React, { useRef } from "react";
import {
  Col,
  Pre1,
  Pre2,
  Pre3,
  Pre4,
  Pre5,
  Row,
  WhiteSpace,
} from "../../style/GlobalStyled";
import theme from "../../style/theme";
import shortSchoolName from "../../function/shortSchoolName";
import Arrow20 from "../../images/svg/Arrow20";
import { CustomButton } from "../Atoms/CustomButton";
import CustomModal from "../Atoms/CustomModal";
import CustomAlertModal from "../Atoms/CustomAlertModal";
import { useNavigate } from "react-router-dom";
import Plus from "../../images/icon/Plus";
import Overlay from "./Overlay";

const TabletMyPage = ({ userInfo, mobile }) => {
  const navigate = useNavigate();
  const modalRef = useRef({});

  const onCustomModal = (name: string) => {
    modalRef.current?.[name]?.show?.();
  };

  const offCustomModal = (name: string) => {
    modalRef.current?.[name]?.hide?.();
  };
  return (
    <Col>
      <Col
        style={{
          alignItems: "flex-start",
          marginBottom: 40,
        }}
      >
        <Col style={{ padding: mobile ? "0px 20px 24px" : "0px 40px 24px" }}>
          <Pre1
            style={{
              marginBottom: mobile ? 16 : 24,
              alignSelf: "flex-start",
              fontSize: mobile ? "20px" : "24px",
            }}
          >
            마이페이지
          </Pre1>
          <Row
            style={{
              border: `1px solid ${theme.Gray3}`,
              borderRadius: 12,
              padding: mobile ? "16px 20px" : "20px",
              marginBottom: 16,
              justifyContent: "space-between",
            }}
          >
            <Row style={{ width: "auto" }}>
              <div
                style={{
                  borderRadius: "50%",
                  overflow: "hidden",
                  lineHeight: 0,
                  border: `1px solid ${theme.Gray3}`,
                  width: mobile ? 82 : 106,
                  height: mobile ? 82 : 106,
                  minWidth: mobile ? 82 : 106,
                }}
              >
                <img
                  src={
                    userInfo.imgUrl ||
                    "https://kr.object.ncloudstorage.com/userprofile-image/cheram_default.jpg"
                  }
                  width={mobile ? 80 : 104}
                  height={mobile ? 80 : 104}
                  alt="유저 프로필"
                />
              </div>
              <Col
                style={{
                  width: "auto",
                  height: "auto",
                  alignItems: "flex-start",
                  marginLeft: 24,
                }}
              >
                <Pre2
                  fontWeight="Medium"
                  style={{ fontSize: mobile ? 16 : 20 }}
                >
                  {userInfo.nickname}
                </Pre2>
                <Pre3
                  fontWeight="Medium"
                  color={theme.Blue12}
                  style={{ margin: "8px 0 12px", fontSize: mobile ? 14 : 16 }}
                >
                  {`${
                    userInfo.school ? shortSchoolName(userInfo.school) : ""
                  } ${userInfo.grade}`}
                </Pre3>
                <Pre4
                  fontWeight="Medium"
                  color={theme.Gray6}
                  style={{ fontSize: mobile ? 12 : 14 }}
                >
                  {`잔여 다운로드: ${userInfo.total_cnt}건`}
                </Pre4>
              </Col>
            </Row>
            <Arrow20 stroke={theme.Gray4} />
          </Row>
          <Row
            style={{
              backgroundColor: theme.Blue2,
              padding: "20px",
              borderRadius: 12,
              justifyContent: "space-between",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/purchase");
            }}
          >
            <Pre3
              color={theme.Blue12}
              style={{
                lineHeight: "25px",
              }}
            >
              {`이용권 구매하기`}
            </Pre3>
            <Arrow20 width={24} height={24} stroke={theme.Blue4} />
          </Row>
        </Col>
        <span
          style={{
            width: "100%",
            height: "16px",
            backgroundColor: theme.Gray1,
          }}
        />

        <Col
          style={{
            flex: 3.2,
            alignItems: "flex-start",
            padding: "24px 0px",
          }}
        >
          {userInfo.passList.length > 0 ? (
            <MyPageTable
              title={"이용권"}
              propList={
                mobile
                  ? ["정보", "잔여 다운로드"]
                  : ["상품명", "이용기간", "잔여 다운로드"]
              }
              flexList={mobile ? [2.5, 1] : [3, 3.5, 1]}
              keyList={
                mobile
                  ? ["productName", "cnt"]
                  : ["productName", "duration", "cnt"]
              }
              data={userInfo.passList}
              mobile={mobile}
            />
          ) : (
            <>
              <Pre2
                style={{
                  marginBottom: 16,
                  marginLeft: mobile ? 20 : 40,
                  fontSize: mobile ? 16 : 20,
                }}
              >
                이용권
              </Pre2>
              <Col
                style={{
                  padding: "52px 0",
                }}
              >
                <Pre4 fontWeight="Medium" color={theme.Gray5}>
                  이용권이 없습니다.
                </Pre4>
              </Col>
            </>
          )}
          <WhiteSpace height={40} />
        </Col>
      </Col>
      <CustomModal
        ref={modalRef}
        name={"alert"}
        onBackdropPress={() => offCustomModal("alert")}
        // isHeader={true}
        // title={"쿠폰 등록"}
        // contentStyles={{
        //   width: 468,
        //   height: 222,
        // }}
      >
        <CustomAlertModal />
      </CustomModal>
      <Overlay />
    </Col>
  );
};

export default TabletMyPage;

const MyPageTable = (props) => {
  const { title, propList, flexList, data, keyList, mobile } = props;
  return (
    <Col style={{ alignItems: "flex-start" }}>
      <Pre2
        style={{
          marginBottom: 16,
          fontSize: mobile ? 16 : 20,
          paddingLeft: mobile ? 20 : 40,
        }}
      >
        {title}
      </Pre2>
      <Row
        style={{
          backgroundColor: theme.Gray1,
          padding: mobile ? "20px" : "16px 40px",
          borderTop: `1px solid ${theme.Gray3}`,
          borderBottom: `1px solid ${theme.Gray3}`,
        }}
      >
        {propList.map((v, i) => {
          return (
            <Pre4
              key={`MyPageTable_${i}`}
              fontWeight="Regular"
              color={theme.Gray8}
              style={{
                flex: flexList[i],
                textAlign: "center",
                display: "inline-block",
              }}
            >
              {v}
            </Pre4>
          );
        })}
      </Row>
      <Col
        style={{
          backgroundColor: theme.White,
        }}
      >
        {data.map((k, i) => {
          const timeDiff =
            new Date(`${k.expired_at}`).getTime() - new Date().getTime();
          const MILLISECONDS_IN_A_SECOND = 1000;
          const SECONDS_IN_A_MINUTE = 60;
          const MINUTES_IN_AN_HOUR = 60;
          const HOURS_IN_A_DAY = 24;
          const timeDiffInDays =
            timeDiff /
            (MILLISECONDS_IN_A_SECOND *
              SECONDS_IN_A_MINUTE *
              MINUTES_IN_AN_HOUR *
              HOURS_IN_A_DAY);
          const formattedTime = Math.ceil(timeDiffInDays);
          return (
            <Row
              key={`passList_${i}`}
              style={{
                padding: mobile ? "16px 20px" : "20px 40px",
                borderBottom: `1px solid ${theme.Gray2}`,
              }}
            >
              {keyList.map((j, jIdx) => {
                if (j === "productName") {
                  if (mobile) {
                    return (
                      <Col
                        key={`mobile_productName_${jIdx}`}
                        style={{
                          flex: flexList[jIdx],
                          gap: 8,
                          alignItems: "flex-start",
                        }}
                      >
                        <Pre4
                          fontWeight="Regular"
                          style={{
                            flex: flexList[jIdx],
                            textAlign: "center",
                            display: "inline-block",
                          }}
                        >
                          {k.product_name}
                        </Pre4>
                        <Pre4
                          fontWeight="Regular"
                          color={theme.Gray6}
                          style={{
                            textAlign: "center",
                            display: "inline-block",
                          }}
                        >
                          {`${k.checked_at}~${k.expired_at}`}
                        </Pre4>
                        <Pre5
                          fontWeight="Medium"
                          color={theme.Blue12}
                          style={{
                            padding: "4px 8px",
                            borderRadius: 20,
                            backgroundColor: theme.Blue2,
                            whiteSpace: "pre-line",
                            textAlign: "center",
                          }}
                        >
                          {`${formattedTime + 1}일 남음`}
                        </Pre5>
                      </Col>
                    );
                  } else {
                    return (
                      <Pre4
                        key={`tablet_productName_${jIdx}`}
                        fontWeight="Regular"
                        color={theme.Gray8}
                        style={{
                          flex: flexList[jIdx],
                          display: "inline-block",
                        }}
                      >
                        {k.product_name}
                      </Pre4>
                    );
                  }
                } else if (j === "duration") {
                  return (
                    <Row
                      style={{ flex: flexList[jIdx], gap: 8 }}
                      key={`duration_${jIdx}`}
                    >
                      <Pre4
                        fontWeight="Regular"
                        color={theme.Gray8}
                        style={{
                          textAlign: "center",
                          display: "inline-block",
                        }}
                      >
                        {`${k.checked_at} ~ ${k.expired_at}`}
                      </Pre4>
                      <Pre5
                        fontWeight="Medium"
                        color={theme.Blue12}
                        style={{
                          padding: "4px 8px",
                          borderRadius: 20,
                          backgroundColor: theme.Blue2,
                        }}
                      >
                        {`${formattedTime}일 남음`}
                      </Pre5>
                    </Row>
                  );
                } else if (j === "cnt") {
                  return (
                    <Pre4
                      key={`cnt_${jIdx}`}
                      fontWeight="Regular"
                      color={theme.Gray8}
                      style={{
                        flex: flexList[jIdx],
                        textAlign: "center",
                        display: "inline-block",
                      }}
                    >
                      {k.cnt}건
                    </Pre4>
                  );
                }
              })}
            </Row>
          );
        })}
      </Col>
    </Col>
  );
};
