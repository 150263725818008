import React from "react";
import { Col, Pre1, Pre3, Pre4 } from "../style/GlobalStyled";
import {
  Route,
  Routes,
  BrowserRouter as Router,
  useNavigate,
} from "react-router-dom";
import ConceptPage from "./ConceptPage";
import PracticalPage from "./PracticalPage";
import WeaknessPage from "./WeaknessPage";
import FinalPage from "./FinalPage";
import SchoolPage from "./SchoolPage";
import { useResponsive } from "../utils/responsive";
import theme from "../style/theme";
import { CustomButton } from "../components/Atoms/CustomButton";

// 개념완성: ConceptPage
// 실전학습: PracticalPage
// 약점공략: WeaknessPage
// 최종점검: FinalPage
// 학교기출: SchoolPage

const MiddlePage = () => {
  return (
    <Col>
      <Routes>
        <Route path="SchoolPage" element={<SchoolPage />} />
        <Route
          path="ConceptPage"
          element={<ConceptPage schoolType="MIDDLE" />}
        />
        <Route
          path="PracticalPage"
          element={<PracticalPage schoolType="MIDDLE" />}
        />
        <Route
          path="WeaknessPage"
          element={<WeaknessPage schoolType="MIDDLE" />}
        />
        <Route path="FinalPage" element={<FinalPage schoolType="MIDDLE" />} />

        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </Col>
  );
};

export default MiddlePage;

const ErrorPage = () => {
  const { isMobile, isTablet } = useResponsive();
  const navigate = useNavigate();
  return (
    <Col style={{ height: "70vh" }}>
      <Pre1
        fontWeight="Medium"
        style={{ fontSize: isTablet ? "28px" : isMobile ? "20px" : "32px" }}
        color={theme.Gray8}
      >
        페이지를 찾을 수 없습니다.
      </Pre1>
      {isMobile ? (
        <Pre4
          fontWeight="Regular"
          color={theme.Gray6}
          style={{
            whiteSpace: "pre-line",
            textAlign: "center",
            lineHeight: "25.6px",
            marginTop: 24,
            marginBottom: 32,
          }}
        >
          입력하신 페이지의 주소가 잘못되었거나{"\n"}페이지의 주소가 변경 혹은
          삭제되어 요청하신 페이지를{"\n"}찾을 수 없습니다. 입력하신 주소가
          정확한지 다시 한번{"\n"}확인해 주시기 바랍니다.
        </Pre4>
      ) : (
        <Pre3
          fontWeight="Regular"
          color={theme.Gray6}
          style={{
            whiteSpace: "pre-line",
            textAlign: "center",
            lineHeight: "25.6px",
            marginTop: 32,
            marginBottom: 48,
          }}
        >
          입력하신 페이지의 주소가 잘못되었거나{"\n"}페이지의 주소가 변경 혹은
          삭제되어 요청하신 페이지를 찾을 수 없습니다.{"\n"}입력하신 주소가
          정확한지 다시 한번 확인해 주시기 바랍니다.
        </Pre3>
      )}
      <Col style={{ width: isMobile ? 142 : 262, height: "auto" }}>
        <CustomButton
          size="Full"
          btnType="Primary"
          title="홈으로 이동하기"
          onClick={() => {
            navigate("/");
          }}
        />
      </Col>
    </Col>
  );
};
