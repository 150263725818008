import axios from "axios";
import { IAuth, IEnglishAPI, IFriend, IInfo, ILogin, ITest } from "./apiTypes";

type TUseAPI = () => IEnglishAPI[];

const useEnglishAPI: TUseAPI = () => {
  // const [cookies, setCookies] = useCookies();

  const API = axios.create({
    baseURL: "https://cwbkmwc15c.execute-api.ap-northeast-2.amazonaws.com/api",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  });

  const info: IInfo = {
    checkNickname: (event) =>
      API.get(`/info/nickname?nickname=${event.nickname}`),
  };

  const friend: IFriend = {
    postFriendAdd: (event) => API.post(`/friend`, event.body),
  };

  const test: ITest = {
    isInput: (event) => API.get(`/test/input?id=${event.userId}`),
  };

  const auth: IAuth = {
    requestNickname: () => API.get(`/auth/nickname`),
  };

  const returnValue = [
    {
      API,
      info,
      friend,
      test,
      auth,
    },
  ];

  return returnValue;
};

export default useEnglishAPI;
