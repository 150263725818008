import React from "react";
import CheckIcon from "../../images/svg/CheckIcon";
import { Pre3, Row } from "../../style/GlobalStyled";
import theme from "../../style/theme";

interface propsType {
  size?: number;
  isChecked: boolean;
  onClick: () => void;
  title?: string;
  fontStyle?: React.CSSProperties;
}

const CustomCheckBoxRow = (props: propsType) => {
  const {
    size = 20,
    isChecked = false,
    onClick,
    title,
    fontStyle = {},
  } = props;
  return (
    <Row
      style={{ height: "auto", width: "auto", cursor: "pointer" }}
      onClick={onClick}
    >
      <div
        style={{
          width: size,
          height: size,
          borderRadius: 4,
          backgroundColor: isChecked ? theme.Blue10 : theme.White,
          border: `1px solid ${theme.Gray2}`,
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <CheckIcon />
      </div>
      {Boolean(title) && (
        <Pre3
          fontWeight="Regular"
          color={theme.Black}
          style={{
            marginLeft: 8,
            userSelect: "none",
            ...fontStyle,
          }}
        >
          {title}
        </Pre3>
      )}
    </Row>
  );
};

export default CustomCheckBoxRow;
