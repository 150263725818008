import React, { useEffect, useState } from "react";
import { Pre4, Row } from "../../style/GlobalStyled";
import { useResponsive } from "../../utils/responsive";

interface Props {
  logo: React.JSX.Element;
  title: string;
  color: string;
  bgColor: string;
  onClick?: () => void;
}

const LoginButton = ({ logo, title, bgColor, color, onClick }: Props) => {
  const { isTablet, isMobile } = useResponsive();
  return (
    <Row
      style={{
        width: "100%",
        height: isMobile ? "48px" : "52px",
        backgroundColor: bgColor,
        borderRadius: 8,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <div>{logo}</div>
      <Pre4 color={color} style={{ marginLeft: 8, padding: "10px 0" }}>
        {title}
      </Pre4>
    </Row>
  );
};

export default LoginButton;
