import React from "react";
import { Pre5, Row } from "../../style/GlobalStyled";
import theme from "../../style/theme";

interface PropsType {
  type:
    | "Gray"
    | "Blue"
    | "Pink"
    | "Lined"
    | "Eng"
    | "Kor"
    | "Math"
    | "Society"
    | "Science"
    | "History"
    | "Red";
  size: "L" | "M" | "S" | "XS";
  title: string;
  isWhite?: boolean;
  fontStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  leftComponent?: JSX.Element;
  rightComponent?: JSX.Element;
}

const CustomTag = (props: PropsType) => {
  const {
    type,
    size,
    isWhite,
    title,
    style,
    leftComponent,
    rightComponent,
    fontStyle,
  } = props;
  return (
    <Row
      style={{
        width: "auto",
        height: "auto",
        borderRadius: 20,
        padding: SizeType[size].padding,
        backgroundColor: isWhite ? theme.White : TagType[type].backgroundColor,
        border: TagType[type].border,
        ...style,
      }}
    >
      {Boolean(leftComponent) && <div>{leftComponent}</div>}
      <Pre5
        fontWeight="Medium"
        color={TagType[type].textColor}
        style={{ fontSize: SizeType[size].fontSize, ...fontStyle }}
      >
        {title}
      </Pre5>
      {Boolean(rightComponent) && (
        <div style={{ marginLeft: 8 }}>{rightComponent}</div>
      )}
    </Row>
  );
};

const TagType = {
  Gray: {
    backgroundColor: theme.Gray2,
    textColor: theme.Gray9,
    border: `0 solid ${theme.Gray2}`,
  },
  Blue: {
    backgroundColor: theme.Blue2,
    textColor: theme.Blue12,
    border: `0 solid ${theme.Blue2}`,
  },
  Pink: {
    backgroundColor: theme.Pink2,
    textColor: theme.Pink9,
    border: `0 solid ${theme.Pink2}`,
  },
  Lined: {
    backgroundColor: theme.White,
    textColor: theme.Gray8,
    border: `1px solid ${theme.Gray4}`,
  },
  Eng: {
    backgroundColor: theme.BackgroundEng,
    textColor: theme.DarkEng,
    border: `0 solid ${theme.BackgroundEng}`,
  },
  Kor: {
    backgroundColor: theme.BackgroundKor,
    textColor: theme.DarkKor,
    border: `0 solid ${theme.BackgroundKor}`,
  },
  Math: {
    backgroundColor: theme.BackgroundMath,
    textColor: theme.DarkMath,
    border: `0 solid ${theme.BackgroundMath}`,
  },
  Society: {
    backgroundColor: theme.BackgroundSociety,
    textColor: theme.DarkSociety,
    border: `0 solid ${theme.BackgroundSociety}`,
  },
  Science: {
    backgroundColor: theme.BackgroundScience,
    textColor: theme.DarkScience,
    border: `0 solid ${theme.BackgroundScience}`,
  },
  History: {
    backgroundColor: theme.BackgroundHistory,
    textColor: theme.DarkHistory,
    border: `0 solid ${theme.BackgroundHistory}`,
  },
  Red: {
    backgroundColor: theme.LightRed,
    textColor: theme.DarkRed,
    border: `0 solid ${theme.LightRed}`,
  },
};

const SizeType = {
  L: {
    padding: "8px 12px",
    fontSize: "14px",
  },
  M: {
    padding: "8px 12px",
    fontSize: "12px",
  },
  S: {
    padding: "6px 10px",
    fontSize: "12px",
  },
  XS: {
    padding: "4px 8px",
    fontSize: "12px",
  },
};

export default CustomTag;
