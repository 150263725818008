const translateSubject = (str: string) => {
  switch (str) {
    case "영어":
      return "english";
    case "국어":
      return "korean";
    case "수학":
      return "math";
    case "사회":
      return "society";
    case "과학":
      return "science";
    case "역사":
      return "history";
    case "한국사":
      return "history";
    case "english":
      return "영어";
    case "korean":
      return "국어";
    case "math":
      return "수학";
    case "society":
      return "사회";
    case "science":
      return "과학";
    case "history":
      return "역사";
  }
  return "";
};

export { translateSubject };
