const midKoreaData = {
  title: "세부과목",
  type: "select",
  list: [
    {
      text: "국어 2-2",
      title: "세부과목",
      type: "select",
      list: [
        {
          text: "천재(박영목)",
          title: "대단원",
          type: "check",
          list: [
            {
              id: "문학 작품의 재구성",
              text: "문학 작품의 재구성",
              title: "문학 작품의 재구성",
            },
            {
              id: "설명과 이해",
              text: "설명과 이해",
              title: "설명과 이해",
            },
            {
              id: "비판적 듣기와 읽기",
              text: "비판적 듣기와 읽기",
              title: "비판적 듣기와 읽기",
            },
          ],
        },
        {
          text: "창비(이도영)",
          title: "대단원",
          type: "check",
          list: [
            {
              id: "신나는 학교생활",
              text: "신나는 학교생활",
              title: "신나는 학교생활",
            },
            {
              id: "마음이 크는 자리",
              text: "마음이 크는 자리",
              title: "마음이 크는 자리",
            },
            {
              id: "세상을 보고 듣다",
              text: "세상을 보고 듣다",
              title: "세상을 보고 듣다",
            },
          ],
        },
        {
          text: "금성(류수열)",
          title: "대단원",
          type: "check",
          list: [
            {
              id: "세상을 읽는다, 나에게 묻는다",
              text: "세상을 읽는다, 나에게 묻는다",
              title: "세상을 읽는다, 나에게 묻는다",
            },
            {
              id: "모방과 창조 사이",
              text: "모방과 창조 사이",
              title: "모방과 창조 사이",
            },
            {
              id: "설명, 앎에 이르는 길",
              text: "설명, 앎에 이르는 길",
              title: "설명, 앎에 이르는 길",
            },
          ],
        },
        {
          text: "미래엔(신유식)",
          title: "대단원",
          type: "check",
          list: [
            {
              id: "문학의 빛깔",
              text: "문학의 빛깔",
              title: "문학의 빛깔",
            },
            {
              id: "서사 갈래의 이해",
              text: "서사 갈래의 이해",
              title: "서사 갈래의 이해",
            },
            {
              id: "극 갈래의 이해",
              text: "극 갈래의 이해",
              title: "극 갈래의 이해",
            },
          ],
        },
        {
          text: "비상(김영욱)",
          title: "대단원",
          type: "check",
          list: [
            {
              id: "소통하고 공감하는 삶",
              text: "소통하고 공감하는 삶",
              title: "소통하고 공감하는 삶",
            },
            {
              id: "매체로 보는 세상",
              text: "매체로 보는 세상",
              title: "매체로 보는 세상",
            },
            {
              id: "새롭게 보고, 다양하게 표현하고",
              text: "새롭게 보고, 다양하게 표현하고",
              title: "새롭게 보고, 다양하게 표현하고",
            },
          ],
        },
        {
          text: "천재(노미숙)",
          title: "대단원",
          type: "check",
          list: [
            {
              id: "표현의 빛깔",
              text: "표현의 빛깔",
              title: "표현의 빛깔",
            },
            {
              id: "읽고 쓰는 즐거움",
              text: "읽고 쓰는 즐거움",
              title: "읽고 쓰는 즐거움",
            },
            {
              id: "함께 여는 세상의 창",
              text: "함께 여는 세상의 창",
              title: "함께 여는 세상의 창",
            },
          ],
        },
        {
          text: "지학사(이삼형)",
          title: "대단원",
          type: "check",
          list: [
            {
              id: "시선과 목소리",
              text: "시선과 목소리",
              title: "시선과 목소리",
            },
            {
              id: "한글은 바르게, 발표는 효과적으로",
              text: "한글은 바르게, 발표는 효과적으로",
              title: "한글은 바르게, 발표는 효과적으로",
            },
            {
              id: "생활 속의 문학 읽기",
              text: "생활 속의 문학 읽기",
              title: "생활 속의 문학 읽기",
            },
            {
              id: "함께 이해하는 설명",
              text: "함께 이해하는 설명",
              title: "함께 이해하는 설명",
            },
            {
              id: "상황에 맞는 대화",
              text: "상황에 맞는 대화",
              title: "상황에 맞는 대화",
            },
          ],
        },
      ],
    },
    {
      text: "국어 3-2",
      title: "세부과목",
      type: "select",
      list: [
        {
          text: "동아(이삼형)",
          title: "대단원",
          type: "check",
          list: [
            {
              id: "통일 시대의 우리말",
              text: "통일 시대의 우리말",
              title: "통일 시대의 우리말",
            },
            {
              id: "조정하며 읽고,근거를 들어 토론하기",
              text: "조정하며 읽고,근거를 들어 토론하기",
              title: "조정하며 읽고,근거를 들어 토론하기",
            },
          ],
        },
        {
          text: "미래엔(신유식)",
          title: "대단원",
          type: "check",
          list: [
            {
              id: "문학의 빛깔",
              text: "문학의 빛깔",
              title: "문학의 빛깔",
            },
            {
              id: "서사 갈래의 이해",
              text: "서사 갈래의 이해",
              title: "서사 갈래의 이해",
            },
            {
              id: "극 갈래의 이해",
              text: "극 갈래의 이해",
              title: "극 갈래의 이해",
            },
            {
              id: "교술 갈래의 이해",
              text: "교술 갈래의 이해",
              title: "교술 갈래의 이해",
            },
          ],
        },
        {
          text: "천재(노미숙)",
          title: "대단원",
          type: "check",
          list: [
            {
              id: "문학의 샘",
              text: "문학의 샘",
              title: "문학의 샘",
            },
            {
              id: "너의 생각, 나의 생각",
              text: "너의 생각, 나의 생각",
              title: "너의 생각, 나의 생각",
            },
            {
              id: "세상을 보는 눈",
              text: "세상을 보는 눈",
              title: "세상을 보는 눈",
            },
          ],
        },
        {
          text: "천재(박영목)",
          title: "대단원",
          type: "check",
          list: [
            {
              id: "문학과 삶",
              text: "문학과 삶",
              title: "문학과 삶",
            },
            {
              id: "논증과 설득 전략",
              text: "논증과 설득 전략",
              title: "논증과 설득 전략",
            },
            {
              id: "문장과 글쓰기",
              text: "문장과 글쓰기",
              title: "문장과 글쓰기",
            },
            {
              id: "점검과 조정",
              text: "점검과 조정",
              title: "점검과 조정",
            },
          ],
        },
        {
          text: "비상(김진수)",
          title: "대단원",
          type: "check",
          list: [
            {
              id: "문제를 해결하는 힘",
              text: "문제를 해결하는 힘",
              title: "문제를 해결하는 힘",
            },
            {
              id: "문학으로 느끼는 삶",
              text: "문학으로 느끼는 삶",
              title: "문학으로 느끼는 삶",
            },
            {
              id: "정확하게 말하고, 비판적으로 듣고",
              text: "정확하게 말하고, 비판적으로 듣고",
              title: "정확하게 말하고, 비판적으로 듣고",
            },
            {
              id: "논리로 여는 세상",
              text: "논리로 여는 세상",
              title: "논리로 여는 세상",
            },
          ],
        },
        {
          text: "지학사(이삼형)",
          title: "대단원",
          type: "check",
          list: [
            {
              id: "다양한 해석, 자신 있는 표현",
              text: "다양한 해석, 자신 있는 표현",
              title: "다양한 해석, 자신 있는 표현",
            },
            {
              id: "조정하며 읽고, 근거를 들어 토론하기",
              text: "조정하며 읽고, 근거를 들어 토론하기",
              title: "조정하며 읽고, 근거를 들어 토론하기",
            },
            {
              id: "문학에 담긴 어제와 오늘",
              text: "문학에 담긴 어제와 오늘",
              title: "문학에 담긴 어제와 오늘",
            },
            {
              id: "다르게 보고, 바르게 쓰기",
              text: "다르게 보고, 바르게 쓰기",
              title: "다르게 보고, 바르게 쓰기",
            },
          ],
        },
        {
          text: "창비(이도영 )",
          title: "대단원",
          type: "check",
          list: [
            {
              id: "읽고 쓰는 즐거움",
              text: "읽고 쓰는 즐거움",
              title: "읽고 쓰는 즐거움",
            },
            {
              id: "세상을 이해하고 가꾸다",
              text: "세상을 이해하고 가꾸다",
              title: "세상을 이해하고 가꾸다",
            },
            {
              id: "논리적인 말과 글",
              text: "논리적인 말과 글",
              title: "논리적인 말과 글",
            },
          ],
        },
        {
          text: "금성(류수열)",
          title: "대단원",
          type: "check",
          list: [
            {
              id: "문제 해결 과정으로서의 글 읽기와 글쓰기",
              text: "문제 해결 과정으로서의 글 읽기와 글쓰기",
              title: "문제 해결 과정으로서의 글 읽기와 글쓰기",
            },
            {
              id: "과거와 현재의 대화",
              text: "과거와 현재의 대화",
              title: "과거와 현재의 대화",
            },
            {
              id: "글의 설득력을 찾아서",
              text: "글의 설득력을 찾아서",
              title: "글의 설득력을 찾아서",
            },
          ],
        },
      ],
    },
  ],
};
export default midKoreaData;
