const societyClassHighData = {
  title: "세부과목",
  type: "select",
  list: [
    {
      text: "통합사회",
      title: "대단원",
      type: "check",
      list: [
        {
          text: "인간, 사회, 환경과 행복",
          title: "대단원",
          id: "인간, 사회, 환경과 행복",
        },
        { text: "자연환경과 인간", title: "대단원", id: "자연환경과 인간" },
        { text: "생활 공간과 사회", title: "대단원", id: "생활 공간과 사회" },
        { text: "인권 보장과 헌법", title: "대단원", id: "인권 보장과 헌법" },
        { text: "시장 경제와 금융", title: "대단원", id: "시장 경제와 금융" },
        {
          text: "사회 정의와 불평등",
          title: "대단원",
          id: "사회 정의와 불평등",
        },
        { text: "문화와 다양성", title: "대단원", id: "문화와 다양성" },
        { text: "세계화와 평화", title: "대단원", id: "세계화와 평화" },
        {
          text: "미래와 지속 가능한 삶",
          title: "대단원",
          id: "미래와 지속 가능한 삶",
        },
      ],
    },
    {
      text: "경제",
      title: "대단원",
      type: "check",
      list: [
        {
          text: "경제생활과 경제 문제",
          title: "대단원",
          id: "경제생활과 경제 문제",
        },
        { text: "시장과 경제활동", title: "대단원", id: "시장과 경제활동" },
        { text: "국가와 경제활동", title: "대단원", id: "국가와 경제활동" },
        { text: "세계 시장과 교역", title: "대단원", id: "세계 시장과 교역" },
        { text: "경제생활과 금융", title: "대단원", id: "경제생활과 금융" },
      ],
    },
    {
      text: "정치와 법",
      title: "대단원",
      type: "check",
      list: [
        { text: "민주주의와 헌법", title: "대단원", id: "민주주의와 헌법" },
        { text: "민주 국가와 정부", title: "대단원", id: "민주 국가와 정부" },
        { text: "정치 과정과 참여", title: "대단원", id: "정치 과정과 참여" },
        { text: "개인 생활과 법", title: "대단원", id: "개인 생활과 법" },
        { text: "사회생활과 법", title: "대단원", id: "사회생활과 법" },
        {
          text: "국제 관계와 한반도",
          title: "대단원",
          id: "국제 관계와 한반도",
        },
      ],
    },
    {
      text: "사회・문화",
      title: "대단원",
      type: "check",
      list: [
        {
          text: "사회・문화 현상의 탐구",
          title: "대단원",
          id: "사회・문화 현상의 탐구",
        },
        { text: "개인과 사회 구조", title: "대단원", id: "개인과 사회 구조" },
        { text: "문화와 일상생활", title: "대단원", id: "문화와 일상생활" },
        {
          text: "사회 계층과 불평등",
          title: "대단원",
          id: "사회 계층과 불평등",
        },
        { text: "현대의 사회 변동", title: "대단원", id: "현대의 사회 변동" },
      ],
    },
    {
      text: "한국지리",
      title: "대단원",
      type: "check",
      list: [
        {
          text: "국토 인식과 지리 정보",
          title: "대단원",
          id: "국토 인식과 지리 정보",
        },
        {
          text: "지형 환경과 인간 생활",
          title: "대단원",
          id: "지형 환경과 인간 생활",
        },
        {
          text: "기후 환경과 인간 생활",
          title: "대단원",
          id: "기후 환경과 인간 생활",
        },
        {
          text: "거주 공간의 변화와 지역 개발",
          title: "대단원",
          id: "거주 공간의 변화와 지역 개발",
        },
        {
          text: "생산과 소비의 공간",
          title: "대단원",
          id: "생산과 소비의 공간",
        },
        {
          text: "인구 변화와 다문화 공간",
          title: "대단원",
          id: "인구 변화와 다문화 공간",
        },
        {
          text: "우리나라의 지역 이해",
          title: "대단원",
          id: "우리나라의 지역 이해",
        },
      ],
    },
    {
      text: "세계지리",
      title: "대단원",
      type: "check",
      list: [
        {
          text: "세계화와 지역 이해",
          title: "대단원",
          id: "세계화와 지역 이해",
        },
        {
          text: "세계의 자연환경과 인간 생활",
          title: "대단원",
          id: "세계의 자연환경과 인간 생활",
        },
        {
          text: "세계의 인문 환경과 인문 경관",
          title: "대단원",
          id: "세계의 인문 환경과 인문 경관",
        },
        {
          text: "몬순 아시아와 오세아니아",
          title: "대단원",
          id: "몬순 아시아와 오세아니아",
        },
        {
          text: "건조 아시아와 북부 아프리카",
          title: "대단원",
          id: "건조 아시아와 북부 아프리카",
        },
        {
          text: "유럽과 북부 아메리카",
          title: "대단원",
          id: "유럽과 북부 아메리카",
        },
        {
          text: "사하라 이남 아프리카와 중・남부 아메리카",
          title: "대단원",
          id: "사하라 이남 아프리카와 중・남부 아메리카",
        },
        {
          text: "평화와 공존의 세계",
          title: "대단원",
          id: "평화와 공존의 세계",
        },
      ],
    },
    {
      text: "생활과 윤리",
      title: "대단원",
      type: "check",
      list: [
        {
          text: "현대의 삶과 실천 윤리",
          title: "대단원",
          id: "현대의 삶과 실천 윤리",
        },
        { text: "생명과 윤리", title: "대단원", id: "생명과 윤리" },
        { text: "사회와 윤리", title: "대단원", id: "사회와 윤리" },
        { text: "과학과 윤리", title: "대단원", id: "과학과 윤리" },
        { text: "문화와 윤리", title: "대단원", id: "문화와 윤리" },
        {
          text: "평화와 공존의 윤리",
          title: "대단원",
          id: "평화와 공존의 윤리",
        },
      ],
    },
    {
      text: "윤리와 사상",
      title: "대단원",
      type: "check",
      list: [
        { text: "인간과 윤리 사상", title: "대단원", id: "인간과 윤리 사상" },
        {
          text: "동양과 한국 윤리 사상",
          title: "대단원",
          id: "동양과 한국 윤리 사상",
        },
        { text: "서양 윤리 사상", title: "대단원", id: "서양 윤리 사상" },
        { text: "사회 사상", title: "대단원", id: "사회 사상" },
      ],
    },
    {
      text: "동아시아사",
      title: "대단원",
      type: "check",
      list: [
        {
          text: "동아시아 역사의 시작",
          title: "대단원",
          id: "동아시아 역사의 시작",
        },
        {
          text: "동아시아 시계의 성립과 변화",
          title: "대단원",
          id: "동아시아 시계의 성립과 변화",
        },
        {
          text: "동아시아의 사회 변동과 문화 교류",
          title: "대단원",
          id: "동아시아의 사회 변동과 문화 교류",
        },
        {
          text: "동아시아의 근대화 운동과 반제국주의 민족 운동",
          title: "대단원",
          id: "동아시아의 근대화 운동과 반제국주의 민족 운동",
        },
        { text: "오늘날의 동아시아", title: "대단원", id: "오늘날의 동아시아" },
      ],
    },
    {
      text: "세계사",
      title: "대단원",
      type: "check",
      list: [
        {
          text: "인류의 출현과 문명의 발생",
          title: "대단원",
          id: "인류의 출현과 문명의 발생",
        },
        {
          text: "동아시아 지역의 역사",
          title: "대단원",
          id: "동아시아 지역의 역사",
        },
        {
          text: "서아시아, 인도 지역의 역사",
          title: "대단원",
          id: "서아시아, 인도 지역의 역사",
        },
        {
          text: "유럽, 아메리카 지역의 역사",
          title: "대단원",
          id: "유럽, 아메리카 지역의 역사",
        },
        {
          text: "제국주의와 두 차례 세계 대전",
          title: "대단원",
          id: "제국주의와 두 차례 세계 대전",
        },
        { text: "현대 세계의 변화", title: "대단원", id: "현대 세계의 변화" },
      ],
    },
  ],
};

export default societyClassHighData;
