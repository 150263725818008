import React, { useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useCheryExamAPI from "../../hooks/useCheryExamAPI";
import { Col } from "../../style/GlobalStyled";
import CustomLoading from "../Atoms/CustomLoading";
import { useRecoilState } from "recoil";
import { categoryState, subjectState } from "../../atoms/atoms";

const AppleRedirectPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = location.hash;
  const token = params.split("id_token=")[1].split(".")[1];

  const [currentSubject, setSubject] = useRecoilState(subjectState);
  const [currentCategory, setCategory] = useRecoilState(categoryState);

  const [WEB_API] = useMemo(useCheryExamAPI, []);

  const getToken = () => {
    const data = JSON.parse(atob(token));
    if (data) {
      getUserInfo(data);
    } else {
      console.log("데이터가 없습니다.");
    }
  };

  const getUserInfo = async (access_token: { sub: string }) => {
    try {
      const socialId = access_token.sub;
      const params = {
        body: {
          user_name: socialId,
        },
      };
      const {
        data: { is_new, token, code },
      } = await WEB_API.login.getWebAuthToken(params);

      if (is_new) {
        navigate("/login/created_account", { state: { code } });
      } else {
        localStorage.setItem("token", token);
        const pathname = sessionStorage.getItem("pathname");
        const category = sessionStorage.getItem("category");
        const subject = sessionStorage.getItem("subject");
        setSubject(subject);
        setCategory(category);

        if (pathname) {
          sessionStorage.setItem("category", "");
          sessionStorage.setItem("subject", "");
          sessionStorage.setItem("pathname", "");
          navigate(pathname);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getToken();
  }, []);

  return (
    <Col style={{ height: "70vh" }}>
      <CustomLoading size={50} />
    </Col>
  );
};

export default AppleRedirectPage;
