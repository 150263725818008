import React from "react";
import NaverLogo from "../../images/svg/NaverLogo";
import theme from "../../style/theme";
import LoginButton from "./LoginButton";

const NaverLogin = () => {
  const NAVER_CLIENT_ID = `${process.env.REACT_APP_NAVER_CLIENT_ID}`;
  const NAVER_REDIRECT_URL = `${process.env.REACT_APP_NAVER_REDIRECT_URL}`;
  const STATE = "test";
  const NAVER_AUTH_URL = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${NAVER_CLIENT_ID}&state=${STATE}&redirect_uri=${NAVER_REDIRECT_URL}`;

  const handleNaverLogin = () => {
    window.location.href = NAVER_AUTH_URL;
  };

  return (
    <LoginButton
      logo={<NaverLogo />}
      title="네이버로 계속하기"
      bgColor="#03CF5D"
      color={theme.White}
      onClick={handleNaverLogin}
    />
  );
};

export default NaverLogin;
