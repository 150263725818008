import axios from 'axios';

interface IGetKakaoTokenResponse {
    access_token: string;
    expires_in: number;
    refresh_token: string;
    refresh_token_expires_in: number;
    scope: string;
    token_type: string;
}

interface IGetKakaoUserInfoResponse {
    id: number;
    connected_at: string;
    kakao_account: {
        age_range: string;
        age_range_needs_agreement: boolean;
        email: string;
        email_needs_agreement: boolean;
        has_age_range: boolean;
        has_email: boolean;
        has_phone_number: boolean;
        is_email_valid: boolean;
        is_email_verified: boolean;
        phone_number: string;
        phone_number_needs_agreement: boolean;
        profile: {
            is_default_image: boolean;
        };
        nickname: string;
        profile_image_url: string;
        thumbnail_image_url: string;
        profile_needs_agreement: boolean;
    };
    properties: {
        nickname: string;
        profile_image: string;
        thumbnail_image: string;
    };
}

const REST_API_KEY = `${process.env.REACT_APP_KAKAO_REST_API_KEY}`;
const REDIRECT_URI = `${process.env.REACT_APP_KAKAO_REDIRECT_URL}`;
const grant_type = 'authorization_code';
const ConTent_type = 'application/x-www-form-urlencoded;charset=utf-8';

export const getKakaoToken = (KAKAO_CODE: string): Promise<{ data: IGetKakaoTokenResponse }> => {
    const data = axios.post(
        `https://kauth.kakao.com/oauth/token?grant_type=${grant_type}&client_id=${REST_API_KEY}&redirect_uri=${REDIRECT_URI}&code=${KAKAO_CODE}`,
        {
            headers: {
                'Content-type': ConTent_type,
            },
        }
    );
    return data;
};

export const getKakaoUserInfo = (access_token: string): Promise<{ data: IGetKakaoUserInfoResponse }> => {
    const data = axios.get(`https://kapi.kakao.com/v2/user/me`, {
        headers: {
            Authorization: `Bearer ${access_token}`,
            'Content-type': ConTent_type,
        },
    });
    return data;
};
