import React from "react";
import { Pre2 } from "../../style/GlobalStyled";
import theme from "../../style/theme";
import { styled } from "styled-components";

type TBtnType = "Primary" | "Secondary" | "Tertiary" | "Black" | "Gray";

type PropsType = {
  btnType: TBtnType;
  size?: "Full" | "L" | "M" | "S" | "XS";
  title: string;
  disabled?: boolean;
  style?: React.CSSProperties;
  textStyle?: {};
  onClick?: () => void;
  padding?: string;
  leftComponent?: JSX.Element;
  rightComponent?: JSX.Element;
};

const CustomBtn = styled.button<{ disabled: boolean; $category: TBtnType }>`
  cursor: ${({ disabled, $category }) => (disabled ? "default" : "pointer")};
  background-color: ${({ disabled, $category }) =>
    disabled
      ? BUTTON_TYPE[$category].disabledBackgroundColor
      : BUTTON_TYPE[$category].backgroundColor};
  &:hover {
    background-color: ${({ disabled, $category }) =>
      disabled ? null : BUTTON_TYPE[$category].pressedBackgroundColor};
  }
  transition: background-color 0.3s ease;
`;

export const CustomButton = ({
  btnType = "Primary",
  size,
  title,
  disabled,
  style,
  textStyle,
  onClick,
  padding,
  leftComponent,
  rightComponent,
}: PropsType) => {
  return (
    <CustomBtn
      type="button"
      disabled={disabled || false}
      $category={btnType}
      style={{
        borderRadius: size ? SIZE_TYPE[size].borderRadius : 12,
        borderStyle: "solid",
        borderWidth: BUTTON_TYPE[btnType].borderWidth,
        borderColor: disabled
          ? BUTTON_TYPE[btnType].disabledBorderColor
          : BUTTON_TYPE[btnType].borderColor,
        padding: size ? SIZE_TYPE[size].padding : padding,
        boxSizing: "border-box",
        display: "inline-flex",
        width: size ? SIZE_TYPE[size].width : "auto",
        justifyContent: "center",
        ...style,
      }}
      onClick={() => {
        onClick();
      }}
    >
      {Boolean(leftComponent) && leftComponent}
      <Pre2
        style={{
          color: disabled
            ? BUTTON_TYPE[btnType].disabledTextColor
            : BUTTON_TYPE[btnType].textColor,
          lineHeight: size ? SIZE_TYPE[size].lineHeight : "16px",
          fontSize: size ? SIZE_TYPE[size].fontSize : 16,
          fontWeight: size ? SIZE_TYPE[size].fontWeight : "Bold",
          userSelect: "none",
          ...textStyle,
        }}
      >
        {title}
      </Pre2>
      {Boolean(rightComponent) && rightComponent}
    </CustomBtn>
  );
};

const BUTTON_TYPE = {
  Primary: {
    backgroundColor: theme.Blue9,
    disabledBackgroundColor: theme.Blue3,
    pressedBackgroundColor: theme.Blue11,
    textColor: theme.White,
    disabledTextColor: theme.White,
    borderWidth: 0,
    borderColor: theme.Blue9,
    disabledBorderColor: theme.Blue3,
  },
  Secondary: {
    backgroundColor: theme.Blue2,
    disabledBackgroundColor: theme.Blue1,
    pressedBackgroundColor: theme.Blue3,
    textColor: theme.Blue12,
    disabledTextColor: theme.Blue4,
    borderWidth: 1,
    borderColor: theme.Blue2,
    disabledBorderColor: theme.Blue1,
  },
  Tertiary: {
    backgroundColor: theme.White,
    disabledBackgroundColor: theme.White,
    pressedBackgroundColor: theme.Gray1,
    textColor: theme.Gray9,
    disabledTextColor: theme.Gray4,
    borderWidth: 1,
    borderColor: theme.Gray4,
    disabledBorderColor: theme.Gray4,
  },
  Black: {
    backgroundColor: theme.Gray10,
    disabledBackgroundColor: theme.Gray3,
    pressedBackgroundColor: theme.Black,
    textColor: theme.White,
    disabledTextColor: theme.White,
    borderWidth: 0,
    borderColor: theme.Gray10,
    disabledBorderColor: theme.Gray3,
  },
  Gray: {
    backgroundColor: theme.Gray2,
    disabledBackgroundColor: theme.Gray3,
    pressedBackgroundColor: theme.Gray3,
    textColor: theme.Gray9,
    disabledTextColor: theme.White,
    borderWidth: 0,
    borderColor: theme.Gray2,
    disabledBorderColor: theme.Gray3,
  },
};

const SIZE_TYPE = {
  Full: {
    width: "100%",
    height: "auto",
    padding: "16px 0",
    borderRadius: 12,
    fontWeight: "Bold",
    fontSize: 16,
    lineHeight: "16px",
  },
  L: {
    width: "auto",
    height: "auto",
    padding: "16px 20px",
    borderRadius: 12,
    fontWeight: "Bold",
    fontSize: 16,
    lineHeight: "16px",
  },
  M: {
    width: "auto",
    height: "auto",
    padding: "12px 16px",
    borderRadius: 12,
    fontWeight: "Medium",
    fontSize: 16,
    lineHeight: "16px",
  },
  S: {
    width: "auto",
    height: "auto",
    padding: "8px 12px",
    borderRadius: 8,
    fontWeight: "Medium",
    fontSize: 14,
    lineHeight: "14px",
  },
  XS: {
    width: "auto",
    height: "auto",
    padding: "8px",
    borderRadius: 8,
    fontWeight: "Medium",
    fontSize: 12,
    lineHeight: "12px",
  },
};
