const midMathClassHighData = {
  title: "세부과목",
  type: "select",
  list: [
    {
      text: "중학수학 1-1",
      title: "대단원",
      type: "check",
      list: [
        { text: "소인수분해", title: "대단원", id: "소인수분해" },
        { text: "정수와 유리수", title: "대단원", id: "정수와 유리수" },
        { text: "방정식", title: "대단원", id: "방정식" },
        { text: "그래프와 비례", title: "대단원", id: "그래프와 비례" },
      ],
    },
    {
      text: "중학수학 1-2",
      title: "대단원",
      type: "check",
      list: [
        { text: "기본 도형", title: "대단원", id: "기본 도형" },
        { text: "평면도형", title: "대단원", id: "평면도형" },
        { text: "입체도형", title: "대단원", id: "입체도형" },
        { text: "통계", title: "대단원", id: "통계" },
      ],
    },
    {
      text: "중학수학 2-1",
      title: "대단원",
      type: "check",
      list: [
        { text: "수와 식", title: "대단원", id: "수와 식" },
        { text: "부등식", title: "대단원", id: "부등식" },
        { text: "방정식", title: "대단원", id: "방정식" },
        { text: "함수", title: "대단원", id: "함수" },
      ],
    },
    {
      text: "중학수학 2-2",
      title: "대단원",
      type: "check",
      list: [
        { text: "삼각형의 성질", title: "대단원", id: "삼각형의 성질" },
        { text: "사각형의 성질", title: "대단원", id: "사각형의 성질" },
        { text: "도형의 닮음", title: "대단원", id: "도형의 닮음" },
        { text: "피타고라스 정리", title: "대단원", id: "피타고라스 정리" },
        { text: "확률", title: "대단원", id: "확률" },
      ],
    },
    {
      text: "중학수학 3-1",
      title: "대단원",
      type: "check",
      list: [
        { text: "제곱근과 실수", title: "대단원", id: "제곱근과 실수" },
        {
          text: "다항식의 곱셈과 인수분해",
          title: "대단원",
          id: "다항식의 곱셈과 인수분해",
        },
        { text: "이차방정식", title: "대단원", id: "이차방정식" },
        { text: "이차함수", title: "대단원", id: "이차함수" },
      ],
    },
    {
      text: "중학수학 3-2",
      title: "대단원",
      type: "check",
      list: [
        { text: "삼각비", title: "대단원", id: "삼각비" },
        { text: "원의 성질", title: "대단원", id: "원의 성질" },
        { text: "통계", title: "대단원", id: "통계" },
      ],
    },
  ],
};
export default midMathClassHighData;
