import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import { categoryState, subjectState } from "../atoms/atoms";
import FilterCategory from "../components/Molecules/FIlterCategory";
import ObjectFilter from "../components/Molecules/ObjectFilter";
import FilterResultList from "../components/Organisms/FilterResultList";
import subjectDataToList from "../function/subjectDataToList";
import { Col, Pre1, Row } from "../style/GlobalStyled";
import theme from "../style/theme";
import { useResponsive } from "../utils/responsive";
import { subjectData } from "../utils/subjectData";
import Overlay from "../components/Molecules/Overlay";
import { useCheryExamList } from "../query/useCheryExamQuery";
import AcademyForm from "../components/Molecules/AcademyForm";

const CATEGORY = {
  english: ["대표유형", "실력별학습"],
  korean: ["대표유형", "실력별학습"],
  math: ["대표유형", "실력별학습"],
  society: ["대표유형", "실력별학습"],
  science: ["대표유형", "실력별학습"],
  history: ["대표유형", "실력별학습"],
};

const PracticalPage = ({ schoolType }: { schoolType: string }) => {
  const { isTablet, isMobile, HORIZONTAL_MARGIN, isDeskTop } = useResponsive();
  const [subject] = useRecoilState(subjectState);

  const location = useLocation();

  const school = location.pathname.split("/")[1];
  const [category, setCategory] = useRecoilState(categoryState);
  const [isOpen, setIsOpen] = useState(false);
  const currentData = subjectData[subject][school];
  const initSelectedFilter =
    subject === "english" || subject === "korean" ? [0, 0, []] : [0, []];
  const [selectedFilter, setSelectedFilter] =
    useState<SelectedFilterType>(initSelectedFilter);
  const [queryFilter, setQueryFilter] = useState({
    subject,
    category,
    section: currentData.list[initSelectedFilter[0] as number].text,
    publisher_author:
      typeof initSelectedFilter[1] === "number"
        ? `${
            currentData.list[initSelectedFilter[0] as number].list[
              initSelectedFilter[1]
            ].text
          }`
        : "",
    meta_id_list: [],
    school_type: schoolType,
  });
  const { data } = useCheryExamList(queryFilter);

  const getSubjectConceptCompletionList = async (
    initSelectedFilter?: SelectedFilterType
  ) => {
    try {
      const paramsSelectedFilter = Boolean(initSelectedFilter)
        ? initSelectedFilter
        : selectedFilter;

      const section = currentData.list[paramsSelectedFilter[0] as number].text;
      const publisher_author =
        typeof paramsSelectedFilter[1] === "number"
          ? `${
              currentData.list[paramsSelectedFilter[0] as number].list[
                paramsSelectedFilter[1]
              ].text
            }`
          : "";
      setQueryFilter({
        section,
        publisher_author,
        subject: subject,
        category,
        meta_id_list: paramsSelectedFilter[
          paramsSelectedFilter.length - 1
        ] as number[],
        school_type: schoolType,
      });
    } catch (e) {
      console.log(e, "getSubjectConceptCompletionList");
    }
  };

  useEffect(() => {
    const init = subjectDataToList(currentData);
    setSelectedFilter(init);
    getSubjectConceptCompletionList(init);
  }, [subject, category]);

  return (
    <>
      <Row
        style={{
          height: "auto",
          width: isDeskTop ? 960 : "100%",
          alignItems: "flex-start",
          flexWrap: "wrap",
        }}
      >
        {isDeskTop ? (
          <Col
            style={{
              height: "auto",
              width: 222,
              marginRight: 24,
            }}
          >
            <FilterCategory
              list={CATEGORY[subject]}
              category={category}
              setCategory={setCategory}
            />
            <AcademyForm />
          </Col>
        ) : (
          <FilterCategory
            list={CATEGORY[subject]}
            category={category}
            setCategory={setCategory}
          />
        )}
        <Col
          style={{
            height: "auto",
            justifyContent: "flex-start",
            width: "100%",
            maxWidth: isDeskTop ? 714 : undefined,
          }}
        >
          {isDeskTop && (
            <Row
              style={{
                height: "auto",
                justifyContent: "flex-start",
                marginBottom: 24,
              }}
            >
              <Pre1 color={theme.Black}>{category}</Pre1>
            </Row>
          )}
          <ObjectFilter
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
            data={currentData}
            onClickSubmit={getSubjectConceptCompletionList}
          />

          <FilterResultList
            section={data.title}
            list={data.list}
            headerTitleList={
              category === "실력별학습"
                ? isDeskTop
                  ? ["난이도", "정보", "다운로드"]
                  : ["정보", "다운로드"]
                : ["정보", "다운로드"]
            }
            headerFlexList={
              category === "실력별학습"
                ? isDeskTop
                  ? [0.15, 0.65, 0.2]
                  : [0.8, 0.2]
                : [0.8, 0.2]
            }
            idList={data.idList}
          />
        </Col>
      </Row>
      {(isTablet || isMobile) && (
        <Col
          style={{
            position: "fixed",
            zIndex: 1,
            height: "auto",
            bottom: isMobile ? (isOpen ? 0 : 36) : 40,
            padding: isMobile ? (isOpen ? 0 : "0px 20px") : "0px 40px",
            alignItems: "flex-end",
          }}
        >
          <AcademyForm setIsOpen={setIsOpen} isOpen={isOpen} />
        </Col>
      )}
      <Overlay />
    </>
  );
};

export default PracticalPage;
