const historyClassHighData = {
  title: "세부과목",
  type: "select",
  list: [
    {
      text: "한국사",
      title: "대단원",
      type: "check",
      list: [
        {
          text: "전근대 한국사의 이해",
          title: "대단원",
          id: "전근대 한국사의 이해",
        },
        {
          text: "근대 국민 국가 수립 운동",
          title: "대단원",
          id: "근대 국민 국가 수립 운동",
        },
        {
          text: "일제 식민지 지배와 민족 운동의 전개",
          title: "대단원",
          id: "일제 식민지 지배와 민족 운동의 전개",
        },
        { text: "대한민국의 발전", title: "대단원", id: "대한민국의 발전" },
      ],
    },
  ],
};

export default historyClassHighData;
