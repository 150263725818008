import { useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useCheryExamAPI from "../../hooks/useCheryExamAPI";
import React from "react";
import { Col } from "../../style/GlobalStyled";
import CustomLoading from "../Atoms/CustomLoading";
import { useRecoilState } from "recoil";
import { categoryState, subjectState } from "../../atoms/atoms";

const NaverRedirectPage = () => {
  const [WEB_API] = useMemo(useCheryExamAPI, []);
  const navigate = useNavigate();
  const location = useLocation();
  const NAVER_CODE = location.search.split("=")[1].split("&")[0];
  const [currentSubject, setSubject] = useRecoilState(subjectState);
  const [currentCategory, setCategory] = useRecoilState(categoryState);
  const getToken = async () => {
    const params = {
      code: NAVER_CODE,
    };

    try {
      const {
        data: { data },
      } = await WEB_API.login.getNaverToken(params);

      getUserInfo(data.access_token);
    } catch (e) {
      console.log(e);
    }
  };

  const getUserInfo = async (access_token: string) => {
    const params = {
      access_token,
    };

    try {
      const {
        data: {
          data: { response },
        },
      } = await WEB_API.login.getNaverUserInfo(params);

      const socialId = response.id + "_naver";
      const tokenParams = {
        body: {
          user_name: socialId,
        },
      };

      const {
        data: { is_new, token, code },
      } = await WEB_API.login.getWebAuthToken(tokenParams);

      if (is_new) {
        navigate("/login/created_account", { state: { code } });
      } else {
        localStorage.setItem("token", token);
        const pathname = sessionStorage.getItem("pathname");
        const category = sessionStorage.getItem("category");
        const subject = sessionStorage.getItem("subject");
        setSubject(subject);
        setCategory(category);
        if (pathname) {
          sessionStorage.setItem("category", "");
          sessionStorage.setItem("subject", "");
          sessionStorage.setItem("pathname", "");
          navigate(pathname);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getToken();
  }, []);

  return (
    <Col style={{ height: "70vh" }}>
      <CustomLoading size={50} />
    </Col>
  );
};

export default NaverRedirectPage;
