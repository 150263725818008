import React from "react";
import AppleLogin from "../components/Login/AppleLogin";
import KakaoLogin from "../components/Login/KakaoLogin";
import NaverLogin from "../components/Login/NaverLogin";
import LoginImg from "../images/png/LoginImg.png";
import MobileLogin from "../images/png/MobileLogin.png";
import { Col, Pre1, WhiteSpace } from "../style/GlobalStyled";
import { useResponsive } from "../utils/responsive";

const Login = () => {
  const { isTablet, isMobile } = useResponsive();

  return (
    <Col style={{ height: "auto", padding: isMobile ? "0px 40px" : 0 }}>
      <Col
        style={{
          height: "auto",
          alignItems: "flex-start",
          maxWidth: 520,
        }}
      >
        <Pre1
          style={{
            fontSize: isMobile ? "20px" : isTablet ? "24px" : "32px",
            marginBottom: 24,
          }}
        >
          로그인
        </Pre1>
        <img
          src={isMobile ? MobileLogin : LoginImg}
          alt="login_image"
          style={{ width: "100%", height: "224px" }}
        />
        <Col style={{ height: "auto", width: "100%", margin: "32px 0" }}>
          <KakaoLogin />
          <WhiteSpace height={12} />
          <AppleLogin />
          <WhiteSpace height={12} />
          <NaverLogin />
        </Col>
      </Col>
    </Col>
  );
};

export default Login;
