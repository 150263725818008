import React, { useMemo } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useCheryExamAPI from "../../hooks/useCheryExamAPI";
import { getKakaoToken, getKakaoUserInfo } from "../API/kakao_login";
import CustomLoading from "../Atoms/CustomLoading";
import { Col } from "../../style/GlobalStyled";
import { categoryState, subjectState } from "../../atoms/atoms";
import { useRecoilState } from "recoil";

const KakaoRedirectPage = () => {
  const { localStorage } = window;
  const [currentSubject, setSubject] = useRecoilState(subjectState);
  const [currentCategory, setCategory] = useRecoilState(categoryState);
  const location = useLocation();
  const navigate = useNavigate();
  const [WEB_API] = useMemo(useCheryExamAPI, []);

  const KAKAO_CODE = location.search.split("=")[1];

  const getToken = async () => {
    try {
      const { data } = await getKakaoToken(KAKAO_CODE);
      getUserInfo(data.access_token);
    } catch (e) {
      console.log("getToken", e);
    }
  };

  const getUserInfo = async (access_token: string) => {
    try {
      const { data } = await getKakaoUserInfo(access_token);
      const socialId = data.id + "_kakao";
      const params = {
        body: {
          user_name: socialId,
        },
      };
      const {
        data: { is_new, token, code },
      } = await WEB_API.login.getWebAuthToken(params);

      if (is_new) {
        navigate("/login/created_account", { state: { code } });
      } else {
        localStorage.setItem("token", token);
        const pathname = sessionStorage.getItem("pathname");
        const category = sessionStorage.getItem("category");
        const subject = sessionStorage.getItem("subject");
        setSubject(subject);
        setCategory(category);
        if (pathname) {
          sessionStorage.setItem("category", "");
          sessionStorage.setItem("subject", "");
          sessionStorage.setItem("pathname", "");
          navigate(pathname);
        } else {
          navigate("/");
        }
      }
    } catch (e) {
      console.log("getUserInfo", e);
    }
  };

  useEffect(() => {
    getToken();
  }, []);

  return (
    <Col style={{ height: "70vh" }}>
      <CustomLoading size={50} />
    </Col>
  );
};

export default KakaoRedirectPage;
