import React, { Dispatch, SetStateAction, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
  categoryState,
  headerOpenState,
  subjectState,
} from "../../atoms/atoms";
import CheryLogo from "../../images/icon/CheryLogo";
import Hamburger from "../../images/icon/Hamburger";
import { Col, Pre2, Pre3, Pre4, Row } from "../../style/GlobalStyled";
import theme from "../../style/theme";
import CustomChip from "../Atoms/CustomChip";

type PropsType = {
  school: string;
  topMenu: { title: string; path: string; onClick: () => void }[];
  categoryList?: {
    title: string;
    path: string;
    list: string[];
  }[];
  subjectList?: { title: string; value: string }[];
  subject?: string;
  setSubject: Dispatch<SetStateAction<string>>;
  setRecoilSubject: Dispatch<SetStateAction<string>>;
};
const DesktopHeader = ({
  school,
  categoryList,
  subjectList,
  subject,
  setSubject,
}: PropsType) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const pageName = pathname.split("/")[2];
  const [recoilHeaderOpen, setRecoilHeaderOpen] =
    useRecoilState(headerOpenState);
  const [category, setCategory] = useRecoilState(categoryState);
  const [recoilSubject, setRecoilSubject] = useRecoilState(subjectState);

  const headerRef = useRef(null);

  useEffect(() => {
    const outSideClick = (e) => {
      if (
        recoilHeaderOpen &&
        headerRef.current &&
        !headerRef.current.contains(e.target)
      ) {
        setRecoilHeaderOpen(false);
      }
    };
    document.addEventListener("mousedown", outSideClick);
    document.body.style.overflow = recoilHeaderOpen ? "hidden" : "auto";
  }, [recoilHeaderOpen]);

  useEffect(() => {
    if (categoryList.filter((v) => v.path === pageName).length > 0) {
      setCategory(categoryList.filter((v) => v.path === pageName)[0].list[0]);
    }
  }, []);

  return (
    <Col
      style={{
        height: "auto",
        alignItems: "flex-start",
        zIndex: 10,
        backgroundColor: theme.White,
        position: "sticky",
        top: 0,
        paddingTop: 16,
      }}
      ref={headerRef}
    >
      <Row
        style={{
          height: "auto",
          width: "auto",
          gap: "8px",
          marginBottom: 16,
          padding: "0px 60px",
        }}
      >
        {subjectList.map((v, index) => {
          const selected = subject === v.value;
          return (
            <CustomChip
              key={`subject_${index}`}
              size="M"
              title={
                school === "high" && v.title === "역사" ? "한국사" : v.title
              }
              type="Tertiary"
              isSelected={selected}
              onClick={() => {
                setSubject(v.value);
                setRecoilHeaderOpen(true);
              }}
            />
          );
        })}
      </Row>
      <Row
        style={{
          height: "auto",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          gap: 60,
          padding: recoilHeaderOpen ? "16px 60px 4px" : "16px 60px",
          backgroundColor: theme.Blue1,
          borderTop: `1px solid ${theme.Blue3}`,
          borderBottom: `1px solid ${theme.Blue3}`,
        }}
      >
        <Col
          style={{
            width: "auto",
            height: "auto",
            cursor: "pointer",
          }}
          onClick={() => {
            setRecoilHeaderOpen((prev) => !prev);
          }}
        >
          <Hamburger color={recoilHeaderOpen ? theme.Blue12 : theme.Gray6} />
        </Col>

        <Row
          style={{
            height: "auto",
            width: "auto",
            gap: 60,
            alignItems: "flex-start",
            userSelect: "none",
          }}
        >
          {categoryList.map((v, index) => {
            const { list } = v;
            const bigCategorySelected = pageName === v.path;
            return (
              <Col
                style={{
                  width: "auto",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
                key={`categoryList_${index}`}
              >
                <Pre3
                  key={`desktop_big_${index}`}
                  fontWeight={"Bold"}
                  style={{
                    width: 90,
                    cursor: "pointer",
                    userSelect: "none",
                    lineHeight: "24px",
                  }}
                  color={bigCategorySelected ? theme.Blue12 : theme.Black}
                  onClick={() => {
                    if (school === "middle") {
                      navigate(`middle/${v.path}`);
                      setCategory(list[0]);
                      setRecoilSubject(subject);
                      setRecoilHeaderOpen(false);
                    } else {
                      navigate(`high/${v.path}`);
                      setCategory(list[0]);
                      setRecoilSubject(subject);
                      setRecoilHeaderOpen(false);
                    }
                  }}
                >
                  {v.title}
                </Pre3>
                {recoilHeaderOpen && (
                  <Col
                    style={{
                      width: "auto",
                      height: "auto",
                      alignItems: "flex-start",
                      marginTop: 16,
                    }}
                  >
                    {list?.map((k, index) => {
                      const selected =
                        category === k && subject === recoilSubject;
                      return (
                        <Pre3
                          key={`desktop_small_${index}`}
                          color={selected ? theme.Blue12 : theme.Black}
                          onClick={() => {
                            if (school === "middle") {
                              navigate(`middle/${v.path}`);
                              setCategory(k);
                              setRecoilSubject(subject);
                              setRecoilHeaderOpen(false);
                            } else {
                              navigate(`high/${v.path}`);
                              setCategory(k);
                              setRecoilSubject(subject);
                              setRecoilHeaderOpen(false);
                            }
                          }}
                          fontWeight="Regular"
                          style={{
                            marginBottom: "16px",
                            cursor: "pointer",
                          }}
                        >
                          {k}
                        </Pre3>
                      );
                    })}
                  </Col>
                )}
              </Col>
            );
          })}
        </Row>
      </Row>
    </Col>
  );
};

const DesktopLogoHeader = ({
  topMenu,
  school,
  setSubject,
  setRecoilSubject,
}: PropsType) => {
  const navigate = useNavigate();
  const setCategory = useRecoilState(categoryState)[1];
  const { pathname } = useLocation();
  return pathname === "/login" || pathname === "/login/created_account" ? (
    <Row
      style={{
        height: "auto",
        justifyContent: "flex-start",
        padding: "32px 60px 0",
      }}
    >
      <Row
        style={{
          width: "auto",
          height: "auto",
          cursor: "pointer",
          userSelect: "none",
        }}
        onClick={() => {
          navigate("/");
        }}
      >
        <CheryLogo />
      </Row>
    </Row>
  ) : (
    <Row
      style={{
        height: "auto",
        gap: "48px",
        padding: "32px 60px 0",
      }}
    >
      <span
        style={{ cursor: "pointer" }}
        onClick={() => {
          navigate("/");
        }}
      >
        <CheryLogo />
      </span>
      <Row
        style={{
          flex: 1,
          height: "auto",
          justifyContent: "flex-start",
        }}
      >
        <Pre2
          onClick={() => {
            setSubject("english");
            setRecoilSubject("english");
            setCategory("우리학교기출");
            navigate("/middle/SchoolPage");
          }}
          fontWeight="Medium"
          color={school === "middle" ? theme.Black : theme.Gray5}
          style={{ cursor: "pointer", userSelect: "none" }}
        >
          중등
        </Pre2>
        <Col
          style={{
            width: "1px",
            height: "20px",
            backgroundColor: `${theme.Gray5}`,
            margin: "0 20px",
          }}
        />
        <Pre2
          onClick={() => {
            setSubject("english");
            setRecoilSubject("english");
            setCategory("우리학교기출");
            navigate("/high/SchoolPage");
          }}
          fontWeight="Medium"
          color={school === "high" ? theme.Black : theme.Gray5}
          style={{ cursor: "pointer", userSelect: "none" }}
        >
          고등
        </Pre2>
      </Row>
      <Row
        style={{
          flex: 1,
          width: "auto",
          height: "auto",
          justifyContent: "flex-end",
        }}
      >
        <Row style={{ justifyContent: "space-between", maxWidth: "340px" }}>
          {topMenu.map((v, index) => {
            return (
              <Pre4
                key={`desktop_top_menu_${index}`}
                onClick={v.onClick}
                fontWeight="Regular"
                color={pathname === v.path ? theme.Blue12 : theme.Gray6}
                style={{ cursor: "pointer", userSelect: "none" }}
              >
                {v.title}
              </Pre4>
            );
          })}
        </Row>
      </Row>
    </Row>
  );
};

export { DesktopHeader, DesktopLogoHeader };
