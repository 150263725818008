import LoginButton from "./LoginButton";
import KakaoLogo from "../../images/svg/KakaoLogo";
import React from "react";

const KakaoLogin = () => {
  const REST_API_KEY = `${process.env.REACT_APP_KAKAO_REST_API_KEY}`;
  const REDIRECT_URI = `${process.env.REACT_APP_KAKAO_REDIRECT_URL}`;
  const kakaoURL = `https://kauth.kakao.com/oauth/authorize?client_id=${REST_API_KEY}&redirect_uri=${REDIRECT_URI}&response_type=code`;

  const handleKakaoLogin = () => {
    window.location.href = kakaoURL;
  };

  return (
    <LoginButton
      logo={<KakaoLogo />}
      title="카카오로 계속하기"
      bgColor="#F9E000"
      color="#3B1E1E"
      onClick={handleKakaoLogin}
    />
  );
};

export default KakaoLogin;
