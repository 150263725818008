import * as React from 'react';

function KakaoLogo(props) {
    return (
        <svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                opacity={0.902}
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9 .476c-4.971 0-9 3.129-9 6.989 0 2.4 1.558 4.517 3.932 5.775l-.999 3.667c-.088.324.28.582.563.394l4.377-2.904c.37.036.745.057 1.127.057 4.97 0 8.1-3.129 8.1-6.99 0-3.86-3.13-6.988-8.1-6.988z"
                fill="#000"
            />
        </svg>
    );
}

export default KakaoLogo;
