import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { categoryState, subjectState } from "../atoms/atoms";
import CustomLoading from "../components/Atoms/CustomLoading";
import DesktopMyPage from "../components/Molecules/DesktopMyPage";
import TabletMyPage from "../components/Molecules/TabletMyPage";
import catchError from "../function/catchError";
import getToken from "../function/getToken";
import useCheryExamAPI from "../hooks/useCheryExamAPI";
import { useResponsive } from "../utils/responsive";

const MyPage = () => {
  const [CHERY_EXAM_API] = useMemo(useCheryExamAPI, []);
  const [userInfo, setUserInfo] = useState({
    grade: "",
    imgUrl: "",
    nickname: "",
    passList: [],
    school: "",
    total_cnt: 0,
  });
  const [subject] = useRecoilState(subjectState);
  const [category] = useRecoilState(categoryState);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { isTablet, isMobile } = useResponsive();
  const loadingRef = useRef(null);

  const getMyPageInfoHandler = async () => {
    try {
      loadingRef.current?.show();
      const session = await getToken();
      if (session?.token?.accessToken) {
        const params = {
          token: session?.token?.accessToken,
        };
        const { data } = await CHERY_EXAM_API.purchase.getMyPageInfo(params);
        setUserInfo(data);
      }
    } catch (e) {
      await catchError({ e, navigate, pathname, category, subject });
      console.log(e, "getMyPageInfoHandler");
    } finally {
      loadingRef.current?.hide();
    }
  };

  useEffect(() => {
    getMyPageInfoHandler();
  }, []);

  return isTablet || isMobile ? (
    <>
      <TabletMyPage userInfo={userInfo} mobile={isMobile} />
      <CustomLoading
        ref={loadingRef}
        size={60}
        init={false}
        backgroundColor={"rgba(255,255,255,0.2)"}
        style={{ position: "fixed", top: 0, left: 0 }}
      />
    </>
  ) : (
    <>
      <DesktopMyPage userInfo={userInfo} />
      <CustomLoading
        ref={loadingRef}
        size={60}
        init={false}
        backgroundColor={"rgba(255,255,255,0.2)"}
        style={{ position: "fixed", top: 0, left: 0 }}
      />
    </>
  );
};

export default MyPage;
