import React, { useMemo, useRef, useState } from "react";
import FileDownLoad from "../../images/svg/FileDownLoad";
import {
  Col,
  Pre3,
  Pre4,
  Pre5,
  Row,
  WhiteSpace,
} from "../../style/GlobalStyled";
import theme from "../../style/theme";
import { useResponsive } from "../../utils/responsive";
import { CustomButton } from "../Atoms/CustomButton";
import CustomTag from "../Atoms/CustomTag";
import { useLocation, useNavigate } from "react-router-dom";
import useCheryExamAPI from "../../hooks/useCheryExamAPI";
import categoryObj from "../../utils/categoryTypeData";
import { useRecoilState } from "recoil";
import { categoryState, subjectState } from "../../atoms/atoms";
import CustomModal from "../Atoms/CustomModal";
import CustomAlertModal from "../Atoms/CustomAlertModal";
import getToken from "../../function/getToken";
import CustomLoading from "../Atoms/CustomLoading";
import JSZip from "jszip";
import { saveAs } from "file-saver";

interface PropsType {
  section?: string;
  list?: any;
  headerTitleList?: string[];
  headerFlexList?: number[];
  onPurchase?: ({ id, isAll, idList, fileName }) => void;
  idList?: number[];
}

const FilterResultList = (props: PropsType) => {
  const { section, list, headerTitleList, headerFlexList, idList } = props;
  const { HORIZONTAL_MARGIN, isDeskTop, isMobile } = useResponsive();
  const totalCount = idList.length;
  const modalRef = useRef({});
  const loadingRef = useRef(null);
  const navigate = useNavigate();
  const [CHERY_EXAM_API] = useMemo(useCheryExamAPI, []);
  const [category] = useRecoilState(categoryState);
  const [subject] = useRecoilState(subjectState);

  const categoryType = categoryObj[category];
  const { pathname } = useLocation();

  const onCustomModal = (name: string) => {
    document.body.style.overflow = "hidden";
    modalRef.current?.[name]?.show?.();
  };

  const offCustomModal = (name: string) => {
    document.body.style.overflow = "auto";
    modalRef.current?.[name]?.hide?.();
  };

  const downloadFiles = async ({ urls, fileName }) => {
    if (urls.length === 1) {
      const response = await fetch(urls[0]);
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = `${fileName}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else if (urls.length > 1) {
      const zip = new JSZip();
      let urlIndex = 0;
      for (let i = 0; i < list.length; i++) {
        const item = list[i];
        for (let j = 0; j < item.list.length; j++) {
          const url = urls[urlIndex];
          const response = await fetch(url);
          const arrayBuffer = await response.arrayBuffer();
          zip.file(`${item.list[j].name}.pdf`, arrayBuffer);
          urlIndex++;
        }
      }

      // zip 파일 생성
      zip.generateAsync({ type: "blob" }).then(function (content) {
        saveAs(content, "archive.zip");
      });
    }
  };

  const onPurchase = async ({ id, isAll, idList, fileName }) => {
    try {
      loadingRef.current?.show();
      const session = await getToken();
      const params = {
        token: session?.token?.accessToken,
        length: isAll ? idList.length : 1,
      };
      const { data } = await CHERY_EXAM_API.purchase.getPdfLink(params);
      loadingRef.current?.hide();
      if (data.msg === "NOT_PURCHASED") {
        modalRef.current?.["alert"].setPropsData({
          type: "alert",
          title: "사용 중인 이용권이 없어요",
          text: "이용권 구매 화면으로 이동할게요!",
          buttons: [
            {
              text: "확인",
              type: "Primary",
              onClick: () => {
                offCustomModal("alert");
                navigate("/purchase");
              },
            },
          ],
        });
        onCustomModal("alert");
      } else if (data.msg === "LACK") {
        modalRef.current?.["alert"].setPropsData({
          type: "check",
          title: "다운로드 실패",
          text: (
            <Col style={{ height: "auto", marginBottom: 24 }}>
              <Pre4 fontWeight={"Regular"} style={{ marginBottom: 20 }}>
                잔여 다운로드 횟수가 부족합니다.
              </Pre4>
              <Pre4 fontWeight={"Regular"} color={theme.Gray6}>
                현재 잔여 횟수: {data.cnt}건
              </Pre4>
              <Pre4 fontWeight={"Regular"} color={theme.BoldRed}>
                필요 잔여 횟수: {isAll ? idList.length : "1"}건
              </Pre4>
            </Col>
          ),
          buttons: [
            {
              text: "이용권 구매하러가기",
              type: "Primary",
              onClick: () => {
                navigate("/purchase");
              },
            },
          ],
        });
        onCustomModal("alert");
      } else if (data.msg === "OVER_LENGTH") {
        modalRef.current?.["alert"].setPropsData({
          type: "check",
          title: "일괄 다운로드 개수 제한",
          text: (
            <Col style={{ height: "auto", marginBottom: 24 }}>
              <Pre4
                fontWeight={"Regular"}
                style={{ marginBottom: 20, marginTop: 4 }}
              >
                파일이 100개를 초과하여 일괄 다운로드가 어려워요.{"\n"}99개
                이하로 선택해주세요!
              </Pre4>
            </Col>
          ),
          buttons: [
            {
              text: "확인",
              type: "Primary",
              onClick: () => {
                offCustomModal("alert");
              },
            },
          ],
        });
        onCustomModal("alert");
      } else if (data.msg === "LOCK") {
        modalRef.current?.["alert"].setPropsData({
          type: "check",
          title: `다운로드 ${isAll ? idList.length : 1}건 차감`,
          text: (
            <Col style={{ height: "auto", marginBottom: 24 }}>
              <Pre4
                fontWeight={"Regular"}
                style={{ marginBottom: 20, marginTop: 4 }}
              >
                잔여 다운로드 횟수에서 {isAll ? idList.length : 1}건이
                차감됩니다.
              </Pre4>
              <Pre4 fontWeight={"Regular"} color={theme.Gray6}>
                현재 잔여 횟수: {data.cnt}건
              </Pre4>
              <Pre4 fontWeight={"Regular"} color={theme.Blue10}>
                차감 이후 횟수: {data.cnt - (isAll ? idList.length : 1)}건
              </Pre4>
            </Col>
          ),
          buttons: [
            {
              text: "취소",
              type: "Tertiary",
              onClick: () => {
                offCustomModal("alert");
              },
            },
            {
              text: "다운로드",
              type: "Primary",
              onClick: async () => {
                try {
                  loadingRef.current?.show();
                  const params = {
                    body: {
                      token: session?.token?.accessToken,
                      type:
                        categoryType === "GangnamZocbo"
                          ? "SchoolZocbo"
                          : categoryType,
                      idList: isAll ? idList : [id],
                    },
                  };
                  const { data } =
                    await CHERY_EXAM_API.purchase.insertUnlockContent(params);
                  await downloadFiles({ urls: data.link, fileName });
                  offCustomModal("alert");
                } catch (err) {
                  console.log(err);
                } finally {
                  loadingRef.current?.hide();
                }
              },
            },
          ],
        });
        onCustomModal("alert");
      }
    } catch (e) {
      if (
        e?.message === "Token not found" ||
        e?.response?.data?.msg === "INVALID_TOKEN"
      ) {
        localStorage.setItem("token", "");
        modalRef.current?.["alert"].setPropsData({
          type: "alert",
          title: "로그인 미완료",
          text: "다운로드 전 로그인을 먼저 진행해주세요!",
          buttons: [
            {
              text: "확인",
              type: "Primary",
              onClick: async () => {
                loadingRef.current?.hide();
                sessionStorage.setItem("subject", subject);
                sessionStorage.setItem("category", category);
                sessionStorage.setItem("pathname", pathname);
                await navigate("/login", { replace: true }); // 유효하지 않은 경우 로그인 페이지로 이동
                offCustomModal("alert");
              },
            },
          ],
        });
        onCustomModal("alert");
      }
    } finally {
    }
  };

  if (list.length === 0) {
    return (
      <Col style={{ height: "auto", padding: "52px 0", marginBottom: 80 }}>
        <Pre3 fontWeight="Medium" color={theme.Gray5}>
          조건에 해당하는 파일이 없습니다.
        </Pre3>
      </Col>
    );
  }

  return (
    <Col
      style={{
        width: isDeskTop ? 714 : "100%",
        height: "auto",
        marginBottom: 40,
      }}
    >
      <Row
        style={{
          justifyContent: "space-between",
          width: isDeskTop ? "100%" : `calc(100% - ${2 * HORIZONTAL_MARGIN}px)`,
          maxWidth: 714,
        }}
      >
        <Col
          style={{
            height: "auto",
            alignItems: "flex-start",
            flex: 1,
          }}
        >
          {Boolean(section) && isDeskTop && (
            <Pre3
              fontWeight="Regular"
              color={theme.Gray8}
              style={{ marginBottom: 8 }}
            >
              {section}
            </Pre3>
          )}
          <Pre4
            fontWeight="Medium"
            color={theme.Gray5}
          >{`총 ${totalCount}개`}</Pre4>
        </Col>
        <Row
          onClick={() => {
            onPurchase({ id: null, isAll: true, idList, fileName: "" });
          }}
          style={{
            width: "auto",
            padding: "0 12px",
            borderRadius: 8,
            border: `1px solid ${theme.Gray4}`,
            height: 30,
            alignSelf: "flex-end",
            cursor: "pointer",
          }}
        >
          <Pre4 color={theme.Gray9} fontWeight="Medium">
            일괄 다운로드
          </Pre4>
        </Row>
      </Row>
      <WhiteSpace height={16} />
      {Boolean(headerTitleList) && (
        <Row
          style={{
            height: isDeskTop ? 54 : 36,
            borderTop: `1px solid ${theme.Gray3}`,
            borderBottom: `1px solid ${theme.Gray3}`,
            backgroundColor: theme.Gray1,
          }}
        >
          {headerTitleList.map((v, index) => {
            return (
              <Col
                key={`headerTitleList_${index}`}
                style={{
                  flex: headerFlexList[index],
                }}
              >
                {isDeskTop ? (
                  <Pre4
                    fontWeight="Medium"
                    color={theme.Gray8}
                    style={{ userSelect: "none" }}
                  >
                    {v}
                  </Pre4>
                ) : (
                  <Pre5
                    fontWeight="Medium"
                    color={theme.Gray8}
                    style={{ userSelect: "none" }}
                  >
                    {v}
                  </Pre5>
                )}
              </Col>
            );
          })}
        </Row>
      )}
      {list.length > 0 &&
        list.map((v, index) => {
          return (
            <Col style={{ height: "auto" }} key={`listItem_${index}`}>
              {Boolean(v.title) && (
                <Row
                  style={{
                    backgroundColor: theme.Blue2,
                    height: 48,
                    justifyContent: "flex-start",
                    padding: isMobile ? "0 20px" : "0 40px",
                  }}
                >
                  <Pre3
                    fontWeight="Regular"
                    color={theme.Black}
                    style={{ fontSize: isDeskTop ? "16px" : "14px" }}
                  >
                    {v.title}
                  </Pre3>
                </Row>
              )}
              {v.list.map((j, jIndex) => {
                return (
                  <FilterResultListItem
                    item={j}
                    index={jIndex}
                    key={`listItem_${index}_${jIndex}`}
                    titleList={headerTitleList}
                    flexList={headerFlexList}
                    onPurchase={onPurchase}
                    isDeskTop={isDeskTop}
                    isMobile={isMobile}
                    categoryType={categoryType}
                  />
                );
              })}
            </Col>
          );
        })}
      <CustomModal
        ref={modalRef}
        name={"alert"}
        onBackdropPress={() => {
          loadingRef.current?.hide();
          offCustomModal("alert");
        }}
      >
        <CustomAlertModal
          mobile={isMobile}
          offModal={() => offCustomModal("alert")}
        />
      </CustomModal>
      <CustomLoading
        ref={loadingRef}
        size={60}
        init={false}
        backgroundColor={"rgba(255,255,255,0.2)"}
        style={{ position: "fixed", top: 0, left: 0 }}
      />
    </Col>
  );
};

export default FilterResultList;

const FilterResultListItem = (props) => {
  const {
    item,
    flexList,
    index,
    onPurchase,
    titleList,
    isDeskTop,
    isMobile,
    categoryType,
  } = props;
  return (
    <Row
      style={{
        height:
          item.difficulty && !isDeskTop
            ? 94
            : item.problem_cnt && !isDeskTop
            ? 72
            : 62,
        borderBottom: `1px solid ${theme.Gray2}`,
      }}
    >
      {titleList.map((v, idx) => {
        return (
          <ItemRow
            key={`filterItem_${index}_${idx}`}
            title={v}
            flexList={flexList}
            item={item}
            onPurchase={onPurchase}
            index={idx}
            isDeskTop={isDeskTop}
            isMobile={isMobile}
            categoryType={categoryType}
          />
        );
      })}
    </Row>
  );
};

const ItemRow = (props) => {
  const {
    title,
    flexList,
    item,
    onPurchase,
    index,
    isDeskTop,
    isMobile,
    categoryType,
  } = props;
  if (title === "정보") {
    return (
      <Row
        style={{
          flex: flexList[index],
          justifyContent: isDeskTop ? "flex-start" : "center",
          alignItems: isDeskTop ? "center" : "flex-start",
          padding: isMobile ? "0 0 0 20px" : "0 0 0 40px",
          flexDirection: isDeskTop ? "row" : "column",
        }}
      >
        {item.difficulty && !isDeskTop && (
          <Pre4
            fontWeight="Regular"
            color={theme.Gray6}
            style={{ userSelect: "none", marginBottom: 8 }}
          >
            {item.difficulty}
          </Pre4>
        )}
        {item.name === "" ? (
          <Row
            style={{
              width: "60%",
              height: 20,
              backgroundColor: theme.Gray2,
              borderRadius: 4,
            }}
          />
        ) : (
          <Pre3
            fontWeight="Regular"
            color={theme.Black}
            style={{ fontSize: isDeskTop ? "16px" : "14px" }}
          >
            {item.name}
          </Pre3>
        )}
        {categoryType === "SchoolZocbo" || categoryType === "GangnamZocbo" ? (
          <></>
        ) : item?.problem_cnt ? (
          <CustomTag
            size={"XS"}
            type={"Lined"}
            title={`${item.problem_cnt}문제`}
            style={{
              marginLeft: isDeskTop ? 8 : 0,
              marginTop: isDeskTop ? 0 : 8,
            }}
          />
        ) : (
          <></>
        )}
      </Row>
    );
  } else if (title === "다운로드") {
    return (
      <Row style={{ flex: flexList[index] }}>
        <ItemFileButton item={item} onPurchase={onPurchase} />
      </Row>
    );
  } else if (title === "난이도") {
    return (
      <Row
        style={{
          flex: flexList[index],
        }}
      >
        <Pre4
          fontWeight="Regular"
          color={theme.Gray6}
          style={{ userSelect: "none" }}
        >
          {item.difficulty}
        </Pre4>
      </Row>
    );
  }
  return <></>;
};

const ItemFileButton = ({ item, onPurchase }) => {
  const { isMobile } = useResponsive();
  return (
    <Col
      style={{ height: "auto", width: "auto", zIndex: 3, position: "relative" }}
    >
      <CustomButton
        size={isMobile ? "XS" : "S"}
        btnType={"Secondary"}
        title={isMobile ? "" : "PDF"}
        onClick={() => {
          onPurchase({ id: item.id, isAll: false, fileName: item.name });
        }}
        leftComponent={
          <Col
            style={{
              height: "13px",
              marginRight: isMobile ? 0 : 8,
              width: "auto",
            }}
          >
            <FileDownLoad color={theme.Blue12} />
          </Col>
        }
        textStyle={{ fontWeight: "Medium" }}
      />
    </Col>
  );
};
