import {
  Route,
  Routes,
  BrowserRouter as Router,
  useNavigate,
} from "react-router-dom";
import Layout from "./Layout";
import MiddlePage from "./pages/MiddlePage";
import HighPage from "./pages/HighPage";
import LoginRouter from "./router/LoginRouter";
import React from "react";
import ProtectedRoute from "./pages/ProtectedRoute";
import { Col, Pre1, Pre3, Pre4 } from "./style/GlobalStyled";
import theme from "./style/theme";
import { CustomButton } from "./components/Atoms/CustomButton";
import { useResponsive } from "./utils/responsive";
import PurchasePage from "./pages/PurchasePage";
import MyPage from "./pages/MyPage";
import { QueryClient, QueryClientProvider } from "react-query";

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: true,
        staleTime: 1000 * 60 * 60,
        cacheTime: Infinity,
        refetchOnMount: true,
      },
    },
  });
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index path="/middle/*" element={<MiddlePage />} />
            <Route path="/high/*" element={<HighPage />} />
            <Route
              path="/login/*"
              element={
                <ProtectedRoute>
                  <LoginRouter />
                </ProtectedRoute>
              }
            />
            <Route path="/purchase" element={<PurchasePage />} />
            <Route path="myPage" element={<MyPage />} />
          </Route>
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </Router>
    </QueryClientProvider>
  );
}

export default App;

const ErrorPage = () => {
  const { isMobile, isTablet } = useResponsive();
  const navigate = useNavigate();
  return (
    <Col style={{ height: "100vh" }}>
      <Pre1
        fontWeight="Medium"
        style={{ fontSize: isTablet ? "28px" : isMobile ? "20px" : "32px" }}
        color={theme.Gray8}
      >
        페이지를 찾을 수 없습니다.
      </Pre1>
      {isMobile ? (
        <Pre4
          fontWeight="Regular"
          color={theme.Gray6}
          style={{
            whiteSpace: "pre-line",
            textAlign: "center",
            lineHeight: "25.6px",
            marginTop: 24,
            marginBottom: 32,
          }}
        >
          입력하신 페이지의 주소가 잘못되었거나{"\n"}페이지의 주소가 변경 혹은
          삭제되어 요청하신 페이지를{"\n"}찾을 수 없습니다. 입력하신 주소가
          정확한지 다시 한번{"\n"}확인해 주시기 바랍니다.
        </Pre4>
      ) : (
        <Pre3
          fontWeight="Regular"
          color={theme.Gray6}
          style={{
            whiteSpace: "pre-line",
            textAlign: "center",
            lineHeight: "25.6px",
            marginTop: 32,
            marginBottom: 48,
          }}
        >
          입력하신 페이지의 주소가 잘못되었거나{"\n"}페이지의 주소가 변경 혹은
          삭제되어 요청하신 페이지를 찾을 수 없습니다.{"\n"}입력하신 주소가
          정확한지 다시 한번 확인해 주시기 바랍니다.
        </Pre3>
      )}
      <Col style={{ width: isMobile ? 142 : 262, height: "auto" }}>
        <CustomButton
          size="Full"
          btnType="Primary"
          title="홈으로 이동하기"
          onClick={() => {
            navigate("/");
          }}
        />
      </Col>
    </Col>
  );
};
