const categoryObj = {
  단어장: "WorkBook",
  본문분석: "TextAnalysis",
  빈칸채우기: "BlankFill",
  해석연습: "InterpretPrac",
  본문암기: "TextMemorize",
  대표유형: "RepresentativeType",
  실력별학습: "LearningByLevel",
  서술형대비: "SubjectivePrepare",
  최다빈출모음: "MostFreq",
  최다오답모음: "MostWrong",
  파이널대비: "FinalPrepare",
  우리학교기출: "SchoolZocbo",
  우리지역기출: "RegionZocbo",
  강남3구기출: "GangnamZocbo",
  작품분석: "WorkAnalysis",
  OX퀴즈: "OXQuiz",
  개념서: "Concept",
  기본예제: "BasicExample",
};

export default categoryObj;
