import * as React from "react";

function FindIcon(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={10.5} cy={10.5} r={6.5} stroke="#C9CACC" strokeWidth={2} />
      <path
        d="M15.5 15.5L20 20"
        stroke="#C9CACC"
        strokeWidth={2}
        strokeLinecap="round"
      />
    </svg>
  );
}

export default FindIcon;
