import React from "react";
import { Col, Pre4, WhiteSpace, Row, Pre1 } from "../../style/GlobalStyled";
import theme from "../../style/theme";
import CustomInput from "./CustomInput";
import { CustomButton } from "./CustomButton";
import Check from "../../images/svg/Check";
import { IValue } from "../../pages/CreatedAccountPage";
import FindSchool from "../Molecules/FindSchool";
import isNoSchool from "../../utils/isNoSchool";
import CustomSelectionChip from "./CustomSelectionChip";
import CustomLoading from "./CustomLoading";
import { useResponsive } from "../../utils/responsive";

export interface WriteInfoPropsType {
  value: IValue;
  setValue: React.Dispatch<React.SetStateAction<IValue>>;
  recommend: string[];
  valid: boolean;
  codeValid: boolean;
  loading: boolean;
  codeLoading: boolean;
  onChangeInputValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClickNicknameHandler: (v: string) => void;
  onClickGradeHandler: (v: string) => void;
  onClickAccountRouteHandler: (v: string) => void;
  onClickComplete: () => void;
}

const WriteInformationPage = ({
  value,
  setValue,
  recommend,
  valid,
  codeValid,
  loading,
  codeLoading,
  onChangeInputValue,
  onClickNicknameHandler,
  onClickGradeHandler,
  onClickAccountRouteHandler,
  onClickComplete,
}: WriteInfoPropsType) => {
  const { isMobile, isTablet } = useResponsive();
  const { nickname, grade, accountRoute, school, code } = value;
  const schoolAvailable = isNoSchool(grade);
  return (
    <Col
      style={{
        backgroundColor: "white",
        padding: "0 40px",
        maxWidth: 960,
      }}
    >
      <Col
        style={{ alignItems: "flex-start", height: "auto", marginBottom: 24 }}
      >
        <Pre1>회원가입</Pre1>
      </Col>
      <Col
        style={{
          border: `1px solid ${theme.Gray2}`,
          borderRadius: 8,
          alignItems: "flex-start",
        }}
      >
        <WriteRecommendInfo
          onChange={onChangeInputValue}
          onClick={onClickNicknameHandler}
          nickname={nickname}
          recommend={recommend}
          valid={valid}
          loading={loading}
          tablet={isTablet}
          mobile={isMobile}
        />
        <WriteGradeInfo
          onClick={onClickGradeHandler}
          grade={grade}
          tablet={isTablet}
          mobile={isMobile}
        />
        <WriteFindSchool
          setValue={setValue}
          tablet={isTablet}
          mobile={isMobile}
          school={value.school}
        />
        <WriteAccountRouteInfo
          onClick={onClickAccountRouteHandler}
          accountRoute={accountRoute}
          tablet={isTablet}
          mobile={isMobile}
        />
        <Row
          style={{
            padding: "20px 24px",
            borderTop: `1px solid ${theme.Gray2}`,
            alignItems: "flex-start",
          }}
        >
          <Col
            style={{
              width: isTablet || isMobile ? 72 : 100,
              height: 48,
              alignItems: "flex-start",
            }}
          >
            <Pre4
              fontWeight="Medium"
              color={theme.Gray10}
              style={{ lineHeight: 1.5 }}
            >
              [선택]
              <br />
              추천인 코드
            </Pre4>
          </Col>
          <Col style={{ alignItems: "flex-start", flex: 1 }}>
            <CustomInput
              placeHolder="6자리 숫자"
              name="code"
              value={code}
              onChange={onChangeInputValue}
              pending={code.length === 0}
              validMessage={
                code.length < 6
                  ? "추천인 코드는 6자리예요"
                  : !codeValid
                  ? "존재하지 않는 코드입니다."
                  : "사용 가능한 코드입니다."
              }
              valid={codeValid}
              rightComponent={
                codeValid && !codeLoading ? (
                  <Check fill={theme.Blue10} />
                ) : codeLoading ? (
                  <CustomLoading size={25} />
                ) : (
                  <></>
                )
              }
              maxLength={6}
              loading={codeLoading}
              disabled={false}
              style={{ height: 48 }}
            />
          </Col>
        </Row>
      </Col>
      <WhiteSpace height={24} />
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <CustomButton
          onClick={onClickComplete}
          btnType="Primary"
          title="회원가입 완료"
          size="Full"
          style={{ width: 248 }}
          disabled={
            !valid ||
            !grade ||
            !accountRoute ||
            (SCHOOL_REQUIRED.includes(value.grade) ? !school : false)
          }
        />
      </div>
      <WhiteSpace height={52} />
    </Col>
  );
};

export default WriteInformationPage;

interface RecommendPropsType {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClick: (v: string) => void;
  nickname: string;
  recommend: string[];
  valid: boolean;
  loading: boolean;
  tablet?: boolean;
  mobile?: boolean;
}

const WriteRecommendInfo = ({
  onChange,
  onClick,
  nickname,
  recommend,
  valid,
  loading,
  tablet,
  mobile,
}: RecommendPropsType) => {
  return (
    <Row
      style={{
        padding: "20px 24px",
        alignItems: "flex-start",
      }}
    >
      <Col
        style={{
          width: tablet || mobile ? 72 : 100,
          height: 48,
          alignItems: "flex-start",
          justifyContent: "center",
        }}
      >
        <Pre4 fontWeight="Medium" color={theme.Gray10}>
          닉네임
        </Pre4>
      </Col>
      <Col style={{ alignItems: "flex-start", flex: 1 }}>
        <CustomInput
          placeHolder="닉네임을 입력해주세요"
          name="nickname"
          value={nickname}
          onChange={onChange}
          valid={valid || nickname.length === 0}
          validMessage={
            nickname.length < 2
              ? "두 글자 이상 입력해주세요."
              : !valid
              ? "중복된 닉네임이에요."
              : "사용할 수 있는 닉네임입니다."
          }
          rightComponent={
            valid && !loading ? (
              <Check fill={theme.Blue10} />
            ) : loading ? (
              <CustomLoading size={25} />
            ) : (
              <></>
            )
          }
          pending={nickname.length === 0}
          loading={loading}
          disabled={false}
          style={{ height: "48px" }}
        />

        <Row
          style={{
            flexWrap: "wrap",
            justifyContent: "flex-start",
            gap: 8,
            marginTop: 12,
            flex: 1,
          }}
        >
          {recommend.map((v, index) => {
            const select = nickname === v;
            return (
              <CustomSelectionChip
                key={v + index + "recommend"}
                isSelected={select}
                text={v}
                onClick={() => onClick(v)}
                size={tablet || mobile ? "M" : "L"}
              />
            );
          })}
        </Row>
      </Col>
    </Row>
  );
};

interface GradePropsType {
  onClick: (v: string) => void;
  grade: string;
  tablet?: boolean;
  mobile?: boolean;
}

const WriteGradeInfo = ({ onClick, grade, tablet, mobile }: GradePropsType) => {
  return (
    <Row
      style={{
        padding: "20px 24px",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        borderTop: `1px solid ${theme.Gray2}`,
      }}
    >
      <Row
        style={{
          width: tablet || mobile ? 72 : 100,
          height: 34,
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Pre4 fontWeight="Medium" color={theme.Gray10}>
          학년
        </Pre4>
      </Row>

      <Row
        style={{
          flexWrap: "wrap",
          justifyContent: "flex-start",
          maxWidth: 300,
          gap: tablet || mobile ? 8 : 12,
          height: "auto",
          flex: 1,
        }}
      >
        {gradeList.map((v, index) => {
          const { label, value } = v;
          const select = value === grade;

          return (
            <CustomSelectionChip
              key={value + index + "recommend"}
              isSelected={select}
              text={label}
              onClick={() => onClick(value)}
              size={tablet || mobile ? "M" : "L"}
            />
          );
        })}
      </Row>
    </Row>
  );
};

interface AccountRoutePropsType {
  onClick: (v: string) => void;
  accountRoute: string;
  tablet?: boolean;
  mobile?: boolean;
}

const WriteAccountRouteInfo = ({
  onClick,
  accountRoute,
  tablet,
  mobile,
}: AccountRoutePropsType) => {
  return (
    <Row
      style={{
        padding: "20px 24px",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        borderTop: `1px solid ${theme.Gray2}`,
      }}
    >
      <Row
        style={{
          width: tablet || mobile ? 72 : 100,
          height: 34,
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Pre4 fontWeight="Medium" color={theme.Gray10}>
          가입 경로
        </Pre4>
      </Row>
      <Row
        style={{
          flexWrap: "wrap",
          maxWidth: tablet || mobile ? "none" : 470,
          justifyContent: "flex-start",
          gap: tablet || mobile ? 8 : 12,
          flex: 1,
        }}
      >
        {accountRouteList.map((v, idx) => {
          const select = accountRoute === v;
          return (
            <CustomSelectionChip
              key={v + idx + "accountRoute"}
              isSelected={select}
              text={v}
              onClick={() => onClick(v)}
              size={tablet || mobile ? "M" : "L"}
            />
          );
        })}
      </Row>
    </Row>
  );
};

interface WriteFindSchoolPropsType {
  setValue: React.Dispatch<React.SetStateAction<IValue>>;
  tablet?: boolean;
  mobile?: boolean;
  school: string;
}

const WriteFindSchool = ({
  setValue,
  tablet,
  mobile,
  school,
}: WriteFindSchoolPropsType) => {
  return (
    <Row
      style={{
        padding: "20px 24px",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        borderTop: `1px solid ${theme.Gray2}`,
        zIndex: 99,
      }}
    >
      <Col
        style={{
          width: tablet || mobile ? 72 : 100,
          height: 48,
          alignItems: "flex-start",
          justifyContent: "center",
        }}
      >
        <Pre4 fontWeight="Medium" color={theme.Gray10}>
          학교
        </Pre4>
      </Col>
      <FindSchool
        onClickItem={({ name, code }) => {
          setValue((prev) => {
            return { ...prev, school: name, schoolCode: code };
          });
        }}
        type={"ALL"}
        school={school}
        style={{ height: 48, flex: 1 }}
      />
    </Row>
  );
};

const gradeList = [
  { label: "중1", value: "중1" },
  { label: "중2", value: "중2" },
  { label: "중3", value: "중3" },
  { label: "중졸 검정고시", value: "중졸 검정고시" },

  { label: "고1", value: "고1" },
  { label: "고2", value: "고2" },
  { label: "고3", value: "고3" },
  { label: "고졸 검정고시", value: "고졸 검정고시" },

  { label: "N수생", value: "N수생" },
  { label: "선생님", value: "선생님" },
  { label: "학부모", value: "학부모" },
];

const MIDDLE_ARRAY = ["중1", "중2", "중3", "중졸 검정고시"];
const HIGH_ARRAY = ["고1", "고2", "고3", "N수생", "고졸 검정고시"];

const SCHOOL_REQUIRED = ["고1", "고2", "고3", "중1", "중2", "중3"];

const accountRouteList = [
  "친구・선후배 추천",
  "학교・학원 선생님 추천",
  "인플루언서 추천",
  "광고 (페이스북・인스타)",
  "광고 (기타)",
];
