import theme from "./theme";

const bottomUpModalStyle: React.CSSProperties = {
  height: "auto",
  position: "absolute",
  bottom: 0,
  borderTopLeftRadius: 20,
  borderTopRightRadius: 20,
  justifyContent: "flex-start",
  maxHeight: 400,
  backgroundColor: theme.White,
};

export { bottomUpModalStyle };
