const mathClassHighData = {
  title: "세부과목",
  type: "select",
  list: [
    {
      text: "수학",
      title: "대단원",
      type: "check",
      list: [
        { text: "다항식", title: "대단원", id: "다항식" },
        { text: "방정식과 부등식", title: "대단원", id: "방정식과 부등식" },
        { text: "도형의 방정식", title: "대단원", id: "도형의 방정식" },
        { text: "집합과 명제", title: "대단원", id: "집합과 명제" },
        { text: "함수", title: "대단원", id: "함수" },
        { text: "경우의 수", title: "대단원", id: "경우의 수" },
      ],
    },
    {
      text: "수학1",
      title: "대단원",
      type: "check",
      list: [
        {
          text: "지수함수와 로그함수",
          title: "대단원",
          id: "지수함수와 로그함수",
        },
        { text: "삼각함수", title: "대단원", id: "삼각함수" },
        { text: "수열", title: "대단원", id: "수열" },
      ],
    },
    {
      text: "수학2",
      title: "대단원",
      type: "check",
      list: [
        {
          text: "함수의 극한과 연속",
          title: "대단원",
          id: "함수의 극한과 연속",
        },
        { text: "미분", title: "대단원", id: "미분" },
        { text: "적분", title: "대단원", id: "적분" },
      ],
    },
    {
      text: "미적분",
      title: "대단원",
      type: "check",
      list: [
        { text: "수열의 극한", title: "대단원", id: "수열의 극한" },
        { text: "미분법", title: "대단원", id: "미분법" },
        { text: "적분법", title: "대단원", id: "적분법" },
      ],
    },
    {
      text: "확률과 통계",
      title: "대단원",
      type: "check",
      list: [
        { text: "경우의 수", title: "대단원", id: "경우의 수" },
        { text: "확률", title: "대단원", id: "확률" },
        { text: "통계", title: "대단원", id: "통계" },
      ],
    },
    {
      text: "기하",
      title: "대단원",
      type: "check",
      list: [
        { text: "이차곡선", title: "대단원", id: "이차곡선" },
        { text: "평면벡터", title: "대단원", id: "평면벡터" },
        {
          text: "공간도형과 공간좌표",
          title: "대단원",
          id: "공간도형과 공간좌표",
        },
      ],
    },
  ],
};

export default mathClassHighData;
