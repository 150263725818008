import React from "react";
import { Pre3, Pre5, Row } from "../../style/GlobalStyled";
import theme from "../../style/theme";

type PropsType = {
  onClick: () => void;
  isSelected: boolean;
  text: string;
  size?: "L" | "M" | "S" | "F";
  subText?: string;

  style?: React.CSSProperties;
  textStyle?: React.CSSProperties;
  subTextStyle?: React.CSSProperties;

  disabled?: boolean;

  LeftComponent?: React.FC<{ isSelected?: boolean }>;
  RightComponent?: React.FC<{ isSelected?: boolean }>;
};

/* 

size: F 일 경우는 피그마 상에서 L로 되어 있지만, padding 값이나 width, height 값이 큰 요소들에 적용 됩니다.
보통 해당 요소의 높이는 같은 층위에 들어가는 TextInput의 높이와 동일하게 적용되게 설정해주시면 됩니다.

*/

const CustomSelectionChip = ({
  text,
  isSelected,
  onClick,
  textStyle,
  style,
  subTextStyle,

  disabled,
  subText,
  size = "S",
  LeftComponent,
  RightComponent,
}: PropsType) => {
  return (
    <Row
      onClick={disabled ? undefined : onClick}
      style={{
        height: SizeType[size].height || "auto",
        width: SizeType[size].width || "auto",
        padding: SizeType[size].padding,
        flex: SizeType[size]?.flex,
        backgroundColor: isSelected ? theme.Blue1 : theme.White,
        border: `solid 1px ${
          isSelected ? theme.Blue7 : disabled ? theme.Gray2 : theme.Gray3
        }`,
        borderRadius: 8,
        lineHeight: "16px",
        cursor: "pointer",
        ...style,
      }}
    >
      {LeftComponent !== undefined && <LeftComponent isSelected={isSelected} />}
      <Pre3
        fontWeight="Medium"
        color={disabled ? theme.Gray3 : isSelected ? theme.Blue11 : theme.Gray7}
        style={{
          userSelect: "none",
          fontSize: SizeType[size].fontSize,
          ...textStyle,
        }}
      >
        {text}
        {Boolean(subText) && (
          <Pre5
            fontWeight="Medium"
            color={isSelected ? theme.Blue11 : theme.Gray5}
            style={{
              userSelect: "none",
              display: "block",
              marginTop: 4,
              ...subTextStyle,
            }}
          >
            {subText}
          </Pre5>
        )}
      </Pre3>
      {RightComponent !== undefined && (
        <RightComponent isSelected={isSelected} />
      )}
    </Row>
  );
};

export default CustomSelectionChip;

const SizeType = {
  L: {
    width: "auto",
    height: "auto",
    padding: "8px 12px",
    borderRadius: 8,
    fontWeight: "Medium",
    fontSize: 16,
    flex: undefined,
  },
  M: {
    width: "auto",
    height: "auto",
    padding: "8px 12px",
    borderRadius: 8,
    fontWeight: "Medium",
    fontSize: 14,
    flex: undefined,
  },
  S: {
    width: "auto",
    height: "auto",
    padding: "8px 12px",
    borderRadius: 8,
    fontWeight: "Medium",
    fontSize: 12,
    flex: undefined,
  },
  F: {
    width: "100%",
    height: "100%",
    padding: "16px 12px",
    borderRadius: 8,
    fontWeight: "Medium",
    fontSize: 16,
    flex: 1,
  },
};
