import React from "react";
import { Pre3, Row } from "../../style/GlobalStyled";
import Xicon from "../../images/svg/Xicon";
import theme from "../../style/theme";

const ModalHeader = ({
  title = "",
  offModal,
}: {
  title?: string;
  offModal?: () => void;
}) => {
  return (
    <Row style={{ height: "auto", padding: "24px 20px 16px" }}>
      <Row style={{ flex: 1, height: "auto", justifyContent: "flex-start" }} />
      <Row style={{ flex: 4, height: "auto" }}>
        <Pre3>{title}</Pre3>
      </Row>
      <Row
        style={{
          flex: 1,
          height: "auto",
          justifyContent: "flex-end",
        }}
      >
        {Boolean(offModal) && (
          <Row
            onClick={offModal}
            style={{
              cursor: "pointer",
              width: "auto",
            }}
          >
            <Xicon width={24} color={theme.Gray8} />
          </Row>
        )}
      </Row>
    </Row>
  );
};

export default ModalHeader;
