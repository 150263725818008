import * as React from "react";

const dir = { top: "-90deg", bottom: "90deg", left: "180deg", right: "0deg" };

function Arrow20(props) {
  return (
    <svg
      width={props.size || 20}
      height={props.size || 20}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        rotate: `${dir[props.dir]}`,
      }}
      {...props}
    >
      <path
        d="M10 18l6-6-6-6"
        stroke={props.stroke ? props.stroke : "#909398"}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Arrow20;
