import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import { Col, Pre4 } from "../../style/GlobalStyled";
import theme from "../../style/theme";
import Lottie from "react-lottie";

interface PropsType {
  size?: number | string;
  backgroundColor?: string;
  init?: boolean;
  delay?: number;
  style?: React.CSSProperties;
}

const CustomLoading = React.forwardRef<
  {
    show: () => void;
    hide: () => void;
    setType: (key: string) => void;
    getState: () => boolean;
  },
  PropsType
>((props, ref) => {
  const { size, backgroundColor, init = true, delay = 0, style } = props;
  const [isVisible, setIsVisible] = useState(init);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [type, setType] = useState("");

  useImperativeHandle(ref, () => ({
    show: () => {
      setIsVisible(true);
    },
    hide: () => {
      timeoutRef.current = setTimeout(() => {
        setIsVisible(false);
      }, delay);
    },
    setType: (key: string) => {
      setType(key);
      setIsVisible(true);
    },
    getState: () => {
      return isVisible;
    },
  }));

  const defaultOptions = {
    renderer: "svg",
    loop: true,
    autoplay: true,
    animationData: require("../../lottie/LoadingIndicator.json"),
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  if (isVisible) {
    return (
      <Col
        style={{
          backgroundColor: backgroundColor || "transparent",
          position: "absolute",
          top: 0,
          zIndex: 1000,
          flex: 1,
          ...style,
        }}
      >
        <Lottie
          options={defaultOptions}
          style={{
            width: size,
            height: size,
          }}
        />
        {type !== "" && (
          <Pre4
            color={theme.Blue12}
            fontWeight={"Medium"}
            style={{ marginTop: 20 }}
          >
            {type}
          </Pre4>
        )}
      </Col>
    );
  } else {
    return <></>;
  }
});

export default CustomLoading;
