import React, { useCallback, useEffect, useMemo, useState } from "react";
import { styled } from "styled-components";
import theme from "../../style/theme";
import Magnifier from "../../images/svg/Magnifier.svg";
import { Col, Pre3, Pre4 } from "../../style/GlobalStyled";
import { ISchoolArray } from "../../hooks/responseTypes";
import useIntegratedAPI from "../../hooks/useIntegratedAPI";
import { debounce } from "lodash";

type PropsType = {
  onClickItem: ({ name, code }: { name: string; code: string }) => void;
  school: string;
  type: "ALL" | "MID" | "HIGH";
  mobile?: boolean;
  style?: {};
};

const SearchInput = styled.input<{
  $active: boolean;
  $arr: ISchoolArray[];
  disabled: boolean | undefined;
  $mobile: boolean;
}>`
  width: 100%;
  height: ${(props) => (props.$mobile ? "46px" : "56px")};
  border: 0;
  border: ${(props) =>
    props.$active ? `1px solid ${theme.Blue10}` : `1px solid ${theme.Gray3}`};
  border-radius: 8px;
  font-size: ${(props) => (props.$mobile ? "14px" : "16px")};
  line-height: 16px;
  box-sizing: border-box;
  padding-left: 48px;
  background: url(${Magnifier}) no-repeat 16px 48%;
  background-color: ${(props) => (props.disabled ? theme.Gray1 : theme.White)};
  color: ${(props) => (props.disabled ? theme.Gray1 : theme.Black)};
  &:focus {
    outline: 0;
    border: 1px solid ${theme.Blue10};
  }
  &::placeholder {
    font-size: ${(props) => (props.$mobile ? "14px" : "16px")};
    color: ${(props) => (props.disabled ? "transparent" : theme.Gray5)};
  }
`;

const HoverCol = styled(Col)`
  &:hover {
    background-color: ${theme.Blue1};
    & > .title {
      color: ${theme.Blue12};
    }
    & > .subTitle {
      color: ${theme.Blue8};
    }
  }
`;

const FindSchoolScroll = ({
  onClickItem,
  type = "ALL",
  mobile,
  school,
  style,
}: PropsType) => {
  const [INTEGRATED_API] = useMemo(useIntegratedAPI, []);
  const [schoolArray, setSchoolArray] = useState<ISchoolArray[]>([]);
  const [inputTxt, setInputTxt] = useState("");
  const [isActive, setIsActive] = useState(false);

  const SearchSchool = async (text: string) => {
    try {
      const { data } = await INTEGRATED_API.school.searchSchool({
        schoolName: text,
        schoolType: type,
      });
      setSchoolArray(data.result);
    } catch (e) {
      console.log(e, "FindSchoolModal");
    }
  };

  useEffect(() => {
    debounceHandler(inputTxt);
  }, [inputTxt]);

  const debounceHandler = useCallback(debounce(SearchSchool, 400), []);

  useEffect(() => {
    setInputTxt(school);
  }, [school]);
  return (
    <Col
      style={{
        width: "100%",
        position: "relative",
        zIndex: 10,
        flex: 1,
        ...style,
      }}
    >
      <SearchInput
        placeholder="학교 이름을 검색하세요."
        value={inputTxt}
        onChange={(e) => {
          setInputTxt((prev) => e.target.value);
          setIsActive(true);
        }}
        $active={isActive}
        $arr={schoolArray}
        disabled={false}
        $mobile={mobile || false}
      />
      <Col
        style={{
          flex: 1,
          height: "auto",
          overflowY: "scroll",
          justifyContent: "flex-start",
          marginTop: 12,
        }}
        id={"invisible-scrollbar"}
      >
        {schoolArray.map((item, index) => {
          return (
            <HoverCol
              key={`find_${index}`}
              onClick={() => {
                onClickItem({ name: item.name, code: item.school_code });
                setIsActive(false);
              }}
              style={{
                height: "auto",
                padding: mobile ? "12px 16px" : "16px 20px",
                cursor: "pointer",
                borderRadius: 12,
              }}
            >
              <Pre3
                fontWeight="Regular"
                color={theme.Gray10}
                style={{
                  width: "100%",
                  fontSize: mobile ? "14px" : "16px",
                  marginBottom: 8,
                }}
                className={"title"}
              >
                {item.name}
              </Pre3>
              <Pre4
                fontWeight="Regular"
                color={theme.Gray6}
                style={{
                  width: "100%",
                  cursor: "pointer",
                  lineHeight: "16px",
                  fontSize: mobile ? "12px" : "14px",
                }}
                className={"subTitle"}
              >
                {item.address}
              </Pre4>
            </HoverCol>
          );
        })}
      </Col>
    </Col>
  );
};

export default FindSchoolScroll;
