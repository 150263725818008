import * as React from "react";

const dir = { top: "-90deg", bottom: "90deg", left: "180deg", right: "0deg" };

function DoubleArrow(props) {
  return (
    <svg
      width={props.size || 24}
      height={props.size || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        rotate: `${dir[props.dir]}`,
      }}
      {...props}
    >
      <path
        d="M12 18l6-6-6-6M6 18l6-6-6-6"
        stroke="#B5B7BA"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default DoubleArrow;
