const { default: englishData } = require("./englishData");
const { default: historyClassHighData } = require("./historyClassHighData");
const { default: koreaData } = require("./koreaData");
const { default: mathClassHighData } = require("./mathClassHighData");
const { default: midEnglishData } = require("./midEnglishData");
const {
  default: midHistoryClassHighData,
} = require("./midHistoryClassHighData");
const { default: midKoreaData } = require("./midKoreaData");
const { default: midMathClassHighData } = require("./midMathClassHighData");
const {
  default: midScienceClassHighData,
} = require("./midScienceClassHighData");
const {
  default: midSocietyClassHighData,
} = require("./midSocietyClassHighData");
const { default: scienceClassHighData } = require("./scienceClassHighData");
const { default: societyClassHighData } = require("./societyClassHighData");

const subjectData = {
  english: { high: englishData, middle: midEnglishData },
  korean: { high: koreaData, middle: midKoreaData },
  math: { high: mathClassHighData, middle: midMathClassHighData },
  society: { high: societyClassHighData, middle: midSocietyClassHighData },
  science: { high: scienceClassHighData, middle: midScienceClassHighData },
  history: { high: historyClassHighData, middle: midHistoryClassHighData },
};

export { subjectData };
