import React, { useCallback, useEffect, useMemo, useState } from "react";
import { styled } from "styled-components";
import theme from "../../style/theme";
import Magnifier from "../../images/svg/Magnifier.svg";
import { Col, Pre3, Pre4 } from "../../style/GlobalStyled";
import { ISchoolArray } from "../../hooks/responseTypes";
import useIntegratedAPI from "../../hooks/useIntegratedAPI";
import { debounce } from "lodash";

type PropsType = {
  onClickItem: ({ name, code }: { name: string; code: string }) => void;
  school: string;
  type: "ALL" | "MID" | "HIGH";
  mobile?: boolean;
  style?: {};
};

const SearchInput = styled.input<{
  $active: boolean;
  $arr: ISchoolArray[];
  disabled: boolean | undefined;
  $mobile: boolean;
}>`
  width: 100%;
  height: 100%;
  padding: 14.75px;
  border: 0;
  border: 1px solid ${theme.Gray3};
  border-radius: ${(props) =>
    props.$active && props.$arr.length !== 0 ? "8px 8px 0px 0px" : "8px"};
  font-size: ${(props) => (props.$mobile ? "14px" : "16px")};
  line-height: 16px;
  box-sizing: border-box;
  padding-left: 48px;
  background: url(${Magnifier}) no-repeat 16px 48%;
  background-color: ${(props) => (props.disabled ? theme.Gray1 : theme.White)};
  color: ${(props) => (props.disabled ? theme.Gray1 : theme.Black)};
  &:focus {
    outline: 0;
  }
  &::placeholder {
    font-size: ${(props) => (props.$mobile ? "14px" : "16px")};
    color: ${(props) => (props.disabled ? "transparent" : theme.Gray5)};
  }
`;

const FindSchool = ({
  onClickItem,
  type = "ALL",
  mobile,
  school,
  style,
}: PropsType) => {
  const [INTEGRATED_API] = useMemo(useIntegratedAPI, []);
  const [schoolArray, setSchoolArray] = useState<ISchoolArray[]>([]);
  const [inputTxt, setInputTxt] = useState("");
  const [isActive, setIsActive] = useState(false);

  const SearchSchool = async (text: string) => {
    try {
      const { data } = await INTEGRATED_API.school.searchSchool({
        schoolName: text,
        schoolType: type,
      });
      setSchoolArray(data.result);
    } catch (e) {
      console.log(e, "FindSchoolModal");
    }
  };

  useEffect(() => {
    debounceHandler(inputTxt);
  }, [inputTxt]);

  const debounceHandler = useCallback(debounce(SearchSchool, 400), []);

  useEffect(() => {
    setInputTxt(school);
  }, [school]);
  return (
    <Col
      style={{
        width: "100%",
        position: "relative",
        zIndex: 10,
        ...style,
      }}
    >
      <SearchInput
        placeholder="학교"
        value={inputTxt}
        onChange={(e) => {
          setInputTxt((prev) => e.target.value);
          setIsActive(true);
        }}
        $active={isActive}
        $arr={schoolArray}
        $mobile={mobile || false}
        disabled={false}
      />
      {isActive && (
        <Col
          style={{
            alignItems: "flex-start",
            width: "100%",
            height: "auto",
            position: mobile ? "static" : "absolute",
            top: 49,
            left: 0,
            backgroundColor: "white",
            borderRadius: "0px 0px 8px 8px",
            border:
              isActive && schoolArray.length !== 0
                ? `1px solid ${theme.Gray3}`
                : "0px",
            borderTop:
              isActive && schoolArray.length !== 0
                ? "1px solid transparent"
                : "0px",
            boxSizing: "border-box",
            paddingBottom:
              isActive && schoolArray.length !== 0 ? "16px" : "0px",
            overflowY: schoolArray.length > 7 ? "scroll" : "auto",
            maxHeight: mobile ? "250px" : "270px",
            justifyContent: "flex-start",
          }}
        >
          {schoolArray.map((item, index) => {
            return (
              <div
                key={`find_${index}`}
                onClick={() => {
                  onClickItem({ name: item.name, code: item.school_code });
                  setIsActive(false);
                }}
              >
                <Pre3
                  fontWeight="Regular"
                  color={theme.Blue12}
                  style={{
                    padding: "16px 16px 4px 16px",
                    width: "100%",
                    cursor: "pointer",
                    fontSize: mobile ? "14px" : "16px",
                  }}
                >
                  {item.name}
                </Pre3>
                <Pre4
                  fontWeight="Regular"
                  color={theme.Gray6}
                  style={{
                    padding: "0px 16px",
                    width: "100%",
                    cursor: "pointer",
                    lineHeight: "16px",
                    fontSize: mobile ? "12px" : "14px",
                  }}
                >
                  {item.address}
                </Pre4>
              </div>
            );
          })}
        </Col>
      )}
    </Col>
  );
};

export default FindSchool;
