import React, { useEffect, useState } from "react";
import Arrow20 from "../../images/svg/Arrow20";
import DoubleArrow from "../../images/svg/DoubleArrow";
import { Col, Pre3, Row } from "../../style/GlobalStyled";
import theme from "../../style/theme";
import { useResponsive } from "../../utils/responsive";

type propsType = {
  list: any[];
  children: React.ReactElement;
  containerStyle?: React.CSSProperties;
  totalCount: number;
  setPageIndex?: React.Dispatch<React.SetStateAction<number>>;
};

// 현재 해당 컴포넌트의 리스트는 일차원 배열이 아닌, 2차원 배열 구조의 리스트입니다.
// 민일 조금 더 공통적인 컴포넌트로 이용하려면 API로 받는 리스트를 일차원 배열로 수정한 후 그에 맞게 FilterResultList 컴포넌트를 수정해야합니다. by ethan
const Pagenation = ({
  list,
  children,
  containerStyle,
  totalCount,
  setPageIndex: setIndex,
}: propsType) => {
  const CONTENT_VIEW_NUMBER = 10; // 한 페이지에 보여지는 리스트 아이템 수
  const PAGE_VIEW_NUMBER = 5; // 한 번에 보여지는 페이지 넘버 수 ex 1, 2, 3, 4, 5 => 6, 7, 8, 9, 10

  const [pageIndex, setPageIndex] = useState(0);
  const { isMobile } = useResponsive();

  const emptyList = [
    { list: new Array(CONTENT_VIEW_NUMBER).fill({ name: "" }) },
  ];

  // const totalCount = list.reduce((count, item) => count + item.list.length, 0);

  const maxPageIndex =
    Math.floor(totalCount / CONTENT_VIEW_NUMBER) -
    1 +
    (totalCount % CONTENT_VIEW_NUMBER ? 1 : 0);

  const currentPageStartIndex =
    Math.floor(pageIndex / PAGE_VIEW_NUMBER) * PAGE_VIEW_NUMBER;

  const currentPageList = Array.from(
    { length: 5 },
    (_, index) => index + currentPageStartIndex
  ).filter((v) => v <= maxPageIndex);

  useEffect(() => {
    setPageIndex(0);
    if (setIndex) {
      setIndex(0);
    }
  }, [totalCount]);

  // const slicedList =
  //   list.length > 0
  //     ? [
  //         {
  //           title: "",
  //           list: list[0].list.slice(
  //             pageIndex * CONTENT_VIEW_NUMBER,
  //             pageIndex * CONTENT_VIEW_NUMBER + CONTENT_VIEW_NUMBER
  //           ),
  //         },
  //       ]
  //     : list;
  return (
    <Col style={{ height: "auto", ...containerStyle }}>
      {React.cloneElement(children, {
        list: totalCount !== 0 && list.length === 0 ? emptyList : list,
        // list: emptyList,
      })}
      <Row
        style={{
          height: 32,
          position: "relative",
          width: "auto",
        }}
      >
        <Row
          style={{
            width: 80,
            position: "absolute",
            left: isMobile ? -96 : -120,
            justifyContent: "flex-end",
          }}
        >
          {currentPageStartIndex - PAGE_VIEW_NUMBER >= 0 && !isMobile && (
            <Col
              style={{ ...arrowStyle, marginRight: 16 }}
              onClick={() => {
                const temp = (prev) => {
                  if (currentPageStartIndex - PAGE_VIEW_NUMBER < 0) {
                    return prev;
                  }
                  return (
                    Math.floor(
                      (currentPageStartIndex - PAGE_VIEW_NUMBER) /
                        PAGE_VIEW_NUMBER
                    ) * PAGE_VIEW_NUMBER
                  );
                };
                setPageIndex(temp);
                if (setIndex) {
                  setIndex(temp);
                }
              }}
            >
              <DoubleArrow dir={"left"} />
            </Col>
          )}
          {pageIndex !== 0 && (
            <Row
              style={{
                ...arrowStyle,
                left: 0,
              }}
              onClick={() => {
                const temp = (prev) => {
                  if (prev === 0) {
                    return prev;
                  }
                  return prev - 1;
                };
                setPageIndex(temp);
                if (setIndex) {
                  setIndex(temp);
                }
              }}
            >
              <Arrow20 size={24} stroke={theme.Gray5} dir={"left"} />
            </Row>
          )}
        </Row>
        <Row
          style={{
            width: "auto",
            gap: isMobile ? 16 : 24,
          }}
        >
          {currentPageList.map((v) => {
            return (
              <Col
                key={`page_list_${v}`}
                style={{
                  width: 24,
                  height: 24,
                  userSelect: "none",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setPageIndex(v);
                  if (setIndex) {
                    setIndex(v);
                  }
                }}
              >
                <Pre3
                  fontWeight={pageIndex === v ? "Bold" : "Medium"}
                  color={pageIndex === v ? theme.Blue10 : theme.Gray5}
                >
                  {v + 1}
                </Pre3>
              </Col>
            );
          })}
        </Row>
        <Row
          style={{
            width: 80,
            position: "absolute",
            right: isMobile ? -96 : -120,
            justifyContent: "flex-start",
          }}
        >
          {pageIndex < maxPageIndex && (
            <Row
              style={{
                ...arrowStyle,
              }}
              onClick={() => {
                const temp = (prev) => {
                  if (prev === maxPageIndex) {
                    return prev;
                  } else {
                    return prev + 1;
                  }
                };
                setPageIndex(temp);
                if (setIndex) {
                  setIndex(temp);
                }
              }}
            >
              <Arrow20 size={24} stroke={theme.Gray5} />
            </Row>
          )}
          {currentPageStartIndex + PAGE_VIEW_NUMBER <= maxPageIndex &&
            !isMobile && (
              <Col
                style={{ ...arrowStyle, marginLeft: 16 }}
                onClick={() => {
                  const temp = (prev) => {
                    if (
                      currentPageStartIndex + PAGE_VIEW_NUMBER >
                      maxPageIndex
                    ) {
                      return prev;
                    }
                    return (
                      Math.floor(
                        (currentPageStartIndex + PAGE_VIEW_NUMBER) /
                          PAGE_VIEW_NUMBER
                      ) * PAGE_VIEW_NUMBER
                    );
                  };
                  setPageIndex(temp);
                  if (setIndex) {
                    setIndex(temp);
                  }
                }}
              >
                <DoubleArrow />
              </Col>
            )}
        </Row>
      </Row>
    </Col>
  );
};

export default Pagenation;

const arrowStyle: React.CSSProperties = {
  width: 32,
  height: 32,
  userSelect: "none",
  cursor: "pointer",
  borderRadius: 8,
  border: `1px solid ${theme.Gray4}`,
};
