import React from "react";
import { Route, Routes } from "react-router-dom";
import AppleRedirectPage from "../components/Login/AppleRedirectPage";
import KakaoRedirectPage from "../components/Login/KakaoRedirectPage";
import NaverRedirectPage from "../components/Login/NaverRedirectPage";
import CreatedAccountPage from "../pages/CreatedAccountPage";
import Login from "../pages/Login";

const LoginRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/created_account" element={<CreatedAccountPage />} />
      <Route path="/api/oauth/callback/apple" element={<AppleRedirectPage />} />
      <Route path="/api/oauth/callback/kakao" element={<KakaoRedirectPage />} />
      <Route path="/api/oauth/callback/naver" element={<NaverRedirectPage />} />
    </Routes>
  );
};

export default LoginRouter;
